/*  
    Source (17.04.2024)
    https://gitlab.com/jt-pub/google-kv-pull-push/-/blob/master/src/pull.js
*/
class JtSegmentFetcher {
    constructor(campaignCode, options = {}) {
        function addScript(url, target = document.head) {
            let s = document.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.referrerPolicy = 'no-referrer-when-downgrade';
            s.src = url;
            target.appendChild(s);
        }

        this.callbackName = options.callbackName || '__jtSegCallback';
        const domain = options.domain || 'justid.io';
        const endpoint = options.endpoint || '/front/kv.js';
        const url = `https://${domain}${endpoint}?code=${campaignCode}&callback=${this.callbackName}`;

        this.promise = new Promise(res => {
            window[this.callbackName] = res;
            addScript(url);
        });
    }

    async getWithTimeout(timeoutMillis, resultIfTimeout = undefined) {
        let result = resultIfTimeout;
        try {
            // protection against too long response time from JustTag endpoint
            result = await Promise.race([this.promise, new Promise((res, rej) => setTimeout(rej, timeoutMillis))]);
        } catch { }
        delete window[this.callbackName];
        return result;
    }
}
window.jtSegmentFetcher = new JtSegmentFetcher('LEKMFW4E');

//Attach targeting
export function setJustTagTargeting() {
    let googletag = window.googletag || {};
    googletag.cmd = googletag.cmd || [];


    return new Promise((resolve) => {
        window.jtSegmentFetcher.getWithTimeout(500).then(justTagSegments => {
            if (!justTagSegments) {
                resolve();
                return;
            }

            googletag.cmd.push(function () {
                googletag.pubads().setTargeting('JTSeg', justTagSegments);
            });

            resolve();
        });
    });
};