import axios, { AxiosResponse } from "axios"

type ResetPasswordData = {
    token: string,
    password: string,
    password_repeat: string;
}

export async function resetPasswordToken(userData: ResetPasswordData): Promise<AxiosResponse<ResetPasswordData>> {

    try {
        const res = await axios.post(`${window.API_HOST}/api/account/reset-password-token`, userData)
        if (res?.data?.errors) {
            throw { status: res.status, errors: res.data.errors }
        }

        return res.data
    } catch (err) {
        if (axios.isAxiosError(err)) {
            throw { status: err.response!.status, errors: err.response!.data.errors }
        } else {
            throw err
        }
    }
}