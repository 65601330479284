import React from "react"

import LegacyAdLibEvent from "./Adslots/AdLibEvent"
import AdContainer from "../prebid/AdContainer"

import useMediaQuery from "../helpers/useMediaQuery";

type FeatureFlagAdContainerProps = {
    name: string;
    only?: 'desktop' | 'mobile';
}

const FeatureFlagAdContainer: React.FC<FeatureFlagAdContainerProps> = ({ name, only }) => {

    const isDesktop = useMediaQuery('bp4');

    //Do not display container based on media query - container is NoSSR
    if (only && ((only == 'desktop' && !isDesktop) || (only == 'mobile' && isDesktop)))
        return null;

    return <AdContainer id={name} />;
}

export default FeatureFlagAdContainer;