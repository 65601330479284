import React from "react";
import { observer } from "mobx-react";

import { styled } from "../../stitches.config";
import AllAuthorsAtavist from "../AllAuthorsAtavist";
import { useStores, pushDataLayer } from "../../helpers";
import { h3 } from "../commonStyles/Fonts";
import TipFormStandalone from "../Modal/payment/TipFormStandalone";
import { IS_FEATURE_TIP } from "../../components/common/constants/constants";

interface AtavistAuthorsProps {
  article: ArticleAuthor;
}

const AtavistAuthors: React.FC<AtavistAuthorsProps> = observer(
  ({ article }) => {
    const { ArticleStore } = useStores();
    const url = ArticleStore.article.fb_url;
    const hasTip = !(article as any)?.diasbleJournalistTip;

    return (
      <WrapperAll>
        <StyledAtavistAuthors id="autorzy-artykulu">
          <h3 className={h3()}>Autorzy artykułu:</h3>
          <Wrapper
            className={
              article?.allAuthors.length > 1 ? "more-authors-active" : ""
            }
          >
            <AllAuthorsAtavist allAuthors={article?.allAuthors} />
          </Wrapper>
        </StyledAtavistAuthors>
        {hasTip && (
          <WrapperTip>
            <TipFormStandalone
              articleId={article?.OID}
              userOID={article.author?.OID}
              allAuthors={article?.allAuthors.map((author: { OID: any; }) => author.OID)}
              descriptionText="Teraz możesz docenić pracę dziennikarzy i dziennikarek. Cała kwota trafi do nich. Wraz z napiwkiem możesz przekazać też krótką wiadomość."
              origin={"tip-standalone-atavist"}
            />
          </WrapperTip>
        )}
      </WrapperAll>
    );
  }
);

const WrapperTip = styled("div", {
  "div,button": {
    backgroundColor: "$grayscale0 !important",
    color: "$grayscale100 !important",
  },
  "& form > input, & input[type='number']": {
    color: "$grayscale100 !important",
    border: "2px solid rgba(0, 0, 0, 0.05)",
  },
  button: {
    border: "2px solid rgba(0, 0, 0, 0.05)",
  },
});

const StyledAtavistAuthors = styled("div", {
  padding: "32px 16px 40px 16px",
  background: "$grayscale0Fixed",
  "@bp4": {
    padding: "32px",
    borderBottom: "0px solid $grayscale25",
  },
  h3: {
    textAlign: "center",
  },
});

const Wrapper = styled("div", {
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  "@bp4": {
    justifyContent: "space-around",
  },
  "& .avatar": {
    width: "72px",
    height: "72px",
    borderRadius: "72px",
    marginRight: "20px !important",
  },
  "& p": {
    margin: "0 0 4px 0 !important",
  },
});

const WrapperAll = styled("div", {
  marginTop: "20px",
  marginBottom: "38px",
  width: "100%",
  maxWidth: "840px",
  "@bp4": {
    margin: "0 auto 38px auto",
  },
  "& .more-authors-active": {
    justifyContent: "space-between",
    "@bp4": {
      justifyContent: "space-around",
    },
    "& .author-atavist-wrapper": {
      "@bp4": {
        width: "182px",
      }
    }
  },
});

export default AtavistAuthors;
