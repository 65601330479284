import axios, { AxiosResponse } from "axios"

export async function logoutUser(): Promise<AxiosResponse> {
    try {
        const res = await axios.post(`${window.API_HOST}/api/account/logout`)
        return res.data
    } catch (err) {
        if (axios.isAxiosError(err)) {
            throw { status: err.response!.status, errors: err.response!.data.errors }
        } else {
            throw new Error('Cannot logout.')
        }
    }
}