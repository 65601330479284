import React from "react"
import { styled } from "../../stitches.config";

const DrawingBoard = React.lazy(() => import(/* webpackChunkName: "components__Article__Parts__DrawingBorad" */"../Article/Parts/DrawingBorad"));

const withDrawingBoard = (cb) => (WrappedComponent) => {
    return class extends React.Component {
        ref = React.createRef()
        state = {
            height: 0,
            condition: false
        }

        updateHeight = () => {
            // if (window.innerWidth < 992) return;
            this.setState({ height: this.ref.current.clientHeight, condition: cb(this.props) })
        }

        componentDidMount() {
            this.updateHeight()
        }

        render() {
            const { condition } = this.state

            // if (window.innerWidth < 992) return <WrappedComponent {...this.props} updateHeight={this.updateHeight} />

            return <Wrapper ref={this.ref}>
                {condition ? <DrawingBoard height={this.state.height} updateHeight={this.updateHeight} /> : null}
                <WrappedComponent {...this.props} updateHeight={this.updateHeight} />
            </Wrapper>
        }
    }
}

const Wrapper = styled("div",{
    position: "relative"
});

export { withDrawingBoard }