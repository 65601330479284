import React from 'react'
import { styled } from '../../../stitches.config';
import { observer } from 'mobx-react'
import Html from './Html'

import { useStores } from '../../../helpers/useStores'


const Header = ({ header }) => {
    const { text, level } = header;
    const { ArticleStore } = useStores();

    if (ArticleStore.article.display_type === "BRID") {
        return (
            <BridHeaderWrap className="brid-header-wrap">
                {(() => {
                    switch (level) {
                        case 1:
                            return (<h1>{text}</h1>);
                        case 2:
                            return (<h2>{text}</h2>);
                        case 3:
                            return (<h3>{text}</h3>);
                        case 4:
                            return (<h4>{text}</h4>);
                        case 5:
                            return (<h5>{text}</h5>);
                        case 6:
                            return (<h6>{text}</h6>);
                    }
                })()}
            </BridHeaderWrap>
        )
    }


    if (ArticleStore.article.display_type === "HARDCOVER") {
        return (
            <HardcoverHeaderWrap>
                {(() => {
                    switch (level) {
                        case 1:
                            return (<h1>{text}</h1>);
                        case 2:
                            return (<h2>{text}</h2>);
                        case 3:
                            return (<h3>{text}</h3>);
                        case 4:
                            return (<h4>{text}</h4>);
                        case 5:
                            return (<h5>{text}</h5>);
                        case 6:
                            return (<h6>{text}</h6>);
                    }
                })()}
            </HardcoverHeaderWrap>
        )
    }

    return (

        <BizonHeaderWrap isAligned={header.isAligned}>
            {(() => {
                switch (level) {
                    case 1:
                        return (<h1>{text}</h1>);
                    case 2:
                        return (<h2>{text}</h2>);
                    case 3:
                        return (<h3>{text}</h3>);
                    case 4:
                        return (<h4>{text}</h4>);
                    case 5:
                        return (<h5>{text}</h5>);
                    case 6:
                        return (<h6>{text}</h6>);
                }
            })()}
        </BizonHeaderWrap>
    )
}
const BizonHeaderWrap = styled("div", {
    margin: "15px 38px",
    textAlign: "left",
    color: "$grayscale100Fixed",
    "h1,h2,h3,h4,h5,h6": {
        fontFamily: "$grotesk",
        fontWeight: 700,
        letterSpacing: "0.2px",
        margin: "0"
    },
    "h1": {
        fontSize: "28px",
        lineHeight: "34px"
    },
    "h2, h3": {
        fontSize: "24px",
        lineHeight: "30px"
    },
    "h4,h5,h6": {
        fontSize: "17px",
        fontWeight: 600
    },
    "@bp4": {
        margin: "35px auto",
        maxWidth: "840px",
        "h1,h2,h3,h4,h5,h6": {
            fontWeight: 700,
            lineHeight: "initial"
        },
        "h1": {
            fontSize: "54px",
            lineHeight: "62px",
            letterSpacing: "0px"
        },
        "h2": {
            fontSize: "32px",
            lineHeight: "40px",
            letterSpacing: "0.3px"
        },
        "h3": {
            fontSize: "24px",
            lineHeight: "30px",
            letterSpacing: "0.2px"
        },
        "h4, h5, h6": {
            fontWeight: 600,
            fontSize: "18px",
            lineHeight: "24px",
            letterSpacing: "0.2px"
        }
    },
    variants: {
        isAligned: {
            true: {
                textAlign: 'center'
            },
            false: {}
        }
    }
});

const BridHeaderWrap = styled("div", {
    padding: "0px 16px",
    backgroundColor: "$grayscale0", 
    color: "$grayscale100",
    "p": {
        margin: "0",
        fontWeight: 300,
        color: "#272424",
        fontSize: "17px"
    },
    "@media (min-width: 992px)": {
        padding: "0px 48px",
        "p": {
            fontSize: "18px",
            color: "#737373"
        }
    }
});

const HardcoverHeaderWrap = styled("div", {
    margin: "9px 35px",
    "h1,h2,h3,h4,h5,h6": {
        fontFamily: "$grotesk",
        fontWeight: 600,
        letterSpacing: "1px",
        margin: "0"
    },
    "@media (min-width: 992px)": {
        margin: "25px 115px"
    }
});

export default Header