import React from "react";
import { styled } from "../../../stitches.config";

export default class Youtube extends React.Component {
  render() {
    const videoId = this.props.videoId;
    const isOneSuper = this.props.isOneSuper;

    return (
      <Container className="part--youtube-video">
        {isOneSuper ?
          <iframe
            title="article video"
            src={`https://www.youtube.com/embed/${videoId
              }?controls=0&autoplay=1&mute=1&loop=1&playlist=${videoId
              }`}
            frameBorder="0"
            allowFullScreen
          />
          :
          <iframe
            title="article video"
            src={`https://www.youtube.com/embed/${videoId
              }`}
            frameBorder="0"
            allowFullScreen
          />
        }
      </Container>
    );
  }
}

const Container = styled("div", {
  position: "relative",
  paddingBottom: "56.25%",
  paddingTop: "25px",
  margin: "18px 0px",
  "> iframe": {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%"
  }
});
