import { observable, action } from "mobx";
import openIDB from "../IDB";

const dbPromise = openIDB();

/**
 * @deprecated
 */
class ReadLater {
  constructor(RootStore) {
    this.RootStore = RootStore;
  }

  @observable readLaterArticles = [];

  @action
  addToReadLater = ({ link, image, title, timestamp }) => {
    dbPromise.then(db => {
      const readLaterDbStore = db
        .transaction("readLaterArticles", "readwrite")
        .objectStore("readLaterArticles");
      readLaterDbStore
        .put({ link, image, title, timestamp }, link)
        .then(response => {
          console.log("Article added to readLaterStore");
          this.updateReadLaterArticles();
        })
        .catch(err => console.log(err));
      const articleStore = db.transaction("articles").objectStore("articles");
      // URLs are passed as whole, with hostname, we have to get the pathname, without '/', since that's how articles are saved in IDB
      const slug = link.startsWith("http")
        ? new URL(link).pathname.replace("/", "")
        : link;
      articleStore.get(slug).then(response => {
        if (response) {
          console.log("already in db");
          return;
        }
        fetch(`${window.API_HOST}/pwa-article/${slug}`)
          .then(response => {
            return response.ok ? response.json() : Promise.reject();
          })
          .then(article => {
            const articleStore = db
              .transaction("articles", "readwrite")
              .objectStore("articles");
            articleStore.put(article, slug);
            console.log("hey, article added ", article);
          })
          .catch(err => {
            console.log("hey, we failed, here is your error: ", err);
          });
      });
    });
  };

  @action
  deleteFromReadLater = link => {
    dbPromise.then(db => {
      const tx = db.transaction("readLaterArticles", "readwrite");
      const readLaterDbStore = tx.objectStore("readLaterArticles");
      readLaterDbStore.delete(link).then(this.updateReadLaterArticles());
    });
  };

  @action
  updateReadLaterArticles = () => {
    dbPromise.then(db => {
      const tx = db.transaction("readLaterArticles");
      const readLaterDbStore = tx
        .objectStore("readLaterArticles")
        .index("articles_by_date");
      readLaterDbStore.getAll().then(articles => {
        this.readLaterArticles = articles.reverse();
      });
      return tx.complete;
    });
  };
}

export default ReadLater;
