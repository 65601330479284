import * as React from "react";
import { styled } from "../../../../stitches.config";
import Media from "../../../common/Media"
import ConditionalWrapper from "../../../../helpers/ConditionalWrapper"
import Link from "../../../Link"

import { ImageElement } from "./types"

type PictureProps = {
  element: ImageElement;
  artIndex?: number;
  titlePart?: boolean;
}

const Picture: React.FC<PictureProps> = ({ element, artIndex, ...props }) => {
  // console.log({ element: toJS(element) })
  if (!element.url && !element.data.img) {
    return null;
  }

  return <div>
    <FigureWrapper isArtTop={artIndex == 0}>
      <ConditionalWrapper
        condition={!!element.link || !!element.data?.link}
        wrapper={(children) => <Link to={element.link || element.data?.link}>{children}</Link>}
      >
        <Media
          src={element.url || element.data?.img}
          alt={element.alt || element.data?.alt}
          title={element.title || element.data?.title}
          height={element.data?.height || element.data?.media?.height}
          width={element.data?.width || element.data?.media?.width}
          disablePreload={element.data?.disablePreload}
          preserveRatio={true}
        />
      </ConditionalWrapper>
      {/* brzydko, ale trzeba ujednolicić obiekty zdjęć */}
      {(element.figcaption || element.source) && <figcaption>
        {element.figcaption}
        <span> {element.source} </span>
      </figcaption>}
      {(element.data?.figcaption || element.data?.source) && <figcaption>
        {element.data?.figcaption}
        <span> {element.data?.source} </span>
      </figcaption>}
      {element.data?.btnLink && element.data?.btnText &&
        <ButtonOnPicture>
          <Link to={element.data?.btnLink}>{element.data?.btnText}</Link>
        </ButtonOnPicture>
      }
    </FigureWrapper>
  </div >
}

const FigureWrapper = styled("figure", {
  position: "relative",
  margin: "32px 0",
  "@bp4": {
    margin: "56px 0"
  },
  "& > div": {
    minWidth: "100%"
  },
  "img": {
    width: "100%",
    height: "auto"
  },
  "figcaption": {
    fontStyle: "normal",
    fontSize: "14px",
    padding: "8px 0 0 0",
    lineHeight: "18px",
    color: "#8D8D8D",
    textAlign: "center",
    fontWeight: 600,
    "@bp4": {
      padding: "12px 0 0 0"
    },
    span: {
      position: "relative"
    }
  },
  variants: {
    /* #5432 brak marginu górnego w głównym zdjęciu artykułowym */
    "isArtTop": {
      true: {
        margin: "0 0 32px 0",
        "@bp4": {
          margin: "0 0 56px 0"
        }
      },
      false: {}
    }
  }
});

const ButtonOnPicture = styled("div", {
  position: "absolute",
  width: "100%",
  bottom: "50px",
  display: "flex",
  zIndex: "10",

  "@bp4": {
    bottom: "70px"
  },

  "& a": {
    padding: "15px 35px",
    marginTop: "16px",
    fontSize: "16px",
    backgroundColor: "$primary",
    color: "$grayscale0Fixed !important",
    fontWeight: "bold",
    margin: "auto",
    display: "block",
    width: "fit-content",

    "&:hover": {
      textDecoration: "none",
    }
  }
});

export default Picture;