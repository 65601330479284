import * as React from "react"
import { styled, defaultColors } from "../../stitches.config"

import { LogoBrandsVariants } from "../../config";

type LogoProps = {
    css?: any;
    type?: "white" | "dark" | "color";
    className?: string;
    brand?: Config.Brand;
}

const Logo: React.FC<React.PropsWithChildren<LogoProps>> = ({ className = "", type = "white", brand = window.BRAND, children, css = {} }) => {

    return <StyledLogo
        className={className}
        css={css}
        color={type}
        brand={brand}>
        {children ?? LogoBrandsVariants.components[brand]}
    </StyledLogo>
}

const StyledLogo = styled('div', {
    maxWidth: 356,
    maxHeight: 116,
    display: "grid",
    placeItems: "center",
    variants: {
        color: {
            white: {
                backgroundColor: "transparent",
            },
            dark: {
                backgroundColor: "transparent",
                "& svg": {
                    "& path": { fill: defaultColors.grayscale0 },
                }
            },
            color: {
                "& svg": {
                    "& [class$=dots]": {
                        fill: defaultColors.grayscale0
                    }
                }
            }
        },
        brand: LogoBrandsVariants.dotsDefaultVariantsColor
    },
    compoundVariants: [
        ...LogoBrandsVariants.dotsColorVariantsColor,
        {
            brand: 'topnewsy',
            color: 'dark',
            css: {
                "& svg": {
                    stroke: "$topnewsyPrimary"
                }
            }
        },
    ]
})

export default Logo