import React from "react";
import Media from "../../..//components/common/Media";
import PodcastPosterPlaceholder from "./PodcastPosterPlaceholder";

interface Props {
    media?: any;
    img?: string;
}

const PodcastPoster: React.FC<Props> = ({ media, img }) => {

    return (
        <div className="podcastPlayerNav__poster">
            {media && (
                <Media
                    media={media}
                    src={img}
                    alt={'Podcast player thumbnail'}
                    height={64}
                    width={64}
                    aspectRatio={1}
                    preserveRatio={true}
                />
            )}
            {!media && <PodcastPosterPlaceholder />}
        </div>
    );

};

export default PodcastPoster;