import * as React from "react"
import { useInterval, pushDataLayer } from "../helpers";

type Action = { type: 'play' | 'pause' | 'toggle_play' }
    | { type: 'set_offset', payload: State["circleOffset"] }
    | { type: 'increment_file_count' | 'reset_file_count' }
    | { type: 'set_touched' };
// type Dispatch = (action: Action) => void
type State = {
    playing: boolean;
    circleOffset: string | number;
    touched: boolean;
    fileCount: number;
    available: boolean;
};
type AudioBriefProviderProps = { children: React.ReactNode }
type HandlePlay = () => void
type CircleProperties = {
    radius: number;
    stroke: number;
    fixedRadius: number;
    circ: number
}

const AudioBriefContext = React.createContext<{ state: State; handlePlay: HandlePlay; circleProps: CircleProperties } | undefined>(undefined);

function audioBriefReducer(state: State, action: Action) {
    switch (action.type) {
        case 'play':
            return {
                ...state,
                playing: true
            }
        case 'pause':
            return {
                ...state,
                playing: false
            }
        case 'toggle_play':
            return {
                ...state,
                playing: !state.playing
            }
        case 'set_offset':
            return {
                ...state,
                circleOffset: action.payload
            }
        case 'increment_file_count':
            return {
                ...state,
                fileCount: state.fileCount + 1
            }
        case 'reset_file_count':
            return {
                ...state,
                fileCount: 0
            }
        case 'set_touched':
            return {
                ...state,
                touched: true
            }
        default:
            throw new Error('Unhandled action')
    }
}

const radius: number = 12.5;
const stroke: number = 2.5;
const fixedRadius: number = radius - stroke / 2;
const circ: number = 2 * Math.PI * fixedRadius;

const circleProps: CircleProperties = {
    radius,
    stroke,
    fixedRadius,
    circ
}

const AudioBriefProvider = ({ children }: AudioBriefProviderProps) => {
    const [state, dispatch] = React.useReducer(audioBriefReducer, {
        playing: false,
        circleOffset: 0,
        touched: false,
        fileCount: 0,
        available: !!window.APP_SETTINGS?.audiobrief?.enabled && !!window.APP_SETTINGS?.audiobrief?.audio_files
    })

    const player = React.useRef<HTMLAudioElement>(null);

    const { audiobrief } = window.APP_SETTINGS;
    const playlistLength = audiobrief?.audio_files?.length || 0;

    const onPlayingEnded = () => {
        const fileName = `${audiobrief?.audio_files?.[state.fileCount].OID}_${audiobrief?.audio_files?.[state.fileCount]?.FileName}`
        window._gaq.push(['_trackEvent', 'Audio Brief', 'Audio ended', fileName])
        pushDataLayer({
            'event': 'analyticsEvent',
            'eventCategory': 'Audio Brief',
            'eventAction': 'Audio ended',
            'eventLabel': fileName,
            'eventValue': undefined
        })
        // setPlaying(false)
        dispatch({ type: 'pause' })
        // setProgress(0)
        dispatch({ type: 'set_offset', payload: 0 })
        if (state.fileCount + 1 < playlistLength) {
            // setFileCount(fileCount + 1)
            dispatch({ type: 'increment_file_count' })
        } else {
            // setFileCount(0)
            dispatch({ type: 'reset_file_count' })
        }
    }

    const handlePlay = () => {
        if (!state.touched) {
            // setTouched(true)
            dispatch({ type: 'set_touched' })
        }
        // setPlaying(prev => !prev)
        dispatch({ type: 'toggle_play' })
        const eventName = state.playing ? 'Pause' : 'Play'
        const fileName = `${audiobrief?.audio_files?.[state.fileCount]?.OID}_${audiobrief?.audio_files?.[state.fileCount]?.FileName}`
        window._gaq.push(['_trackEvent', 'Audio Brief', eventName, fileName])

        pushDataLayer({
            'event': 'analyticsEvent',
            'eventCategory': 'Audio Brief',
            'eventAction': eventName,
            'eventLabel': fileName,
            'eventValue': undefined
        })
        if (player.current !== null) {
            state.playing ? player.current.pause() : player.current.play();
        }
    }

    useInterval(() => {
        // setProgress(player.current.currentTime / player.current.duration)
        if (player.current !== null) {
            dispatch({ type: 'set_offset', payload: (circ - (player.current.currentTime / player.current.duration) * circ).toFixed(4) })
        }
    }, state.playing ? 150 : null)


    React.useEffect(() => {
        if (state.fileCount < playlistLength && state.fileCount > 0 && player.current !== null) {
            player.current.load();
            handlePlay();
        }
    }, [state.fileCount])

    const value = { state, handlePlay, circleProps }
    if (!state.available) return <AudioBriefContext.Provider value={value}>{children}</AudioBriefContext.Provider>
    return (
        <AudioBriefContext.Provider value={value}>
            <audio ref={player} onEnded={onPlayingEnded} preload="none">
                <source src={audiobrief?.audio_files?.[state.fileCount]?.url} type="audio/mpeg" />
            </audio>
            {children}
        </AudioBriefContext.Provider>
    )
}

const useAudioBrief = () => {
    const context = React.useContext(AudioBriefContext)

    if (context === undefined) {
        throw new Error('useAudioBrief must be used within AudioBriefProvider')
    }
    return context
}

export { AudioBriefProvider as default, useAudioBrief }
