import axios, { AxiosResponse } from "axios"

export type SignupUserData = {
    email: string;
    password: string;
}

export async function signupUser(userData: SignupUserData): Promise<AxiosResponse<SignupUserData>> {
    try {
        const res = await axios.post(`${window.API_HOST}/api/account/signup`, userData)
        if (res?.data?.errors) {
            throw { status: res.status, errors: res.data.errors }
        } else if (res?.data?.access_token) {
            localStorage.setItem("access_token", res.data.access_token);
        }

        return res.data
    } catch (err) {
        if (axios.isAxiosError(err)) {
            throw { status: err.response!.status, errors: err.response!.data.errors }
        } else {
            throw err
        }
    }
}