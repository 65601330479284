const ArticleCacheChannel = new MessageChannel();

export default ArticleCacheChannel;

/*--Message layer types--*/
export interface IArticleCacheWorkerMessageData { };

export interface IArticleCacheWorkerMessage<T extends IArticleCacheWorkerMessageData = IArticleCacheWorkerMessageData> {
    command: "PRELOAD" | "RELOAD" | "CHECK" | "STATUS",
    data: T;
};

/*--Message layer data types--*/

//Preload data
export interface IArticleCacheWorkerMessagePreloadData extends IArticleCacheWorkerMessageData {
    slug: string;
    force?: boolean;
};

//Reload data
export interface IArticleCacheWorkerMessageReloadData extends IArticleCacheWorkerMessageData {
    slug: string;
};

//Check data
export interface IArticleCacheWorkerMessageCheckData extends IArticleCacheWorkerMessageData {
    slug: string;
};

//Status data
export interface IArticleCacheWorkerMessageStatusData extends IArticleCacheWorkerMessageData {
    slug: string;
    available: boolean;
    articleData?: any;
};