import ArticleStore from "./ArticleStore";
import CategoryStore from "./CategoryStore";
import UIModel from "./UIStore";
import ReadLaterStore from "./ReadLaterStore";
import TopicStore from './TopicStore';
import UserStore from './UserStore';
import AdsStore from './AdsStore';
import PodcastStore from './PodcastStore';

export class RootModel {
  ArticleStore: ArticleStore;
  CategoryStore: CategoryStore;
  UIStore: UIModel;
  ReadLaterStore: ReadLaterStore;
  TopicStore: TopicStore;
  AdsStore: AdsStore;
  UserStore: UserStore;
  PodcastStore: PodcastStore;
  constructor() {
    this.ArticleStore = new ArticleStore(this);
    this.CategoryStore = new CategoryStore(this);
    this.UIStore = new UIModel(this);
    this.ReadLaterStore = new ReadLaterStore(this);
    this.TopicStore = new TopicStore(this);
    this.AdsStore = new AdsStore(this);
    this.UserStore = new UserStore();
    this.PodcastStore = new PodcastStore(this);
  }
}

const RootStore = new RootModel();

RootStore.ReadLaterStore.updateReadLaterArticles();

export default RootStore;
