import { observable, action, computed } from "mobx";


import { pushDataLayer } from "../helpers/pushDataLayer"

class UserStore {

    @observable status = null;
    message = null;

    fetchedUser = { user: { type: "natemat" } };
    currentSlug = null;

    @action('Change store status')
    setStatus = (status, message = null) => {
        this.message = message;
        this.status = status;
    }

    @action("Display User")
    displayUser = user => {
        this.fetchedUser = user;
        this.setStatus("valid");
    };

    @action("Get category based on current URL")
    updateSlug = (slug = "") => {
        if (this.currentSlug !== slug) {
            // prawdopodobnie "wstecz", brak aktualizacji
            this.currentSlug = slug;
            this.setStatus("loading");
        }
        this._fetchFromNetwork(slug, true);
    };

    _fetchFromNetwork = (slug, justUpdate = false) => {
        const that = this,
            fetchUrl = `${window.API_HOST}/pwa-user/${slug}`;

        fetch(fetchUrl)
            .then(response => {
                return response.ok
                    ? response.json()
                    : Promise.reject(response.status);
            })
            .then(res => {
                pushDataLayer({
                    'event': 'virtualPageview',
                    'virtualPageURL': `/${res.user.url}`,
                    'virtualPageTitle': res.user.name,
                    'pageType': 'Pozostałe'
                })
                that.displayUser(res);
                // this._saveToDb(response, slug);
            })
            .catch(err => {
                window.APP_TRACK_ERROR("UserStore-fetchFromNetwork", { url: fetchUrl, error: err });

                if (err === 404) {
                    that.setStatus("error", "Nie znaleziono takiej strony");
                    return;
                } else if (justUpdate) {
                    return;
                } else if (!window.navigator.onLine) {
                    that.setStatus("error", "Brak połączenia z internetem. Nadal możesz korzystać z aplikacji, choć część treści, tak jak ta, będzie niedostępna");
                    return;
                } else {
                    that.setStatus("error", "Ups! Wystąpił problem podczas pobierania strony. Sprawdź połączenie z internetem i spróbuj ponownie");
                    return;
                }
            });
    };
}

export default UserStore;
