import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { startPayment, isPaymentFormError } from "../../../api/payment";
import { styled } from "../../../stitches.config";
import { withThemeContext } from "../../components/StitchesThemeProvider";
import { PaymentStatus, PaymentType } from "../../types/Payment";
import TipFormBasic from "./TipFormBasic";

interface Props {
  colorMode?: string;
  dark?: boolean;
  descriptionText: string;
  allAuthors: string[];
  articleId?: string;
  origin: string;
}

const TipFormStandalone: React.FC<Props> = ({
  colorMode,
  allAuthors,
  dark,
  descriptionText,
  articleId,
  origin
}) => {
  const [activeRequest, setActiveRequest] = React.useState(false)
  const [urlBlikStatus, setUrlBlikStatus] = React.useState("")
  const [checkedBlikStatus, setCheckedBlikStatus] = React.useState(false)
  const fetchUrl = `${window.API_HOST}/api/payment/check-success?token=${urlBlikStatus}`;
  //const fetchUrl = `https://natemat-staging.jadwiga.easysoftware.pl/api/payment/check-success?token=A3FCF09B85-CB2285-DC6B33-3F6FF855E7`;

  const isDarkMode = dark || colorMode === "dark";
  const location = useLocation();
  const navigate = useNavigate();
  const isManyAuthors = (allAuthors && allAuthors.length > 1);
  const userOidJoined = allAuthors.join(",");

  const getManyAuthorsParams = (status: string, data?: any,) => {
    return `modal-type=PAYMENT&payment-type=TIP&payment-editor-id=${userOidJoined}&payment-article-id=""&payment-status=${status}&payment-origin=${origin}`;
  };

  const onClick = async (
    amount: number,
    email: string,
    blikPay: boolean,
    message?: string,
    blikCode?: string
  ) => {
    const payload = {
      amount: amount,
      termsAndCondition: true,
      blikPay: blikPay,
      message: message,
      email: email,
      User_OID: userOidJoined,
      articleId,
      blikCode: blikCode,
      origin
    };

    try {
      const paymentResponseData = await startPayment(payload, PaymentType.TIP);

      setUrlBlikStatus(paymentResponseData?.token)
      setActiveRequest(true)

      navigate(
        `${location.pathname}?${getManyAuthorsParams(
          PaymentStatus.WAITING
          //PaymentStatus.SUCCESS
          //  status, data
        )}`
      );

    } catch (error) {
      if (isPaymentFormError(error))
        throw error;

      navigate(
        `${location.pathname}?${getManyAuthorsParams(
          PaymentStatus.FAILED
        )}`
      );
    }
  };


  useEffect(() => {

    if (activeRequest) {
      const getStatus = function () {
        fetch(fetchUrl)
          .then(result => result.json())
          .then(result => setCheckedBlikStatus(result))
        console.log("test timer")
      }
      getStatus()
      const interval = setInterval(() => getStatus(), 2000)
      return () => {
        clearInterval(interval);
      }
    }
  }, [activeRequest])

  useEffect(() => {
    console.log("test url: ", fetchUrl)
    console.log("test url boolean: ", checkedBlikStatus)

    if (checkedBlikStatus) {
      setActiveRequest(false)
      navigate(
        `${location.pathname}?${getManyAuthorsParams(
          PaymentStatus.SUCCESS
        )}`
      );
    }
  }, [urlBlikStatus, checkedBlikStatus])

  return (
    <TipWrapper isDarkMode={isDarkMode}>
      <TipFormBasic
        onPayButtonClick={onClick}
        descriptionText={descriptionText}
        isManyAuthors={isManyAuthors}
      />
    </TipWrapper>
  );
};

export default withThemeContext(TipFormStandalone);

const TipWrapper = styled("div", {
  background: "$grayscale100",
  marginTop: "32px",
  position: "relative",
  color: "$grayscale0 !important",
  minHeight: "905px",
  "@bp4": {
    minHeight: "546px"
  },
  "a, input, textarea": {
    color: "$grayscale0 !important",
  },
  a: {
    color: "$primary !important",
  },
  "button[type=submit]": {
    backgroundColor: "$primary !important",
    color: "$grayscale0 !important",
  },
  "& > div": {
    padding: "40px 20px",
  },
  variants: {
    isDarkMode: {
      true: {
        "input,textarea": {
          borderColor: "rgba(0, 0, 0, 0.15)",
        },
      },
      false: {
        "input,textarea": {
          borderColor: "rgba(255, 255, 255, 0.15)",
        },
      },
    },
  },
});
