import { useState, useEffect } from 'react'
/*TODO może przenieść do UIStore albo podawać tego hooka do komponentów przez kontekst? 
aktualnie jest ponad 20 komponentów w ktorych sprawdzamy window.innerWidth (> || <) 992 + nie działa przy resizie*/
export function useWindowWidth(): number {
    const [width, setWidth] = useState<number>(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return width;
}