import React, { useState } from "react";
import AnimateHeight, { Height } from "react-animate-height";

import {
  h4Strong,
  h3,
  bodyLarge,
  bodyLargeStrong,
  body,
} from "../../../../redesign/commonStyles/Fonts";
import parse from "html-react-parser";

import { styled } from "../../../../stitches.config";

import ArrowIcon from "../../../../redesign/assets/icons/arrow-down-orange.svg";

interface Props {
  blockData: {
    date: string;
    budget: number;
    partnership?: string;
    partnershipLogoUrl?: string;
    title?: string;
    description?: string;
    text?: string;
  };
}

const WhatIsBrid: React.FC<Props> = ({ blockData }) => {
  const isDesktop = window.innerWidth > 996;
  const [height, setHeight] = useState<Height>(isDesktop ? 120 : 72);

  const title = blockData.title || "Czym jest BRID?";
  const description =
    blockData.description ||
    `Witaj! Trafiłeś do karty projektu BRID. Tutaj to nasi czytelnicy decydują, o czym chcą czytać w naTemat, a następnie mają szansę razem z nami współfinansować realizację tego pomysłu. Projekt, który widzisz, wygrał ostatnie głosowanie. Jeśli ci się podoba, możesz wspomóc nas w jego realizacji.`;
  const text = blockData?.text
    ? parse(blockData.text)
    : parse(`
  <p className={${bodyLargeStrong()}}>
  Osoby, które wspomogą nas w tworzeniu tego projektu, mogą liczyć
  na szereg korzyści:
</p>
<ul className={${bodyLarge()}}>
  <li>
    jako osoba wpłacająca masz możliwość zapisać się na newsletter
    poświęcony materiałowi,
  </li>
  <li>
    otrzymasz ekskluzywne wiadomości i zdjęcia od dziennikarzy,
    którzy tworzą projekt,
  </li>
  <li>
    otrzymasz informację o gotowości projektu oraz przedpremierowy
    dostęp do niego.
  </li>
</ul>
  `);

  return (
    <>
      {isDesktop ? (
        <StyledAnimate onClick={() => setHeight(height === 120 ? "auto" : 120)}>
          <AnimateHeight duration={500} height={height}>
            <UpperTile>
              <h4 className={isDesktop ? h3() : h4Strong()}>
                {title}
              </h4>
              <Icon className={height === 120 ? "" : "arrow-active"}>
                <img src={ArrowIcon} />
              </Icon>
            </UpperTile>
            <Line />
            <p className={bodyLarge()}>{description}</p>
            <p className={bodyLargeStrong()}>{text}</p>
          </AnimateHeight>
        </StyledAnimate>
      ) : (
        <StyledAnimate onClick={() => setHeight(height === 72 ? "auto" : 72)}>
          <AnimateHeight duration={500} height={height}>
            <UpperTile>
              <h4 className={isDesktop ? h3() : h4Strong()}>{title}</h4>
              <Icon className={height === 72 ? "" : "arrow-active"}>
                <img src={ArrowIcon} />
              </Icon>
            </UpperTile>
            <Line />
            <p className={bodyLarge()}>{description}</p>
            <p className={bodyLargeStrong()}>{text}</p>
          </AnimateHeight>
        </StyledAnimate>
      )}
    </>
  );
};

const StyledAnimate = styled("div", {
  margin: "8px 0 16px 0",
  padding: "0px 16px 0px 16px",
  backgroundColor: "$grayscale0",
  color: "$grayscale100",
  "@bp4": {
    padding: "0px 48px 0px 48px",
  },
  ".arrow-active": {
    transform: "rotate(180deg)",
  },
  "& ul": {
    paddingBottom: "40px",
    "& li": {
      "&::marker": {
        color: "$primary",
        backgroundColor: "$primary",
      },
    },
  },
});

const Line = styled("div", {
  margin: "0 0 16px 0",
  width: "100%",
  height: "1px",
  backgroundColor: "$grayscale25",
});

const Icon = styled("div", {
  margin: " 0 16px 0 auto !important",
});

const UpperTile = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  cursor: "pointer",
  "& h4": {
    margin: 0,
    padding: "24px 0",
    "@bp4": {
      padding: "48px 0",
    },
  },
});

export default WhatIsBrid;
