import React, { createElement } from "react";

import Article from "../../pages/Article";
import ArticleSwiper from "../ArticleSwiper";
import NoSSR from "../../helpers/NoSSR";

import { IS_ARTICLE_SWIPER_ENABLE } from "../../components/common/constants/constants";
import { TaboolaNewsroomWrapper } from "../../helpers/TaboolaNewsroom";

type ArticlePageType = "zdrowie" | "job" | "blog" | "quiz" | "default";

const ArticlePage: React.FC<{ type?: ArticlePageType }> = ({ type = "default" }) => {

    return (
        <TaboolaNewsroomWrapper newsroomPage="article">
            <Article />
            {IS_ARTICLE_SWIPER_ENABLE && type == 'default' && (<NoSSR fallback={null}><ArticleSwiper /></NoSSR>)}
        </TaboolaNewsroomWrapper>
    );

};

export default ArticlePage;