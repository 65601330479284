type SimpleEvent = {
    event: string;
    eventCategory?: string;
    eventAction?: string;
}

type DataLayerType = {
    event: string;
    articleSection: string;
    articleTag: string;
    articleAuthor: string;
    articleCharacterRange: string;
}
type PageViewType = DataLayerType & {
    virtualPageURL: string;
    virtualPageTitle: string;
    pageType: string;
}
type EventType = DataLayerType & {
    articleUrl: string;
    articleTitle: string;
}
type GenericAnalyticsType = {
    event: 'analyticsEvent';
    eventCategory: string;
    eventAction: string;
    eventLabel: string | undefined;
    eventValue: string | undefined;
    eventCallback?: () => any;
    eventTimeout?: number;
}

type DataLayerObject = SimpleEvent | PageViewType | EventType | GenericAnalyticsType;

export const pushDataLayer = (data: DataLayerObject): void => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(data);
}