import React, { useEffect } from "react";
import { styled } from "../../stitches.config";
import { h4Strong } from "../commonStyles/Fonts";
import HomeIcon from "../assets/icons/home-icon.svg";
import Link from "../../components/Link";
import { withThemeContext } from "./StitchesThemeProvider";

interface GoBackToMainPageProps {
  isDarkMode?: boolean;
}

const GoBackToMainPage: React.FC<GoBackToMainPageProps> = ({
  isDarkMode,
  ...props
}) => {

  return (
    <Wrap>
      <StyledGoBackToMainPage
        className={isDarkMode ? "theme-background" : "dark-background"}
      >
        <Link to="/">
          <StyledHomeIcon></StyledHomeIcon>
          <p className={h4Strong()}>
            Kliknij lub skroluj dalej, aby wrócić na stronę główną
          </p>
        </Link>
      </StyledGoBackToMainPage>
    </Wrap>
  );
};

const StyledGoBackToMainPage = styled("div", {
  padding: "16px 63.5px",
  "@bp4": {
    padding: "27px 0 29px 0",
  },
  "& a": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 auto",
    "@bp4": {
      width: "max-content",
    },
    "&:hover": {
      textDecoration: "none",
      color: "$grayscale0",
    },
  },
  "& p": {
    margin: "0",
    textAlign: "center",
    color: "$grayscale0Fixed",
  },
});

const StyledHomeIcon = styled("div", {
  display: "none",
  "@bp4": {
    display: "flex",
    marginRight: "16px",
    width: "16px",
    height: "17px",
    backgroundImage: `url(${HomeIcon})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
});

const Wrap = styled("div", {
  "& .dark-background": {
    background: "$grayscale100",
  },
  "& .theme-background": {
    background: "$primary",
  },
});

export default withThemeContext(GoBackToMainPage);
