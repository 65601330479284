import React, { useEffect, useState, startTransition, ComponentType } from 'react';
import { createElement } from 'react';

const NoSSR: React.FC<React.SuspenseProps> = ({ fallback, children }) => {

    const [isInit, setInit] = useState<boolean>(false);

    //@ts-ignore
    useEffect(() => startTransition(() => setInit(!window.SSR)), []);

    return (
        <React.Suspense fallback={fallback}>
            {isInit ? children : fallback}
        </React.Suspense>
    );

};

export default NoSSR;

export function withNoSSR<P extends {}>(Component: ComponentType<P>, fallback: React.ReactNode | null = null) {

    return (props: P) => (
        <NoSSR fallback={fallback}>
            {createElement(Component, props)}
        </NoSSR>
    )

}