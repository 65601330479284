import React from "react";
import { styled } from "../../stitches.config";
import { bodyLarge } from "../commonStyles/Fonts";
import { ReactComponent as Star } from "../assets/icons/star-icon.svg";

interface ButtonProps {
  size?: "big" | "normal" | "small";
  type?: "primary" | "black" | "secondary" | "blackSecondary";
  starPosition?: "left" | "center" | "right" | "none";
  text: string;
  disabled?: boolean;
  onClick?: () => void;
}

const Button: React.FC<ButtonProps> = ({
  size = "normal",
  type = "primary",
  starPosition = "left",
  text,
  disabled,
  onClick,
  ...props
}) => {
  return (
    <StyledButton
      size={size}
      color={type}
      className={bodyLarge()}
      disabled={disabled}
      onClick={onClick}
    >
      {starPosition === "left" && (
        <>
          <div className="star-on-left star">
            <Star />
          </div>
          {text}
        </>
      )}
      {starPosition === "right" && (
        <>
          {text}
          <div className="star-on-right star">
            <Star />
          </div>
        </>
      )}
      {starPosition === "center" && (
        <>
          <div className="star-center star">
            <Star />
          </div>
        </>
      )}
      {starPosition === "none" && <>{text}</>}
    </StyledButton>
  );
};

const StyledButton = styled("button", {
  display: "flex",
  alignItems: "center",
  "& .star": {
    width: "16px",
    height: "16px",
  },
  "& .star-on-left": {
    marginRight: "8px",
  },
  "& .star-on-right": {
    marginLeft: "8px",
  },
  variants: {
    color: {
      primary: {
        color: "$grayscale0Fixed",
        backgroundColor: "$primary",
        border: "2px solid $primary",
        "&:hover": {
          backgroundColor: "$primary120",
          border: "2px solid $primary120",
        },
        "&:disabled": {
          color: "$grayscale75",
          backgroundColor: "$grayscale25",
          border: "2px solid $grayscale25",
        },
        "&:focus": {
          border: "2px solid $primary120",
        },
        "&:active": {
          backgroundColor: "$primary120",
          border: "2px solid $primary120",
        },
        "& .star": {
          "& svg": {
            fill: "$grayscale0 !important",
          },
        },
      },
      black: {
        color: "$grayscale0",
        backgroundColor: "$grayscale100",
        border: "2px solid $grayscale100",
        "&:hover": {
          backgroundColor: "$darkmodeStroke",
          border: "2px solid $darkmodeStroke",
        },
        "&:disabled": {
          color: "$grayscale75",
          backgroundColor: "$grayscale0",
          border: "2px solid $grayscale75",
        },
        "&:focus": {
          border: "2px solid $primary",
        },
        "&:active": {
          backgroundColor: "$darkmodeBg",
          border: "2px solid $darkmodeBg",
        },
        "& svg": {
          fill: "$grayscale0 !important",
        },
      },
      secondary: {
        color: "$grayscale100",
        backgroundColor: "$grayscale0",
        border: "2px solid $grayscale10",
        "&:hover": {
          backgroundColor: "$grayscale10",
        },
        "&:disabled": {
          color: "$grayscale75",
          backgroundColor: "$grayscale0",
          border: "2px solid $grayscale75",
        },
        "&:focus": {
          border: "2px solid $primary",
          backgroundColor: "$grayscale0",
        },
        "&:active": {
          backgroundColor: "$grayscale10",
        },
        "& .star": {
          "& svg": {
            fill: "$primary !important",
          },
        },
      },
      blackSecondary: {
        color: "$grayscale0",
        backgroundColor: "$grayscale100",
        border: "2px solid $darkmodeStroke",
        "&:hover": {
          backgroundColor: "$darkmodeBg",
        },
        "&:disabled": {
          color: "$darkmodeStroke",
          backgroundColor: "$grayscale100",
          border: "2px solid $darkmodeStroke",
        },
        "&:focus": {
          border: "2px solid $primary",
        },
        "&:active": {
          backgroundColor: "$darkmodeStroke",
          border: "2px solid $darkmodeStroke",
        },
        "& svg": {
          fill: "$primary !important",
        },
      },
    },
    size: {
      small: {
        padding: "4px 8px 6px 8px",
        "& .star-center": {
          margin: "4px 0 2px 0",
        },
      },
      normal: {
        padding: "5px 12px 7px 12px",
        "& .star-center": {
          margin: "7px 0 5px 0",
        },
      },
      big: {
        padding: "9px 14px 11px 14px",
        "& .star-center": {
          margin: "5px 0 3px 0",
        },
      },
    },
  },
});

export default Button;
