import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { defaultColors, styled } from "../../stitches.config";
import SVGTip from "../assets/icons/tip.svg";
import { bodyStrong } from "../commonStyles/Fonts";

interface Props {
  author: any;
  articleID: string;
}

const TipWidget: React.FC<Props> = ({ author, articleID }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const onWrapperClick = () => {
    navigate(
      `${location.pathname}?modal-type=PAYMENT&payment-type=TIP&payment-editor-id=${author.OID}&payment-status=pending&payment-article-id=${articleID}&payment-origin=tip-popup`
    );
  };
  return (
    <>
      <DesktopWrapper onClick={onWrapperClick}>
        <img src={SVGTip} />
        <p className={bodyStrong()}>Daj napiwek autorowi</p>
      </DesktopWrapper>
      <MobileButton onClick={onWrapperClick}>
        <p className={bodyStrong()}>Daj napiwek autorowi</p>
        <img src={SVGTip} />
      </MobileButton>
    </>
  );
};

export default TipWidget;

const DesktopWrapper = styled("div", {
  display: "none",
  position: "fixed",
  cursor: "pointer",
  bottom: "10%",
  right: "10%",
  zIndex: 100,
  borderRadius: "50%",
  width: "56px",
  height: "56px",
  background: "$primary",
  padding: "1rem",
  color: defaultColors.grayscale0,
  transition: "all 0.55s cubic-bezier(0.645, 0.045, 0.355, 1)",
  img: {
    transform: "translate(6px, 5px)",
  },

  p: {
    position: "absolute",
    top: "4px",
    left: "50px",
    opacity: "0",
    transitionDelay: "2s",
    transition: "all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1)",
  },

  "&:hover": {
    borderRadius: "50px",
    width: "225px",
    height: "56px",
    background: "$primary",
    transition:
      "all 0.55s cubic-bezier(0.645, 0.045, 0.355, 1), border-radius 0.15s cubic-bezier(0.645, 0.045, 0.355, 1)",
    p: {
      opacity: 1,
      transitionDelay: "2s",
      transition: "opacity 1s cubic-bezier(0.645, 0.045, 0.355, 1)",
    },
  },

  "@bp4": {
    display: "block",
  },
});

const MobileButton = styled("button", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop: '16px',
  marginBottom: '16px',
  backgroundColor: "$primary",
  color: defaultColors.grayscale0,
  position: "relative",
  marginLeft: "12px",
  width: "calc(100% - 24px)",
  border: "none",
  gap: '10px',
  "@bp4": {
    display: "none",
  },
});
