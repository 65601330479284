import * as React from "react"
import { styled } from "../../../../stitches.config"

import RenderSlateChildren from "./RenderSlateChildren"
import { OrderedListElement } from "./types"

type OrderedListProps = {
    element: OrderedListElement;
}

const OrderedList: React.FC<OrderedListProps> = ({ element }) => {
    return (
        <NumeredListBizon>
            {element.children.map((li, index) => (<li key={index}>
                {li.children.map(lic => (
                    <RenderSlateChildren element={lic.children} />
                ))}
            </li>))}
        </NumeredListBizon>
    );
}

const NumeredListBizon = styled("ol", {
    paddingLeft: "32px",
    fontSize: "16px",
    fontFamily: "$charis",
    fontWeight: 400,
    lineHeight: "26px",
    "@bp4": {
        paddingLeft: "60px",
        fontSize: "21px",
        lineHeight: "32px"
    },
    li: {
        "& > div": {
            display: "inline-block"
        }
    }
});


export default OrderedList