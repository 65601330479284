export const adSlotEnum = Object.freeze({
    "art_belka_top": "art_belka_top",
    "sg_belka_top": "sg_belka_top",
    "kat_belka_top": "kat_belka_top",
    "interstitialBanner": "layer_1x1",
    "anchorBanner": "belka_sticky",
    "SG_Billboard_1": "sg_bill1",
    "SG_Billboard_2": "sg_bill2",
    "SG_Billboard_3": "sg_bill3",
    "SG_Billboard_4": "sg_bill4",
    "SG_Billboard_5": "sg_bill5",
    "SG_Billboard_6": "sg_bill6",
    "SG_Halfpgae_1": "sg_half1",
    "SG_Halfpgae_2": "sg_half2",
    "SG_Halfpgae_3": "sg_half3",
    "SG_Halfpgae_4": "sg_half4",
    "SG_Halfpgae_5": "sg_half5",
    "KAT_Billboard_1": "kat_bill1",
    "KAT_Billboard_2": "kat_bill2",
    "KAT_Billboard_3": "kat_bill3",
    "KAT_Halfpage_1": "kat_half1",
    "KAT_Halfpage_2": "kat_half2",
    "KAT_Halfpage_3": "kat_half3",
    "ART_Billboard_1": "art_bill1",
    "ART_Billboard_2": "art_bill2",
    "ART_Billboard_3": "art_bill3",
    "ART_Billboard_4": "art_bill4",
    "ART_Billboard_5": "art_bill5",
    "ART_Halfpage_1": "art_half1",
    "ART_Halfpage_2": "art_half2",
    "ART_Halfpage_3": "art_half3",
    "ART_Halfpage_4": "art_half4",
    "ART_Halfpage_5": "art_half5",
    "ART_Halfpage_6": "art_half6",
    "ART_Halfpage_7": "art_half7",
    "ART_Halfpage_8": "art_half8",
    "ART_Halfpage_9": "art_half9",
    "ART_Halfpage_10": "art_half10",
    "ART_intext_1": "art_intext1",
    "ART_intext_2": "art_intext2",
    "ART_intext_3": "art_intext3",
    "ART_intext_4": "art_intext4",
    "ART_intext_5": "art_intext5",
    "ART_intext_6": "art_intext6",
    "ART_intext_7": "art_intext7",
    "ART_intext_8": "art_intext8",
    "ART_intext_9": "art_intext9",
    "ART_intext_10": "art_intext10",
    "ART_intext_11": "art_intext11",
    "ART_intext_12": "art_intext12",
    "ART_intext_13": "art_intext13",
    "ART_intext_14": "art_intext14",
    "ART_intext_15": "art_intext15",
    "Brid_intext": "\\/129257696\\/natemat\\/brid_tekst",
    "Brid_halfpage": "\\/129257696\\/natemat\\/brid_prawastrona",
    "Brid_rectangle": "\\/129257696\\/natemat\\/brid_dol"
})