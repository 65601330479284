import * as React from "react";
import { styled } from "../../../../stitches.config";
import Media from "../../../common/Media"
import ConditionalWrapper from "../../../../helpers/ConditionalWrapper"
import Link from "../../../Link"
import { MediaPropertiesType } from "./types"

import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

export interface ExtendedProps {
  pictures: Array<MediaPropertiesType>;
  disablePreload: boolean;
}

type FadingPictureProps = {
  artIndex?: number;
  data: ExtendedProps;
}

const FadingPicture: React.FC<FadingPictureProps> = ({ artIndex, data, ...props }) => {

  const settings = {
    infinite: true,
    dots: false,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    fade: true,
    pauseOnHover: false,
    cssEase: 'ease-out',
  };

  data.pictures.map((picture, index) => (
    console.log("test fading-picture: ", index, picture)
  ));
  return <div>
    <FigureWrapper isArtTop={artIndex == 0}>
      <Slider {...settings}>
        {data.pictures.map((picture, index) => (
          <ConditionalWrapper
            condition={!!picture.link}
            wrapper={(children) => <Link to={picture.link}>{children}</Link>}
          >
            <Media
              src={picture.img}
              alt={picture.alt}
              title={picture.title}
              height={picture.height || picture.media?.height}
              width={picture.width || picture.media?.width}
              disablePreload={true}
              preserveRatio={true}
            />
          </ConditionalWrapper>
        ))}
      </Slider>
    </FigureWrapper>
  </div >
}

const FigureWrapper = styled("figure", {
  position: "relative",
  margin: "32px 0",
  "@bp4": {
    margin: "56px 0"
  },
  "& > div": {
    minWidth: "100%"
  },
  "img": {
    width: "100%",
    height: "auto"
  },
  "figcaption": {
    fontStyle: "normal",
    fontSize: "14px",
    padding: "8px 0 0 0",
    lineHeight: "18px",
    color: "#8D8D8D",
    textAlign: "center",
    fontWeight: 600,
    minHeight: "48px",
    "@bp4": {
      padding: "12px 0 0 0",
      minHeight: "30px"
    },
    span: {
      position: "relative"
    }
  },
  variants: {
    /* #5432 brak marginu górnego w głównym zdjęciu artykułowym */
    "isArtTop": {
      true: {
        margin: "0 0 32px 0",
        "@bp4": {
          margin: "0 0 56px 0"
        }
      },
      false: {}
    }
  }
});


export default FadingPicture;