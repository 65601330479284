import React from "react";
import { withlazyload } from "./HOCs/WithLazyLoad"

@withlazyload({
  height: 5,
  once: true,
  offset: 10
})
export default class TrackEvent extends React.Component {
  render() {
    const { name, category, label } = this.props;
    window._gaq.push(["_trackEvent", category, name, label]);
    // alert('Track event: ' + category + '/' + name);

    return (
      <div data-track-category={category} data-track-name={name} data-track={label}>
      </div>
    );
  }
}

