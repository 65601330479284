import * as React from "react"

const useBreakpoint = (breakpoint: number): boolean => {
    const [isDesktop, setIsDesktop] = React.useState<boolean>(window.innerWidth > breakpoint);

    React.useEffect(() => {
        const handleResize = (): void => {
            const _isDesktop: boolean = window.innerWidth > breakpoint;
            if (_isDesktop !== isDesktop) setIsDesktop(_isDesktop);
        }
        window.addEventListener("resize", handleResize)

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [isDesktop])

    return isDesktop
}

export { useBreakpoint }

