import React, { useState, useEffect, useRef } from "react";
import Link from "../../components/Link";
import { styled } from "../../stitches.config";
import CategoryBadge from "../CategoryBadge";


// embedy
import Youtube from "../../components/Article/Parts/Youtube";
import OnnetworkPlayer from "../../components/OnnetworkPlayer";

interface IProps {
    article: any;
    style?: string;
    withBackground?: boolean;
    isEmbedTextOnPhoto?: boolean;
    isOneSuper?: boolean;
}

const UniversalEmbedBox: React.FC<IProps> = ({
    article,
    style,
    withBackground,
    isEmbedTextOnPhoto,
    isOneSuper,
    ...props
}) => {
    const [height, setHeight] = useState(200);
    const isDesktop = window.innerWidth > 996;
    const pictureContainer = useRef<HTMLDivElement>(null);
    const partner = (article.partner ? <Partner>{article.partner}</Partner> : null);
    const categoryBadge = (article?.category && article.category?.name ?
        <div className="category">
            <CategoryBadge
                text={article.category.name}
                showMark
                markText=""
                categoryColor={article.categoryColor}
            />
        </div>
        :
        null
    );


    useEffect(() => {
        if (pictureContainer?.current?.clientWidth) {
            const eltWidth = pictureContainer?.current?.clientWidth;
            const cHeight = Math.ceil((eltWidth * 2) / 3);

            if (cHeight !== height) {
                setHeight(cHeight);
            }
        }
    }, []);

    var embed = null;

    if (article.embed && article.embed.type) {
        if (article.embed.type == 'youtube') {
            embed = <EmbedElement>
                <Youtube isOneSuper={isOneSuper} videoId={article.embed.id} />
            </EmbedElement>
        } else if (article.embed.type == 'onnetwork') {
            embed = <EmbedElement>
                <OnnetworkPlayer mid={article.embed.mid} />
            </EmbedElement>
        }
    }


    return (
        <>
            {isEmbedTextOnPhoto && isDesktop ?
                <> {isOneSuper ?
                    <TextOnPhotoWrapper className={"text-on-picture style--" + style}>
                        <Link to={article.settings?.link || article.url} data-analytics-name="universal-embed-box">
                            <div className="embed-wrap embed-isSuperOne">
                                {embed}
                            </div>
                        </Link>
                    </TextOnPhotoWrapper>
                    :
                    <TextOnPhotoWrapper className={"text-on-picture style--" + style}>
                        <div className="embed-wrap">
                            {embed}
                        </div>
                        {partner}
                        {categoryBadge}
                    </TextOnPhotoWrapper>
                }
                </>
                :
                <Wrapper
                    className={
                        "photo-on-top photo-on-top-embed style--" +
                        style +
                        (withBackground ? " style--with-padding" : "")
                    }
                >
                    <StyledPhotoOnTop ref={pictureContainer} size={article.size}>
                        <StyledImage>
                            {embed}
                            {partner}
                            {categoryBadge}
                        </StyledImage>
                    </StyledPhotoOnTop>
                    {article.embed.type == 'onnetwork' ?
                        <StyledWrap size={article?.size}>
                            <span className="title">
                                {article?.settings?.title_normal || article?.pile_title || article?.title}
                            </span>
                        </StyledWrap> :
                        <Link to={article.settings?.link || article.url} data-analytics-name="universal-embed-box">
                            <StyledWrap size={article?.size}>
                                <span className="title">
                                    {article?.settings?.title_normal || article?.pile_title || article?.title}
                                </span>
                            </StyledWrap>
                        </Link>
                    }
                </Wrapper>
            }
        </>
    );
};

const Partner = styled("span", {
    display: 'block',
    position: 'absolute',
    left: '0',
    top: '0',
    padding: '0px 8px',
    backgroundColor: '#D8D8D8',
    color: '#8B8B8B',
    lineHeight: '16px',
    fontSize: '11px',
    zIndex: '4',
});

const StyledPhotoOnTop = styled("div", {
    variants: {
        size: {
            lg: {
                "@bp4": {
                    width: "455px",
                    height: "330px",
                },
                "@bp5": {
                    width: "624px",
                    height: "416px",
                },
            },
            md: {
                "@bp4": {
                    width: "200px",
                },
                "@bp5": {
                    width: "408px",
                    height: "272px",
                },
            },
        },
    },
    "& a": {
        textDecoration: "none !important",
    },
});

const StyledImage = styled("div", {
    position: "relative",
    /*
    "& img": {
        width: "100%",
        height: "auto",
        display: "block",
    },
    */
    "& .category": {
        position: "absolute",
        bottom: "16px",
        left: "16px",
        zIndex: "3",
    },
});

const StyledWrap = styled("div", {
    display: "block",
    color: "$grayscale100",
    padding: "16px 0 0px",
    margin: "0 auto",
    "& .title": {
        fontFamily: "$grotesk",
        fontSize: 24,
        lineHeight: "30px",
        letterSpacing: "0.2px",
        fontWeight: "700",
    },
    "@bp4": {
        height: "111px",
        overflow: "hidden",

        "& .title": {
            fontSize: 18,
            lineHeight: "24px",
        },
    },
    "@bp5": {
        margin: "0",
        height: "87px",
        overflow: "hidden",
    },
    variants: {
        size: {
            lg: {
                "@bp5": {
                    width: "570px",
                    margin: "0",
                },
                p: {
                    fontSize: "24px",
                    lineHeight: "30px",
                    "@bp4": {
                        fontSize: "30px",
                        lineHeight: "34px",
                    },
                    "@bp5": {
                        fontSize: "38px",
                        lineHeight: "42px",
                    },
                },
            },
            md: {
                margin: "0",
                p: {
                    fontSize: "24px",
                    lineHeight: "30px",
                },
            },
        },
    },
});

const Wrapper = styled("div", {
    paddingBottom: "16px",
    marginBottom: "8px",

    "& a": {
        "&:hover": {
            textDecoration: "underline",
            textDecorationColor: "$grayscale100 !important",
        },
    },

    "&.style--red": {
        [`${StyledWrap} > span`]: {
            backgroundColor: "#EA3A3E",
            color: "white",
            boxShadow: "2px 0 0 red, -2px 0 0 #EA3A3E",
        },
        "& a": {
            "&:hover": {
                textDecorationColor: "$grayscale0Fixed !important",
            },
        },
    },

    "&.style--primary": {
        [`${StyledWrap} > span`]: {
            backgroundColor: "$primary",
            color: "white",
            boxShadow: "2px 0 0 red, -2px 0 0 $primary",
        },
        "& a": {
            "&:hover": {
                textDecorationColor: "$grayscale0Fixed !important",
            },
        },
    },

    "&.style--black": {
        [`${StyledWrap} > span`]: {
            backgroundColor: "#131212",
            color: "white",
            boxShadow: "2px 0 0 #131212, -2px 0 0 #131212",
        },
        "& a": {
            "&:hover": {
                textDecorationColor: "$grayscale0Fixed !important",
            },
        },
    },

    "&.style--with-padding": {
        backgroundColor: "$grayscale0",
        paddingBottom: "1px",
        [`${StyledWrap}`]: {
            margin: "16px",
            padding: 0,
            "@bp4": {
                height: "70px",
                overflow: "hidden"
            },
        },
    },
});

const EmbedElement = styled("span", {
    display: "block",
    position: "static",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,

    "& .part--youtube-video": {
        margin: 0,
        paddingTop: 0,
        paddingBottom: "54%",
        backgroundColor: "black",
    },

    "& .onnetwork-video": {
        margin: 0,
        paddingTop: 0,
        paddingBottom: "54%",
        backgroundColor: "black",
    },

    "@bp4": {
        position: "static",
        backgroundColor: "black",

        "& .part--youtube-video": {
            height: "200px",
        },
        "& .onnetwork-video": {
            height: "200px",
        }
    }

});

// textOnPhoto

const TextOnPhotoWrapper = styled("div", {
    marginBottom: "24px",
    position: "relative",
    "@bp4": {
        marginBottom: "32px",
    },

    "& .title": {
        fontSize: "18px",
        lineHeight: "24px",
        letterSpacing: "0.2px",
        fontWeight: "700",
        margin: "16px 0 0 0",
        "@bp4": {
            fontSize: "24px",
            lineHeight: "30px",
        },
    },

    "& .embed-wrap": {
        marginBottom: "16px",
    },

    "& .embed-isSuperOne": {
        pointerEvents: "none",
        "& .part--youtube-video": {
            paddingBottom: "56.25%",
        },
    }
});




export default UniversalEmbedBox;
