import React from 'react';
import { styled } from '../../../stitches.config';
import { observer } from 'mobx-react'
import HtmlParser from '../Content/HtmlParser'

import { useStores } from '../../../helpers/useStores'


const Paragraph = observer(({ paragraph }) => {
    const { text } = paragraph;
    const { ArticleStore } = useStores();

    if (ArticleStore.article.display_type === "BRID") {
        return (
            <BridWrapper>
                <HtmlParser data={text} />
            </BridWrapper>
        )
    }

    if (ArticleStore.article.display_type === "HARDCOVER") {
        return (<HardcoverWrapper>
            <HtmlParser data={text} />
        </HardcoverWrapper>)
    }

    return (
        <BizonWrapper>
            <HtmlParser data={text} disableNofollow={ArticleStore.article.disableNofollowExtra} />
        </BizonWrapper>
    )
})

const Wrapper = styled("div", {
    margin: "20px 38px",
    color: "#262626",
    fontSize: "17px",
    fontWeight: "lighter",
    letterSpacing: "-0.34px",
    lineHeight: "23px",
    a: {
        color: "rgb(255, 102, 0) !important",
        "&:hover": {
            textDecoration: "none"
        }
    },
    "@media (min-width: 992px)": {
        maxWidth: "660px",
        margin: "20px auto",
        color: "#222222",
        fontSize: "18px",
        fontWeight: 400,
        letterSpacing: "-0.3px",
        lineHeight: "28px"
    }
});

const BizonWrapper = styled("div", {
    margin: "20px 38px",
    color: "$grayscale100",
    fontSize: "16px",
    fontWeight: 400,
    letterSpacing: "0.1px",
    lineHeight: "26px",
    fontFamily: "$charis",
    "a": {
        color: "rgb(255, 102, 0) !important",
        "&:hover": {
            textDecoration: "none"
        }
    },
    "@bp4": {
        maxWidth: "840px",
        margin: "20px auto",
        fontSize: "21px",
        letterSpacing: "0px",
        lineHeight: "32px"
    }
});


const BridWrapper = styled("div", {
    margin: "0 auto",
    letterSpacing: "normal",
    "div": {
        fontFamily: "$grotesk",
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "24px",
        marginLeft: "0",
        marginRight: "0",
        padding: "10px 16px",
        "@bp4": {
            padding: "0px 48px"
        }
    },
    "a": {
        color: "$grayscale100Fixed !important",
        "&:hover": {
            textDecoration: "none"
        }
    }
});

const HardcoverWrapper = styled("div",{
    margin: "19px 35px",
    fontSize: "18px",
    fontFamily: "$charis",
    color: "#1e202e",
    maxWidth: "calc(100vw - 70px)",
    "@media (min-width: 992px)": { 
        margin: "19px 115px" }
});

export default Paragraph;