import React, { Component } from 'react'
import VizSensor from 'react-visibility-sensor'
import { pushDataLayer } from "../../helpers/pushDataLayer"

export default class LinkChanger extends Component {

    onChangeVisibility = (isVisible) => {
        const { url, data = {} } = this.props;
        /* 
            Będzie działało tylko na prodzie ze względu na https
            Nie zliczy datalayer jeżeli dany artykuł będzie najnowszym w danej kategorii, tzn pierwszy polecany będzie tym samym artykułem
        */
        if (isVisible && url && url !== window.location.href) {
            let el = document.createElement('a');
            el.href = url;
            window.history.replaceState(null, null, el.pathname);
            pushDataLayer(data);
        }
    }

    render() {
        return <VizSensor onChange={(isVisible) => this.onChangeVisibility(isVisible)} {...this.props}>
            {this.props.children}
        </VizSensor>
    }
}