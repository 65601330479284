import React, { useState, useEffect } from 'react';
import { styled } from "../../stitches.config"
import { h2, h4, bodyLarge } from "../commonStyles/Fonts";
import Adslot from "../../components/Adslot";
import AdslotWithScrollWrapper from "../../components/AdslotWithScrollWrapper";
import Link from "../../components/Link";
import Media from "../../components/common/Media"
import LinkChanger from "../../components/Article/LinkChanger"
import { withlazyload } from "../../components/HOCs/WithLazyLoad"

import ConditionalWrapper from "../../helpers/ConditionalWrapper";

import { LoadArticlesData } from "../../stores/ArticleStore"

type NextArticleProps = {
    id: string;
    adslotNum: number;
    articleData: LoadArticlesData;
    dataAnalyticsName?: string;
    hideAdslots?: boolean;
    changeLink?: boolean;
}

// type NextArticleState = {
//     slug: string;
//     url: string;
//     seoTitle: string;
//     categoryName: string;
//     topics: {
//         name: string;
//         slug: string;
//         url: string;
//     }[];
//     author: string;
//     charRange: string;
//     bodyPart: any;
// }

// type NextArticleStateData = {
//     slug: string;
//     url: string;
//     seoTitle: string;
//     title: string;
//     picture: {
//         alt: string;
//         img: string;
//     }
//     author: string;
//     lead: string;
// }

const NextArticle: React.FC<NextArticleProps> = ({
    articleData,
    adslotNum,
    dataAnalyticsName,
    hideAdslots = false,
    changeLink
}) => {
    const isDesktop = window.innerWidth > 996;
    const imgUrl = articleData?.picture?.img;

    return (
        <ConditionalWrapper
            condition={!!changeLink}
            wrapper={(children) => (
                <LinkChanger
                    partialVisibility={true}
                    minTopValue={500}
                    url={articleData!.url}
                    data={{
                        'event': 'virtualPageview',
                        'virtualPageURL': articleData.uri,
                        'virtualPageTitle': articleData.seo_title,
                        'pageType': 'Artykuł doładowany',
                        'articleSection': articleData.category.name,
                        // TYCH BRAKUJE W RESPONSE
                        // 'articleTag': articleData.topics.length > 0 ? articleData!.topics[0].name : "",
                        // 'articleAuthor': articleData!.data_layer.author,
                        // 'articleCharacterRange': articleData!.data_layer.charRange
                    }}>
                    {children}
                </LinkChanger>
            )}
        >
            <StyledNextArticle>
                {imgUrl &&
                    <Link to={articleData.url} data-analytics-name={dataAnalyticsName}>
                        {isDesktop ?
                            <Media
                                height={472}
                                media={articleData.picture}
                                src={imgUrl}
                                preserveRatio={false} />
                            :
                            <Media
                                height={218}
                                media={articleData.picture}
                                src={imgUrl}
                                preserveRatio={false} />
                        }
                    </Link>
                }
                <Link to={articleData.url} data-analytics-name={dataAnalyticsName}><p className={h2()}>{articleData.title}</p></Link>
                <Description className={h4()}>{articleData.lead}</Description>
                <ButtonWrapper>
                    <Link to={articleData.url} data-analytics-name={dataAnalyticsName}><span className={bodyLarge()}>Czytaj całość</span></Link>
                </ButtonWrapper>
                <br /><br />
                {!hideAdslots && <AdslotWithScrollWrapper name={`ART_Billboard_${adslotNum}`} />}
            </StyledNextArticle>
        </ConditionalWrapper>
    );
};

const StyledNextArticle = styled('div', {
    padding: "24px 0 29px 0",
    maxWidth: "840px",
    minHeight: "1000px",
    "@bp4": {
        minHeight: "850px",
        padding: "40px 0 13px 0",
        borderBottom: "1px solid $grayscale25",
    },

    "& img": {
        maxWidth: "100%",
        objectFit: "cover",
        objectPosition: "top",
        height: "218px",
        "@bp4": {
            height: "472px",
        },
    },
    "& a": {
        color: "$grayscale100",
    },

    "& p": {
        margin: 0,
        paddingTop: "16px",
        '@bp4': {
            paddingTop: "40px",
        }
    }
})


const Description = styled('div', {
    margin: "12px 0 43px 0",
    maxHeight: "192px",
    overflow: "hidden",
    "@bp4": {
        margin: "12px 0 51px 0",
        maxHeight: "124px",
    }
})

const ButtonWrapper = styled('div', {
    "& a": {
        marginTop: "32px",
        padding: "11px 20px 13px 20px",
        background: "$grayscale100",
        textDecoration: "none",
        "@bp4": {
            marginBottom: "40px",
        },
        "span": {
            color: "$grayscale0 !important",
        }
    },
})


export default withlazyload({ once: true, offset: 700 })(NextArticle)