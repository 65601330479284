import React from 'react';

import { usePodcastPlayer } from "../../../context/PodcastPlayerContext";

import PrevIcon from "../../../assets/img/podcastPlayer/podcast_prev_15.png";
import NextIcon from "../../../assets/img/podcastPlayer/podcast_next_15.png";
import { withThemeContext } from "../../../redesign/components/StitchesThemeProvider";

interface Props {
    duration: number;
    progress: number;
    videoId?: string;
    isDarkMode?: boolean;
};

const PodcastSkipTime: React.FC<Props> = ({ duration, progress, videoId, isDarkMode }) => {

    const { seek } = usePodcastPlayer();

    if (!videoId) return <></>;

    return (
        <div className={`podcastPlayerNav__skipTime ${isDarkMode ? 'podcastPlayerNav__skipTime--invert' : ''}`}>
            <button onClick={() => seek(Math.max((progress - 15), 0), videoId)}>
                <img src={PrevIcon} width="32" height="32" />
            </button>
            <button onClick={() => seek(Math.min((progress + 15), duration), videoId)}>
                <img src={NextIcon} width="32" height="32" />
            </button>
        </div>
    );

};

export default withThemeContext(PodcastSkipTime);

//const styled