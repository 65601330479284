import axios, { AxiosResponse } from "axios"

type ResetPasswordData = {
    email: string;
}

export async function resetPassword(userData: ResetPasswordData): Promise<AxiosResponse<ResetPasswordData>> {
    try {
        const res = await axios.post(`${window.API_HOST}/api/account/reset-password`, userData)
        return res.data
    } catch (err) {
        if (axios.isAxiosError(err)) {
            throw { status: err.response!.status, errors: err.response!.data.errors }
        } else {
            throw new Error('Cannot reset password.')
        }
    }
}