import axios, { AxiosResponse } from "axios"

type ItemType = number;
type ItemTypeArray = ItemType[];
type DictType<T extends number> = {
    [key in T]: {
        id: T;
        name: string;
        img?: string;
    }
}

type Author = {
    id: number;
    img: string;
    name: string;
    url: string,
    categoryName?: string,
    articlesCount?: number
}

export type Settings = {
    dark_mode: boolean;
    newsletter: boolean;
    followed_authors: ItemTypeArray;
    followed_categories: ItemTypeArray;
    followed_tags: ItemTypeArray;
    ignored_categories: string[];
}

export type UserData = {
    OID: number;
    valid: boolean;
    valid_to: string;
    access_token: string;
    email: string;
    username: string;
    settings: Settings;
    dictionary: {
        author: Author[];
        category: DictType<ItemType>;
        tag: DictType<ItemType>;
        section: {
            [key: string]: {
                name: string;
                key: string
            }
        };
    }
}

export async function me(): Promise<UserData> {
    try {
        const res: AxiosResponse<UserData> = await axios.post(`${window.API_HOST}/api/me`)
        return res.data
    } catch (err) {
        if (axios.isAxiosError(err)) {
            throw { status: err.response!.status, errors: err.response!.data.errors }
        } else {
            throw new Error('Cannot request info.')
        }
    }
}

export async function updateMe(data: UserData): Promise<UserData> {
    try {
        const res: AxiosResponse<UserData> = await axios.post(`${window.API_HOST}/api/me`, data)
        return res.data
    } catch (err) {
        if (axios.isAxiosError(err)) {
            throw { status: err.response!.status, errors: err.response!.data.errors }
        } else {
            throw new Error('Cannot request info.')
        }
    }
}