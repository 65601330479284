import React, {useEffect} from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useAuth } from "../context/AuthContext"

const PrivateRoute: React.FC<{element: React.ReactNode}> = ({element}) => {
    const [{ isAuthed }] = useAuth();

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(()=>{
        if(isAuthed===null) return;

        if(!isAuthed)
            navigate('/login',{
                state: {
                    from: location
                }
            })
    },[isAuthed]);

    if(isAuthed===null)
        return null;

    return <>{isAuthed && element}</>;
}

export default PrivateRoute