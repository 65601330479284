import React, { Suspense } from "react"
import FooterPlaceholder from "./FooterPlaceholder";

const FooterFull = React.lazy(() =>/* webpackChunkName: "Footer" */ import('./Footer'));

const Footer: React.FC = () => {

    return (
        <FooterPlaceholder className="footer-placeholder">
            <Suspense fallback={null}>
                <FooterFull />
            </Suspense>
        </FooterPlaceholder>
    );

};

export default Footer;