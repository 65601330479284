import React from 'react';
import type { BrandConfig } from '../config.types';

//BrandingLogos
import logo, { ReactComponent as LogoComponent } from "./assets/logo.svg";
import logoPlaceholder from "./assets/logo_placeholder.svg";
import logoSimple from "./assets/logo_simple.svg";

//ErrorPageImages
import errorPageImageDesktop from "./assets/errorPageDesktop.png";
import errorPageImageMobile from "./assets/errorPageMobile.png";

//Theme
const colors = {
    primary120: "#EF7E7B",
    primary: "#FB8781",
    primary60: "#FFE7E6",
};

const svgColorFilter: string = "brightness(0) saturate(100%) invert(76%) sepia(20%) saturate(4909%) hue-rotate(309deg) brightness(104%) contrast(97%);"

//Breadcrumbs/Footer title
const brandTitle = (
    <>
        KlubZwierzaki
    </>
);

//ErrorPageBranding
const errorPageText = (
    <>
        Nawet pies z kulawą nogą tu nie zagląda. Zajrzyj na stronę główną, tam na pewno znajdziesz jakieś smaczki.
    </>
);

//FooterDescription
const footerDescription = "Nawet pies z kulawą nogą tu nie zagląda. Zajrzyj na stronę główną, tam na pewno znajdziesz jakieś smaczki";

//Export config
const BrandConifg: BrandConfig = {
    LogoComponent,
    logo, logoPlaceholder, logoSimple,
    colors, svgColorFilter,
    brandTitle,
    errorPageText, errorPageImageDesktop, errorPageImageMobile,
    footerDescription
};

export default BrandConifg;