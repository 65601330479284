import detectRobots from "./detectRobots";
import isLocalStorageSupported from "./isLocalStorageSupported";

const isRealMobileDevice = () => {
    //Ignore - robots | browser withour session storage | exitScreen empty | is IOS
    const isIOS = /iPhone|iPod|iPad/.test(navigator.platform);
    //@ts-ignore
    const isAndroid = /android/i.test(navigator.userAgent) && !!window.chrome;
    const isFbAppBrowser = navigator.userAgent.match(/FBAN|FBAV/i);
    const isRobot = detectRobots(navigator.userAgent);
    if (isRobot || !isLocalStorageSupported())
        return false;

    if (!isIOS && !isAndroid && !isFbAppBrowser)
        return false;

    return true;
};

export default isRealMobileDevice;