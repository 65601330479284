import React, { useState, useEffect, useCallback } from "react";
import useModal from "../../helpers/useModal";
import { styled } from "../../stitches.config";
import MODAL_CONFIG from "./config";

const Modal: React.FC = () => {
  const { modalType, toggleModal } = useModal();

  const ModalContent = useCallback(
    () => modalType && React.createElement(MODAL_CONFIG[modalType].component),
    [modalType]
  );

  if (!modalType) return null;

  return (
    <Mask>
      <Overlay>
        <CloseButton onClick={toggleModal}>
          <svg viewBox="0 0 20 21" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M11.7525 10.4142L19.5455 2.62119L17.707 0.782715L9.91401 8.57568L2.12119 0.782868L0.282715 2.62135L8.07553 10.4142L0.282715 18.207L2.12119 20.0455L9.91401 12.2526L17.707 20.0456L19.5455 18.2071L11.7525 10.4142ZM9.91401 8.99995L17.707 1.20698L17.707 1.20703L9.91406 9L9.91401 8.99995ZM2.12119 1.20713L2.12124 1.20718L0.707031 2.6214L0.706979 2.62135L2.12119 1.20713ZM8.4998 10.4142L0.706979 18.207L0.707031 18.207L8.49985 10.4142L8.4998 10.4142Z"
            />
          </svg>
        </CloseButton>
        <ModalContent />
      </Overlay>
    </Mask>
  );
};

const Mask = styled("div", {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100vw",
  height: "100vh",
  backgroundColor: "rgba(0, 0, 0, 0.3)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 1000000,
  backdropFilter: "blur(4px)",
});

const Overlay = styled("div", {
  background: "$grayscale100",
  position: "relative",
  color: "$grayscale0",
  height: "100%",
  width: "100%",
  "@bp4": {
    height: "initial",
    width: "initial",
  },
});

const CloseButton = styled("button", {
  position: "absolute",
  top: "28px",
  right: "28px",
  width: "20px",
  height: "20px",
  border: "none",
  background: "transparent",
  cursor: "pointer",
  svg: {
    fill: "$grayscale0Fixed",
    width: "16px",
    height: "16px",
  },
});

export default Modal;
