import { PaymentParams, PaymentStatus, PaymentType } from "../types/Payment";
import { PaymentModal } from './ModalComponents';


type PaymentFields = {
  type: ModalType.PAYMENT;
  paymentType: PaymentType;
  paymentStatus: PaymentStatus;
  paymentEditorId?: string;
  paymentBridId?: number;
  paymentValue?: string;
  paymentArticleId?: string;
  paymentOrigin?: string;
};

export enum ModalType {
  PAYMENT = "PAYMENT",
}
export type Params = PaymentParams;
export type ModalFields = PaymentFields;

type Config = {
  [key in keyof typeof ModalType]: {
    modalType: ModalType;
    params: Params[];
    component: () => JSX.Element;
    requireParams: {
      [key in keyof typeof PaymentType]?: PaymentParams[];
    };
  };
};

const MODAL_CONFIG: Config = {
  [ModalType.PAYMENT]: {
    modalType: ModalType.PAYMENT,
    params: Object.values(PaymentParams),
    component: PaymentModal,
    requireParams: {
      TIP: [
        PaymentParams.PAYMENT_EDITOR_ID,
        PaymentParams.PAYMENT_ARTICLE_ID,
        PaymentParams.PAYMENT_STATUS,
        PaymentParams.PAYMENT_TYPE,
        PaymentParams.PAYMENT_ORIGIN
      ],
      BRID: [
        PaymentParams.PAYMENT_BRID_ID,
        PaymentParams.PAYMENT_STATUS,
        PaymentParams.PAYMENT_TYPE,
        PaymentParams.PAYMENT_VALUE,
      ],
    },
  },
};

export default MODAL_CONFIG;
