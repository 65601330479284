export const injectTestAdKeywords = (keywords: any) => {
    const searchQuery = window.location.search;
    const urlParams = new URLSearchParams(searchQuery);

    const topics = urlParams.get('adTest');

    if (topics) {
        keywords.adTest = keywords.adTest ?? [];
        keywords.adTest = [
            ...topics.split(',')
        ];
    }

    return keywords;
};