import React from "react";
import { styled } from "../../../../stitches.config";

import NoSSR from "../../../../helpers/NoSSR";

const NavbarBottomFull = React.lazy(() => import("./NavbarBottom"));

const NavbarBottom: React.FC = () => {

    return (
        <FixedContainer>
            <NoSSR fallback={<NavbarBottomPlaceholder />}>
                <NavbarBottomFull />
            </NoSSR>
        </FixedContainer>
    );

};

const FixedContainer = styled("div", {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 999998,
    '@bp4': {
        display: "none"
    }
});

const NavbarBottomPlaceholder = styled("div", {
    borderTop: "1px solid $grayscale25",
    height: "72px",
    width: "100%",
    background: "$grayscale0"
});

export default NavbarBottom;