import React from "react";
import { hydrateRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import openIDB from "./IDB";

import * as serviceWorker from './serviceWorker';

import AppEntry from "./appEntry";

import './redesign/ExitScreen';

const rootElement = document.getElementById("root") as Element;
if (rootElement) {
    hydrateRoot(rootElement, (
        <BrowserRouter>
            <AppEntry />
        </BrowserRouter>
    ));
};

openIDB();
serviceWorker.register();