import React from "react";
import { inject, observer } from 'mobx-react'

import CommentIcon from "../../../assets/img/comment_gray.svg";
import FacebookIcon from "../../../assets/img/facebook_blue.svg";

import AuthorDateBizon from "../../../redesign/components/AuthorDate"

const AuthorDate = inject("UIStore", "ArticleStore")(
  observer(({ article }) => {

    var stats = [];
    if (article.stats && article.stats.shares) {
      stats.push(<span><img src={FacebookIcon} alt="" /> {article.stats.shares}</span>);
    }
    if (article.stats && article.stats.comments) {
      stats.push(<span><img src={CommentIcon} alt="" /> {article.stats.comments}</span>);
    }
    return (
      <AuthorDateBizon article={article} />
    );
  }))

export default AuthorDate;
