import * as React from "react";
import { useLocation } from "react-router-dom";
import {
  theme as defaultTheme,
  darkTheme,
  globalStyles,
} from "../../stitches.config";

const { createContext, useState, useEffect } = React;

interface IColourModeContext {
  colorMode: string,
  cycleToggleMode: (() => void) | null,
  isDarkMode: boolean,
  isForceDarkMode: boolean | null,
  setForceDarkMode: React.Dispatch<React.SetStateAction<boolean | null>>
};

export const ColourModeContext = createContext<IColourModeContext>({} as IColourModeContext);

type ColourMode = string;
type MediaTheme = string;
type StitchesThemeProviderType = [
  ColourMode,
  () => void,
  (newMode: ColourMode) => void
];

type AvailableThemes = {
  [x: string]: typeof defaultTheme | typeof darkTheme;
};
const available_themes: AvailableThemes = {
  //@ts-ignore
  light: defaultTheme.className,
  //@ts-ignore
  dark: darkTheme.className,
};

const saveColorMode = (newMode: ColourMode) => {
  try {
    if (typeof newMode === "string")
      window.localStorage.setItem("color-mode", newMode);
  } catch (e) {
    console.warn(e);
  }
};

const getSavedColorModePreference = (): ColourMode => {
  try {
    const savedMode = window.localStorage.getItem("color-mode");
    if (typeof savedMode === "string") return savedMode;
  } catch (e) {
    console.warn(e);
    //@ts-ignore
    return null;
  }
  //@ts-ignore
  return null;
};

//@ts-ignore
const getMediaTheme = (): MediaTheme => {
  const mql = matchMedia("(prefers-color-scheme: dark)");
  const hasMediaQueryPreference = typeof mql.matches === "boolean";
  if (hasMediaQueryPreference) return mql.matches ? "dark" : "light";
};

export const useColorMode = (
  isForceDarkMode: boolean | null
): StitchesThemeProviderType => {
  const [colorMode, setColorMode] = useState("");
  const html = document.documentElement;

  const applyColorMode = (newMode: ColourMode) => {
    html.classList.remove(available_themes[colorMode]);
    html.classList.add(available_themes[newMode]);
    setColorMode(newMode);
  };

  let savedColorMode =
    isForceDarkMode !== false ? getSavedColorModePreference() : "";
  if (savedColorMode == null) {
    savedColorMode = getMediaTheme();
  }
  html.classList.add(available_themes[savedColorMode]);

  useEffect(() => {
    setColorMode(savedColorMode);
  }, [savedColorMode]);

  // fallback na stare przeglądarki które mają addListener, a nie addEventListener
  if (window.matchMedia("(prefers-color-scheme: dark)")?.addEventListener) {
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", (e) => {
        applyColorMode(e.matches ? "dark" : "light");
      });
  } else {
    window.matchMedia("(prefers-color-scheme: dark)").addListener((e) => {
      applyColorMode(e.matches ? "dark" : "light");
    });
  }

  if (window.matchMedia("(prefers-color-scheme: light)")?.addEventListener) {
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", (e) => {
        applyColorMode(e.matches ? "light" : "dark");
      });
  } else {
    window.matchMedia("(prefers-color-scheme: dark)").addListener((e) => {
      applyColorMode(e.matches ? "light" : "dark");
    });
  }

  const cycleToggleMode = (): void => {
    const keys = Object.keys(available_themes);
    let index = keys.indexOf(colorMode);
    if (index === keys.length - 1) {
      index = 0;
    } else if (index >= 0) {
      index = index + 1;
    }
    const newMode = keys[index];
    applyColorMode(newMode);
    saveColorMode(newMode);
  };

  return [colorMode, cycleToggleMode, applyColorMode];
};

type StitchesThemeProviderProps = {
  children: any;
};

const StitchesThemeProvider = ({ children }: StitchesThemeProviderProps) => {
  const { pathname } = useLocation();
  const [isForceDarkMode, setForceDarkMode] = useState<boolean | null>(null);
  const [colorMode, cycleToggleMode, applyColorMode] =
    useColorMode(isForceDarkMode);
  const [isDarkMode, setDarkMode] = useState(false);

  /**
   * można wymusić darkmode, lub go wyłączyć na danej funkcjonalności
   * - null - powoduje, że brane jest ustawienie z przełącznika
   * - false - wyłączony darkmode (np. atavisty)
   * - true - wymuszony darkmode (np. artykuł video)
   */

  useEffect(() => {
    colorMode === "dark" ? setDarkMode(true) : setDarkMode(false);
  }, [colorMode]);

  useEffect(() => {
    if (isForceDarkMode) {
      applyColorMode("dark");
    }
    if (isForceDarkMode === false) {
      applyColorMode("false");
    }
  }, [isForceDarkMode, applyColorMode]);

  useEffect(() => {
    const storageTheme = localStorage.getItem("color-mode");
    if (storageTheme) {
      applyColorMode(storageTheme);
    }
    setForceDarkMode(null);
  }, [pathname]);

  const getColorMode = () => {
    if (isForceDarkMode) {
      return "dark";
    }
    if (isForceDarkMode === false) {
      return "light";
    }
    return colorMode;
  };

  return (
    <ColourModeContext.Provider
      //@ts-ignore
      value={{
        colorMode: getColorMode(),
        cycleToggleMode: isForceDarkMode ? null : cycleToggleMode,
        isDarkMode: isForceDarkMode !== null ? isForceDarkMode : isDarkMode,
        isForceDarkMode,
        setForceDarkMode,
      }}
    >
      {children}
    </ColourModeContext.Provider>
  );
};

export const withThemeContext = (Component: React.FC<any>) => (props: any) =>
(
  <ColourModeContext.Consumer>
    {(state) => <Component {...props} {...state} />}
  </ColourModeContext.Consumer>
);

export const useThemeContext = () => {
  return React.useContext(ColourModeContext);
}

const ThemeWrapperComponent: React.FC<React.PropsWithChildren<{ colorMode?: 'light' | 'dark', className?: string }>> = ({ children, className, colorMode = 'light' }) => {

  return (
    <div className={`${available_themes[colorMode]} ${className ? className : ''}`}>
      {children}
    </div>
  )

};
ThemeWrapperComponent.toString = () => '.theme-wrapper';

export const ThemeWrapper = ThemeWrapperComponent;

export default StitchesThemeProvider;
