import React from "react";
// import { inject, observer } from "mobx-react";
import { styled } from "../stitches.config";

import { withConsent } from "./HOCs/withConsent"
import { AuthContext } from "../context/AuthContext";

class OnnetworkPlayer extends React.Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    this.playerComponent = React.createRef(null);
  }

  /**
   * nigdy nie aktualizuj, wyświetlaj ten sam player
   */
  shouldComponentUpdate(nextProps, nextState) {
    return false;
  }

  componentDidMount() {
    var that = this;

    const authContext = this.context;
    const user = ((Array.isArray(authContext) && authContext[0]) ? authContext[0]?.user : undefined);
    const adBlockingParameter = (user && user.valid) ? "&abm=1" : "&abm=0";

    const player = this.playerComponent.current;

    // timeout, żeby to się wszystko wyrenderowało
    // setTimeout(function () {
    if (player && player.getAttribute('data-onnetwork-mid')) {
      var videoSrc = 'https://video.onnetwork.tv/embed.php?sid=' + player.getAttribute('data-onnetwork-mid') + `${adBlockingParameter}`;

      const script = document.createElement("script");
      script.src = videoSrc;
      script.async = true;

      player.innerHTML = '';
      if (!window.TESTING_MODE) {
        player.appendChild(script);
      }
    }
    // }, 200);
  }

  render() {
    const mid = (this.props.mid && this.props.mid.id ? this.props.mid.id : this.props.mid);

    if (!mid) {
      return null;
    } else if (mid.length > 20) {
      // kod iframe
      return (<div className="onnetwork-video">
        <iframe className="exsminframe" src={"https://video.onnetwork.tv/frame_nat.php?mid=" + mid} frameBorder="0" scrolling="no" allowFullScreen> </iframe>
      </div>);
    } else {
      // kod script
      return (<PlayerContainer
        data-onnetwork-mid={mid}
        ref={this.playerComponent}
      />);
    }
  }
}

const PlayerContainer = styled("div", {
  margin: "18px 0",
  minHeight: "196px",
  "@bp4": {
    minHeight: "472px"
  },
  // Support #7577 - player pływajacy przykryty tapetą screeningu na Safari potrzebuje mniejszego zIndexu 
  ".miniature": {
    zIndex: "100 !important",
  }
});

// export default OnnetworkPlayer
export default withConsent(OnnetworkPlayer)