import React from "react";

import { useStores } from "../../../../helpers/useStores";
import { styled } from "../../../../stitches.config";
import Parts from "../Parts";
import AnimateHeightWrapper from "./common/components/AnimateHeightWrapper";
import * as S from "./common/styles/Styles";
import { h3 } from "../../../../redesign/commonStyles/Fonts";

interface IData {
  about: any;
}

const BridAbout: React.FC<{ blockData: IData }> = ({ blockData }) => {
  // const { about }: IData = blockData;
  const { ArticleStore } = useStores();
  return (
    <Wrap>
      <h3 className={h3()}>O projekcie</h3>
      <AnimateHeightWrapper height={300} visibility={true}>
        <Parts parts={blockData} article={ArticleStore.article} />
      </AnimateHeightWrapper>
    </Wrap>
  );
};

const Wrap = styled("div", {
  margin: "16px auto",
  fontFamily: "$grotesk",
  background: "$grayscale0",
  padding: "8px 16px 40px 16px",
  "@bp4": {
    padding: "24px 48px 56px 48px",
  },
  h3: {
    color: "$grayscale100",
    marginBottom: "24px",
    "@bp4": {
      marginBottom: "32px",
    }
  },
  p: {
    margin: 0,
    fontWeight: 300,
    color: "$grayscale75",
    fontSize: 17,
    "@bp4": {
      fontSize: 18,
      color: "$grayscale25",
    }
  },
  div: {
    paddingLeft: "0px !important",
    paddingRight: "0px !important",
  }
});

export default BridAbout;
