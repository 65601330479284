import React from 'react';
import { styled } from '../../../stitches.config';
import Html from './Html';
import Parts from './Parts';
import { useThemeContext } from '../../../redesign/components/StitchesThemeProvider';

const Block = ({ settings, article, hideAdslots }) => {
    const { parts, position, style } = settings;

    const { isDarkMode } =useThemeContext();

    return (
        <Wrapper className={position + " " + style} isDarkTheme={isDarkMode}>
            <Parts parts={parts} article={article} hideAdslots={hideAdslots} />
        </Wrapper>
    );
}

const Wrapper = styled("div", {
    marginTop: "12px",
    marginBottom: "12px",
    "&.pos_indent": {
        paddingLeft: "10px",
        paddingRight: "10px",
        "@media (min-width: 992px)": {
            paddingLeft: "20px"
        }
    },
    "&.style_gray": {
        background: "#E9E9E9 !important"
    },
    "&.ls_gray": {
        /* border-left: 4px solid #DBDBDB; 
        wyłączenie globalne szarego borderu na zlecenie Michała Mańkowskiego 20.05.2020 - informacja w docs:
        https://docs.google.com/document/d/1GYqcH3LV12jGhsWoCNh3MxGgLuVLBi1gJWWxbsfyoaM/edit?ts=5d5bb3af#
        */
    },
    "&.style_nomargins": {
        paddingLeft: "0",
        paddingRight: "0"
    },
    "p": {
        margin: "0"
    },
    variants: {
        "isDarkTheme":{
            true: {
                "&.style_gray":{
                    background: "#1B1B1B !important"
                }
            },
            false: {}
        }
    }
});

export default Block;