import React, { useCallback } from 'react';
import { styled } from "../../../stitches.config";
import { withThemeContext } from "../../components/StitchesThemeProvider";
import Logo from "../../atoms/Logo";

import { ErrorPageConfig } from "../../../config";

interface IErrorPage {
    brand?: Config.Brand;
    isDarkMode?: boolean;
    description?: React.ReactNode;
}

const ErrorPage: React.FC<IErrorPage> = ({ brand = window.BRAND, isDarkMode, description }) => {

    const errorDescription = useCallback(() => {
        if (description)
            return description;

        return ErrorPageConfig.description[brand];
    }, [brand, description]);

    return (
        <>
            <Box brand={brand}>
                <Description>
                    {errorDescription()}
                </Description>
                <ImageMobile
                    brand={brand}
                />
                <LogoWrapper>
                    <Logo type={isDarkMode ? "dark" : "white"} brand={brand} />
                </LogoWrapper>
            </Box>
        </>
    );

};

export default withThemeContext(ErrorPage);

const Box = styled("div", {
    width: "100%",
    maxWidth: "1272px",
    margin: "0 auto",
    color: "$grayscale100",
    marginBottom: "32px",
    position: "relative",
    marginTop: "40px",
    "@bp4": {
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center right",
        height: "500px",
        marginBottom: "48px",
    },
    variants: {
        brand: ErrorPageConfig.backgroundDesktopVariants
    }
});

const ImageMobile = styled("div", {
    width: "100%",
    maxWidth: "400px",
    height: "270px",
    margin: "0 auto 60px auto",
    backgroundPosition: "center",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    "@bp4": {
        display: "none"
    },
    variants: {
        brand: ErrorPageConfig.backgroundMobileVariants
    }
});

const LogoWrapper = styled("div", {
    margin: "0 auto",
    width: "150px",
    height: "30px",
    "@bp4": {
        position: "absolute",
        left: 0,
        bottom: 0,
    }
});

const Description = styled("div", {
    maxWidth: "400px",
    width: "100%",
    padding: "0 24px",
    color: "$grayscale100",
    margin: "0 auto 32px auto",
    textAlign: "left",
    fontSize: "18px",
    lineHeight: "24px",
    "@bp4": {
        margin: "0 0 32px 0",
        maxWidth: "520px",
        padding: "0",
        fontSize: "28px",
        lineHeight: "32px",
    }
});