// import idb from "idb";

/*eslint-disable */
export default function openIDB() {
  // browser does not support idb
  if (true || !IDBIndex) {
	  var fallback = {
		  then: function() {
			  return fallback;
		  },
	  	  catch: function() {
	  		  return fallback;
	  	  }
	  };
	  return fallback;
  }
	
  return idb.open("natematPWA", 2, function(upgradeDB) {
    switch (upgradeDB.oldVersion) {
      case 0:
        upgradeDB.createObjectStore("articles");
        upgradeDB.createObjectStore("categories");
      case 1:
        upgradeDB.createObjectStore("readLaterArticles");
        const readLaterStore = upgradeDB.transaction.objectStore(
          "readLaterArticles"
        );
        readLaterStore.createIndex("articles_by_date", "timestamp");
    }
  });
}

/*eslint-enable */
