import React, { Fragment } from "react";
import parse from "html-react-parser"
import { observer } from "mobx-react"

import { useStores } from "./useStores"

const parserOptions = {
    replace: ({ name, attribs = {}, children }) => {
        if (name === "script") {
            if (attribs.src && attribs.src?.length > 1) {
                setTimeout(() => {
                    const s = document.createElement("script");
                    s.src = attribs.src;
                    s.type = "text/javascript";
                    s.async = true;
                    document.getElementsByTagName("body")[0].appendChild(s);
                }, 300);
                return <Fragment />;
            } else {
                const script = (children[0] ? children[0].data : null);
                setTimeout(() => window.eval(script), 1000);
                return <Fragment />;
            }
        }
    }
}

const TrackingCodeParser = observer(({ trackingCode }) => {
    const { AdsStore } = useStores();

    if (AdsStore.tcDataLoaded)
        return <>
            {trackingCode ? parse(trackingCode, parserOptions) : null}
        </>

    return null;
})

export default TrackingCodeParser