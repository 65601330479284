import React from "react";
import { styled } from "../../../../stitches.config";
import { dLabel, h4Strong } from "../../../../redesign/commonStyles/Fonts";

import WhatIsBrid from "./WhatIsBrid"

interface IData {
  date: string;
  budget: number;
  partnership?: string;
  partnershipLogoUrl?: string;
  title?: string;
  description?: string;
  text?: string;
}

const BridInfo: React.FC<{ blockData: IData }> = ({ blockData }) => {
  const { date, budget }: IData = blockData;
  const url: string | null = blockData.partnershipLogoUrl
    ? blockData.partnershipLogoUrl.replace("0,0,0,0", "0,28,0,0")
    : null;
  return (
    <>
      <Wrap>
        <Tile>
          <span className={dLabel()}>Planowana data realizacji</span>
          <p className={h4Strong()}>{date}</p>
        </Tile>
        <Tile>
          <span className={dLabel()}>Budżet projektu</span>
          <p className={h4Strong()}>{budget}</p>
        </Tile>
        {(!!blockData.partnership || !!url) && (
          <Tile>
            <span className={dLabel()}>Partner Artykułu</span>
            <PartnerWrap>
              {!!url ? (
                <img src={url} alt="logo" />
              ) : (
                <p className={h4Strong()}>{blockData.partnership}</p>
              )}
            </PartnerWrap>
          </Tile>
        )}
      </Wrap>
      <WhatIsBrid blockData={blockData}/>
    </>
  );
};

const Wrap = styled("div", {
  margin: "0 16px 40px 16px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  "@bp2": {
    margin: "0 auto 40px auto",
  },
  "@bp4": {
    flexDirection: "row",
    margin: "0 auto",
  },
});


const PartnerWrap = styled("div", {
  display: "flex",
  flexDirection: "row",
  margin: "0",
  alignItems: "flex-start",
  p: {
    width: "auto !important",
  },
  img: {
    maxHeight: "23px",
    marginTop: "8px",
    "@bp4": {
      margin: "8px auto 0 auto",
    }
  }
});

const Tile = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  padding: "24px 0 0 0",
  "@bp4": {
    padding: "25px 0",
    marginRight: "64px",
  },
  span: {
    display: "inline-block",
    color: "$primary",
    width: "auto",
    textTransform: "uppercase",
  },
  p: {
    margin: "8px 0 0 0",
    color: "$grayscale100",
  },
});

export default BridInfo;
