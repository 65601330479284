import React, { useState } from 'react';
import { styled } from "../stitches.config"


interface ToggleSwitchProps {
    active: boolean,
    color?: "white" | "dark" | "orange";
    size?: "small" | "big";
    onToggle?: () => void;
    dataAnalyticsName?: string;
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
    active,
    color = "orange",
    size = "big",
    dataAnalyticsName,
    onToggle,
    ...props
}) => {
    const toggleClass = () => {
        onToggle && onToggle()
    };
    return (
        <StyledToggleSwitch size={size} color={color} onClick={toggleClass} data-analytics-name={dataAnalyticsName ? dataAnalyticsName : null}>
            <div className={active ? "active" : ""}>
                <span className="slider"></span>
            </div>
        </StyledToggleSwitch>
    );
};


const StyledToggleSwitch = styled('button', {
    position: "relative",
    cursor: "pointer",
    display: "inline-block",
    boxSizing: "border-box",
    background: "none",
    borderRadius: "24px",
    pointerEvents: "auto",
    flexShrink: 0,

    variants: {
        color: {
            white: {
                "& .slider": {
                    backgroundColor: "$grayscale0Fixed",
                    "&:before": {
                        backgroundColor: "$primary",
                    }
                },
            },
            orange: {
            },
            dark: {
            },
        },
        size: {
            small: {
                width: "24px",
                height: "16px",
                border: "1px solid $grayscale50",
                "& .active": {
                    "& .slider": {
                        width: "20px",
                        "&:before": {
                            transform: "translateX(9px)",
                            height: "10px",
                            width: "10px",
                            left: "0px",
                            bottom: "1px",
                        },
                        "@supports (-webkit-touch-callout: none)": {
                            width: '22px',
                            "&:before": {
                                transform: "translateX(11px)",
                            },
                        },
                    }
                },
                "& .slider": {
                    top: "1px",
                    left: "1px",
                    height: "12px",
                    width: "12px",
                    "&:before": {
                        height: "4px",
                        width: "4px",
                        left: "4px",
                        bottom: "4px",
                    }
                },
            },
            big: {
                width: "36px",
                height: "24px",
                border: "2px solid $grayscale25",
                padding: 0,
                "& .active": {
                    "& .slider": {
                        width: "28px",
                        "&:before": {
                            left: "0px",
                            height: "12px",
                            width: "12px",
                            bottom: "2px",
                            transform: "translateX(13px)",
                        }
                    }
                },
                "& .slider": {
                    width: "16px",
                    height: "16px",
                    top: "2px",
                    left: "2px",
                    "&:before": {
                        height: "6px",
                        width: "6px",
                        left: "5px",
                        bottom: "5px",
                    }
                },
            }
        }
    },

    "& .slider": {
        position: "absolute",
        cursor: "pointer",
        right: 0,
        bottom: 0,
        backgroundColor: "$primary",
        transition: "0.4s",
        borderRadius: "34px",
        "&:before": {
            position: "absolute",
            content: "",
            backgroundColor: "$grayscale0",
            transition: "0.4s",
            borderRadius: "50%",
        }
    },
})


export default ToggleSwitch