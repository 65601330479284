import * as React from "react";
import { useEffect, useReducer } from "react";
import { me, updateMe, UserData } from "../api/account";

type AuthProviderProps = { children: React.ReactNode };
type AuthAction =
  | { type: "login" }
  | { type: "logout" }
  | { type: "register" }
  | { type: "set_user"; payload: UserData }
  | { type: "update_user"; payload: UserData }
  | { type: "remove_user" }
  | { type: "set_error"; payload: { status: number; errors: any } };
type AuthState = {
  isAuthed: boolean | null;
  hasError: boolean;
  user: UserData | null;
  isUpdated: boolean;
  errorStatus: {
    status: number;
    errors: Record<string, string>;
  } | null;
};

const AuthContext =
  React.createContext<[AuthState, React.Dispatch<AuthAction>] | undefined>(
    undefined
  );

const authReducer = (state: AuthState, action: AuthAction): AuthState => {
  switch (action.type) {
    case "login":
      return {
        ...state,
        errorStatus: null,
        isAuthed: true,
      };
    case "logout":
      return {
        ...state,
        errorStatus: null,
        isAuthed: false,
        user: null
      };
    case "register":
      return {
        ...state,
        errorStatus: null,
        isAuthed: true,
      };
    case "set_user": {
      return {
        ...state,
        isAuthed: true,
        user: action.payload,
        isUpdated: false,
      };
    }
    case "update_user": {
      return {
        ...state,
        isAuthed: true,
        user: action.payload,
        isUpdated: true,
      };
    }
    case "remove_user": {
      return {
        ...state,
        isAuthed: false,
        user: null,
      };
    }
    case "set_error":
      return {
        ...state,
        hasError: true,
        errorStatus: action.payload,
      };
    default:
      throw new Error("Unhandled action");
  }
};

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [state, dispatch] = useReducer(authReducer, {
    isAuthed: null,
    hasError: false,
    errorStatus: null,
    user: null,
    isUpdated: false,
  });

  const reloadMe = () => {
    // console.log({ state })
    me()
      .then((res) => {
        // console.log(res);
        localStorage.setItem("access_token", res.access_token);
        dispatch({ type: "set_user", payload: res });
      })
      .catch((err) => console.log(err));
  };

  // wejściowy status
  useEffect(() => {
    const storageToken = localStorage.getItem('access_token');
    const smAccessToken = (window?.APP_SETTINGS?.social_media_access_token);
    const token = storageToken || smAccessToken;

    if (token) {
      //   const parsedUser = JSON.parse(token) as UserData;
      //   dispatch({ type: "set_user", payload: parsedUser });
      reloadMe();
    } else {
      dispatch({ type: "remove_user" });
    }
  }, []);

  // po zalogowaniu sie
  useEffect(() => {
    if (state.isAuthed && !state.user) {
      reloadMe();
    }
  }, [state.isAuthed]);

  useEffect(() => {
    if (state.isUpdated && state.user) {
      updateMe(state.user).then(() => reloadMe());
    }
  }, [state.isUpdated, state.user]);

  return (
    <AuthContext.Provider value={[state, dispatch]}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = React.useContext(AuthContext);

  if (context === undefined) {
    throw new Error("useAuth must be used within AuthContext");
  }
  return context;
};

export { AuthProvider as default, useAuth, AuthContext };
