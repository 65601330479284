import React from 'react';
import type { BrandConfig } from '../config.types';

//BrandingLogos
import logo, { ReactComponent as LogoComponent } from "./assets/logo.svg";
import logoPlaceholder from "./assets/logo_placeholder.svg";
import logoSimple from "./assets/logo_simple.svg";

//ErrorPageImages
import errorPageImageDesktop from "./assets/errorPageDesktop.png";
import errorPageImageMobile from "./assets/errorPageMobile.png";

//Theme
const colors = {
    primary120: "#B82D30",
    primary: "#EA3A3E",
    primary60: "#FBD8D8",
};

const svgColorFilter: string = "invert(33%) sepia(71%) saturate(5483%) hue-rotate(342deg) brightness(98%) contrast(87%);"

//SocialMedia links
const socialMediaLinks = {
    facebook: 'https://www.facebook.com/ASZdziennik',
    instagram: 'https://www.instagram.com/aszdziennik.pl/',
    tiktok: 'https://www.tiktok.com/@aszdziennik',
    youtube: 'https://www.youtube.com/@asz.dziennik'
};

//Breadcrumbs/Footer title
const brandTitle = (
    <>
        ASZ<span>:</span>dziennik
    </>
);

//ErrorPageBranding
const errorPageText = (
    <>
        Asz tak daleko w okolicę końca internetu nie zabrnęliśmy.Ale w aszdziennik przeczytasz wiele interesujących rzeczy.
    </>
);

//FooterDescription
const footerDescription = "Najlepsze newsy w Polsce. Opiniotwórczy serwis satyryczny.";

//PushPushGo worker
const pushPushWorkerUrl = "https://s-eu-1.pushpushgo.com/66952b77dcfe8d618f9131d1/worker.js";

//Export config
const BrandConifg: BrandConfig = {
    LogoComponent,
    logo, logoPlaceholder, logoSimple,
    colors, svgColorFilter,
    brandTitle,
    socialMediaLinks,
    errorPageText, errorPageImageDesktop, errorPageImageMobile,
    footerDescription,
    pushPushWorkerUrl
};

export default BrandConifg;