import React, { useEffect } from "react"
import { styled } from "../../../stitches.config";
import PhotoOnTop from "../../common/PhotoOnTop"
import TextOnPicture from "../../../redesign/components/TextOnPicture";
import { IS_ARTICLE_PV_FROM_RELATED_ARTICLE } from "../../common/constants/constants";
import { pushDataLayer } from "../../../helpers"


const RelatedArticle = ({ article }) => {

    const dataLayerObject = {
        'event': 'virtualPageview',
        'virtualPageURL': article.uri,
        'virtualPageTitle': article.seo_title,
        'pageType': 'Powiązany artykuł',
    }

    useEffect(() => {
        IS_ARTICLE_PV_FROM_RELATED_ARTICLE ?
            pushDataLayer(dataLayerObject) : null
    }, []);

    return <>
        <Title>Czytaj także<span>:</span></Title>
        <TextOnPicture article={article} />
    </>
}

const Title = styled("b", {
    "span": {
        color: "$primary"
    }
});

export default RelatedArticle