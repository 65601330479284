import React, { useEffect, useRef, useState } from "react";
import { styled } from "../../stitches.config";

interface IProps {
    parentHeight?: number | string;
    children: any;
}

const ScrollWrapper: React.FC<IProps> = ({
    children,
    parentHeight,
    ...props
}) => {

    const [adHeight, setAdHeight] = useState<number>(0);

    const insideWrap = useRef<HTMLSpanElement>(null);

    useEffect(() => {

        const heightInsideWrap = insideWrap.current?.offsetHeight ?? 100000;
        setAdHeight(heightInsideWrap)

        const maxScrollingHeight = 320;
        // 320 specjalnie pod reklamy - można zrobić jako props

        /*if (heightInsideWrap < maxScrollingHeight) {
            insideWrap.current?.classList.add('scrollable-wrap');
        }*/

        //console.log("wysokość wrapu: ", heightInsideWrap,)

    }, []);

    //console.log("wysokość przekazywana:", parentHeight, "state:", adHeight)

    return <Container className="scrollable-container" style={{ minHeight: parentHeight + "px" }}>
        <InsideWrap className="scrollable-wrap" ref={insideWrap}>
            {children}
        </InsideWrap>
    </Container>
}

const Container = styled("span", {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
    width: "100%",
    position: "relative",
    '&.scrollable-container': {
        display: "block !important",
        width: "fit-content !important",
    },
});

const InsideWrap = styled("span", {
    display: "block",
    margin: "0 auto",
    '&.scrollable-wrap': {
        position: "sticky !important",
        top: "200px !important",
        "@media (max-height: 625px)": {
            top: "100px !important",
        }
    },
});

export default ScrollWrapper;

// trzeba zmienić overflow w body-v2 i container extra-margin-container na overflow: clip, żeby sticky działało