import { css } from "../../stitches.config"

export const h1 = css({
    fontFamily: "$grotesk",
    fontSize: 28,
    lineHeight: "34px",
    letterSpacing: "0.2px",
    fontWeight: "700",
    "@bp4": {
        fontSize: "54px",
        lineHeight: "62px",
        letterSpacing: "0px",
    }
})

export const h2 = css({
    fontFamily: "$grotesk",
    fontSize: 24,
    lineHeight: "30px",
    letterSpacing: "0.2px",
    fontWeight: "700",
    "@bp4": {
        fontSize: 32,
        lineHeight: "40px",
        letterSpacing: "0.3px",
    }
})

export const h3 = css({
    fontFamily: "$grotesk",
    fontSize: 24,
    lineHeight: "30px",
    letterSpacing: "0.2px",
    fontWeight: "700",
})

export const h4Strong = css({
    fontFamily: "$grotesk",
    fontSize: 18,
    lineHeight: "24px",
    letterSpacing: "0.2px",
    fontWeight: "700",
})

export const h4 = css({
    fontFamily: "$grotesk",
    fontSize: 18,
    lineHeight: "24px",
    letterSpacing: "0.2px",
    fontWeight: "600",
})

export const pStrong = css({
    fontFamily: "$charis",
    fontSize: 16,
    lineHeight: "26px",
    letterSpacing: "0.1px",
    fontWeight: "700",
    "@bp4": {
        fontSize: 21,
        lineHeight: "32px",
        letterSpacing: "0px",
    }
})

export const p = css({
    fontFamily: "$charis",
    fontSize: 16,
    lineHeight: "26px",
    letterSpacing: "0.1px",
    fontWeight: "400",
    "@bp4": {
        fontSize: 21,
        lineHeight: "32px",
        letterSpacing: "0px",
    }
})

export const bodyLargeStrong = css({
    fontFamily: "$grotesk",
    fontSize: 16,
    lineHeight: "24px",
    letterSpacing: "0px",
    fontWeight: "800",
})


export const bodyLarge = css({
    fontFamily: "$grotesk",
    fontSize: 16,
    lineHeight: "24px",
    letterSpacing: "0px",
    fontWeight: "600",
})

export const bodyStrong = css({
    fontFamily: "$grotesk",
    fontSize: 14,
    lineHeight: "18px",
    letterSpacing: "0px",
    fontWeight: "800",
})

export const body = css({
    fontFamily: "$grotesk",
    fontSize: 14,
    lineHeight: "18px",
    letterSpacing: "0px",
    fontWeight: "600",
})

export const bodySmall = css({
    fontFamily: "$grotesk",
    fontSize: 12,
    lineHeight: "16px",
    letterSpacing: "0.1px",
    fontWeight: "700",
})

export const dLabel = css({
    fontFamily: "$grotesk",
    fontSize: 12,
    lineHeight: "16px",
    letterSpacing: "0.8px",
    fontWeight: "700",
})
