import React from 'react';
import { styled } from '../../../../stitches.config';

const NavPlaceholder = styled("div", {
    position: "relative",
    backgroundColor: "$grayscale100Fixed",
    minHeight: "128px",
    "@bp4": {
        position: "static",
        minHeight: "118px",
    },
    "&::before": {
        content: "",
        position: "absolute",
        inset: 0,
        zIndex: 5,
        height: "32px",
        background: "$primary",
        "@bp4": {
            height: "34px"
        }
    }
});


export default NavPlaceholder;