import './PodcastPlayerNav.module.scss';

import React, { useState, useEffect, useMemo } from 'react';

import { IPodcastPlayerData, PODCASTS_PLAYERS, usePodcastPlayer } from '../../../context/PodcastPlayerContext';

import PodcastPoster from './PodcastPoster';
import PodcastPlayerVolume from './PodcastPlayerVolume';
import PodcastNextEpisodeButton from './PodcastNextEpisodeButton';

import PodcastSkipTime from './PodcastSkipTime';

import { ReactComponent as PauseIcon } from "../../../assets/img/podcastPlayer/podcast_pause.svg";
import { ReactComponent as PlayIcon } from "../../../assets/img/podcastPlayer/podcast_play.svg";
import { ReactComponent as ReplayIcon } from "../../../assets/img/podcastPlayer/podcast_replay.svg";
import { ReactComponent as AdPlayIcon } from "../../../assets/img/podcastPlayer/podcast_ad.svg";

import { ReactComponent as NextIcon } from "../../../assets/img/podcastPlayer/podcast_next.svg";
import { ReactComponent as CloseIcon } from "../../../assets/img/podcastPlayer/podcast_close.svg";
import Link from '../../../components/Link';

interface Props {
    videoId: string;
}

const PodcastPlayerNav: React.FC<Props> = ({ videoId }) => {

    const { isPlay, isCompleted, play, pause, progress, volume, setVolume, setMedia, seek,
        setEpisode, isPlayerInit, isVideoLoading, isAdPlay } = usePodcastPlayer();

    const [playerData, setPlayerData] = useState<IPodcastPlayerData>({} as IPodcastPlayerData);
    useEffect(() => {
        if (PODCASTS_PLAYERS.hasOwnProperty(videoId))
            setPlayerData(PODCASTS_PLAYERS[videoId] || {} as IPodcastPlayerData);
        console.log('[ES]', PODCASTS_PLAYERS);
    }, [videoId, isVideoLoading]);

    const { podcastPoster, podcastTitle, podcastSeries, nextEpisode, prevEpisode, duration, podcast_article } = playerData;

    const isReady = useMemo(() => isPlayerInit && !isVideoLoading, [isPlayerInit, isVideoLoading]);
    const progressDiff = useMemo(() => (duration || 0) - progress, [progress, duration]);

    return (
        <div className="podcastPlayerNav" id="podcastPlayerNav">
            <div className="podcastPlayerNav__wrapper">
                <PodcastPoster {...podcastPoster} />
                <div className="podcastPlayerNav__titleWrapper">
                    {isAdPlay ? (
                        <p className="podcastPlayerNav__title">
                            Reklama
                        </p>
                    ) : isReady ? (
                        <>
                            {podcastSeries && (
                                <p className="podcastPlayerNav__series">
                                    {podcastSeries}
                                </p>
                            )}
                            <p className="podcastPlayerNav__title">
                                <Link to={`/${podcast_article}`}>{podcastTitle}</Link>
                            </p>
                        </>
                    ) : (
                        <p className="podcastPlayerNav__title">
                            Ładowanie...
                        </p>
                    )}
                </div>
                <div className="podcastPlayerNav__controls">
                    {prevEpisode && <button className="podcastPlayerNav__controls__button podcastPlayerNav__controls__button--prev" onClick={() => setEpisode(prevEpisode)}>
                        <NextIcon />
                    </button>}
                    <PlayButton
                        isAdPlay={isAdPlay}
                        isPlay={isPlay}
                        isCompleted={isCompleted}
                        duration={duration || 0}
                        progress={progress}
                        onClick={() => (!isPlay || isCompleted) ? play() : pause()}
                    />
                    {nextEpisode && <button className="podcastPlayerNav__controls__button" onClick={() => setEpisode(nextEpisode)}>
                        <NextIcon />
                    </button>}
                </div>
                <PodcastSkipTime
                    duration={duration || 0}
                    progress={progress}
                    videoId={videoId}
                />
                {((progressDiff > 10 && !isCompleted) || !nextEpisode) && <PodcastPlayerVolume volume={volume} setVolume={setVolume} />}
                {(progressDiff <= 10 || isCompleted) && nextEpisode && <PodcastNextEpisodeButton onClick={() => setEpisode(nextEpisode)} />}
                <button className="podcastPlayerNav__close" onClick={() => setMedia(undefined)}>
                    <CloseIcon />
                </button>
            </div>
        </div>
    )

};

export default PodcastPlayerNav;

interface PlayButtonProps {
    isPlay: boolean;
    isCompleted: boolean;
    duration: number;
    progress: number;
    isAdPlay: boolean;
    onClick: () => void;
}

const PlayButton: React.FC<PlayButtonProps> = ({ isPlay, isCompleted, duration, progress, isAdPlay, onClick }) => {

    const [pogressPercent, setProgress] = useState<number>(0);

    useEffect(() => {
        setProgress(progress / duration * 100);
    }, [progress, duration]);

    return (
        <button className={`podcastPlayerNav__controls__play ${isPlay ? 'podcastPlayerNav__controls__play--played-' : ''}`} onClick={onClick}>
            <svg id="svg" width="208" height="208" viewBox="0 0 208 208" className="podcastPlayerNav__progress">
                <circle r="98" cx="104" cy="104" strokeDasharray={`${100 - pogressPercent} 100`} pathLength="100"
                    className="podcastPlayerNav__progress__bg"
                    style={{ transform: `rotate(${270 + pogressPercent / 100 * 360}deg)` }}
                />
                <circle r="98" cx="104" cy="104" strokeDasharray={`${pogressPercent} 100`} pathLength="100"
                    className="podcastPlayerNav__progress__actual"
                />
            </svg>
            {isAdPlay && <AdPlayIcon className="adPlayButton" />}
            {!isAdPlay && <>
                {!isPlay && !isCompleted && <PlayIcon style={{ transform: 'translateX(12.5%)' }} />}
                {isPlay && <PauseIcon />}
                {isCompleted && <ReplayIcon />}
            </>}
        </button>
    )

}