import React, { useInsertionEffect, useEffect, useRef } from 'react';
import { createStitches } from '@stitches/react';
import * as Stitches from '@stitches/react';
import { StitchesBrandsConfig } from './config'

export type BrandColors = {
    [type in Config.Brand]: {
        primary120: string;
        primary: string;
        primary60: string;
        secondary?: string;
        secondary120?: string;
    }
}

//https://codepen.io/sosuke/pen/Pjoqqp
type BrandSVGFilter = {
    [type in Config.Brand]: {
        svgPrimary: string;
    }
}


export const mainColor: BrandColors = StitchesBrandsConfig.mainColor;

export const mainSVGFilter: BrandSVGFilter = StitchesBrandsConfig.mainSVGFilter

const getPrimaryColors = (brand: Config.Brand): BrandColors[keyof BrandColors] => (mainColor[brand]);

const getSVGFilter = (brand: Config.Brand): BrandSVGFilter[keyof BrandSVGFilter] => (mainSVGFilter[brand]);

export const defaultColors = {
    ...getPrimaryColors(window.BRAND),
    ...StitchesBrandsConfig.defaultColors,
    darkmodeStroke: "#3A3A3A",
    darkmodeBg: "#1B1B1B",
    grayscale0: "#FFFFFF",
    grayscale10: "#F5F5F5",
    grayscale25: "#E7E7E7",
    grayscale50: "#CCCCCC",
    grayscale75: "#8D8D8D",
    grayscale90: "#1B1B1B",
    grayscale100: "#131212",
    grayscale100Fixed: "#131212",
    grayscale0Fixed: "#FFFFFF",
    columnistBackground: "#FFFFFF",
    hardcoverColor: "#f72c3f"
}

export const { styled, css, globalCss, config, theme, createTheme, keyframes, getCssText } = createStitches({
    media: {
        mobile: "(max-width: 995px)",
        desktop: "(min-width: 996px)",
        bp6: "(min-width: 1310px)",
        bp5: "(min-width: 1310px)",
        bp4: "(min-width: 996px)",
        bp3: "(min-width: 768px)",
        bp2: "(min-width: 375px)",
        bp1: "(min-width: 360px)",
    },
    theme: {
        colors: {
            ...defaultColors,
            secondary: defaultColors.primary,
            secondary120: defaultColors.primary120,
        },
        filter: {
            ...getSVGFilter(window.BRAND)
        },
        fonts: {
            grotesk: "HK Grotesk, sans-serif",
            charis: "CharisSIL, serif"
        },
        fontWeights: {
            4: 400,
            6: 600,
            7: 700,
            8: 800
        },
        fontSize: {},
        lineHeights: {
            16: "16px",
            18: "18px",
            24: "24px",
            26: "26px",
            30: "30px",
            32: "32px",
            40: "40px",
            62: "62px"

        },
        letterSpacings: {
            0: 0,
            1: 0.1,
            2: 0.2,
            3: 0.3,
            8: 0.8
        }
    },
});

export const darkTheme = createTheme({
    colors: {
        grayscale0: "#131212",
        grayscale10: "#1B1B1B",
        grayscale25: "#8D8D8D",
        grayscale50: "#CCCCCC",
        grayscale75: "#E7E7E7",
        grayscale90: "#8D8D8D",
        grayscale100: "#FFFFFF",
        columnistBackground: "#232323",
        secondary: defaultColors.secondary ? defaultColors.secondary : defaultColors.primary,
        secondary120: defaultColors.secondary120 ? defaultColors.secondary120 : defaultColors.primary120,
    },
});

export const globalStyles = globalCss({
    "*": {
        boxSizing: "border-box",
        ".container": {
            "@bp2": {
                // border: "1px solid blue",
                width: 375,
            },
        }
    },
    "body": {
        backgroundColor: "$grayscale0",
    },
});

export type CSS = Stitches.CSS<typeof config>;

export const useGlobalCSS = (styles: any, deps?: React.DependencyList) => {

    const additionalStylesRef = useRef<string>("");
    const rootElement = document.documentElement;

    useInsertionEffect(() => {
        additionalStylesRef.current = css(styles).toString();

        rootElement.classList.add(additionalStylesRef?.current);
        return () => rootElement.classList.remove(additionalStylesRef.current);
    }, deps ?? []);

    //Bug react 18.2 - insertionEffect does not always run a cleanup function, so we need to handle it using useEffect
    useEffect(() => {
        return () => rootElement.classList.remove(additionalStylesRef.current);
    }, []);

};

export const Global: React.FC<{ styles: any }> = ({ styles }) => {
    useGlobalCSS(styles);
    return null;
};