import React, { useEffect } from 'react';
import ArticleStore from '../../stores/ArticleStore';
import ArticleCacheChannel, { IArticleCacheWorkerMessage, IArticleCacheWorkerMessagePreloadData, IArticleCacheWorkerMessageReloadData, IArticleCacheWorkerMessageCheckData, IArticleCacheWorkerMessageStatusData } from './ArticleCacheChannel';
import { IS_FEATURE_ARTICLE_PRELOAD } from '../../components/common/constants/constants';

const ARTICLE_STATUS_EVENT = "ARTICLE_STATUS";

const handleMessage = (event: MessageEvent, articleStore: ArticleStore) => {
    console.log('[ES] Message from ArticleCache', event.data);

    const { command, data } = event.data as IArticleCacheWorkerMessage;

    switch (command) {

        //Reload article
        case 'RELOAD': {
            const { slug } = data as IArticleCacheWorkerMessageReloadData;

            if (articleStore.currentSlug == slug) {
                articleStore.updateSlug(slug);
                console.log('[ES] ArticleCache reload article');
            }
            break;
        }

        //Article status
        case 'STATUS': {
            const status = data as IArticleCacheWorkerMessageStatusData;

            //Emit event with article status
            document.dispatchEvent(new CustomEvent<IArticleCacheWorkerMessageStatusData>(ARTICLE_STATUS_EVENT, {
                detail: {
                    ...status
                }
            }));
            break;
        }
    }
}

//Init cache client
export default async (articleStore: ArticleStore) => {
    if (!IS_FEATURE_ARTICLE_PRELOAD)
        return;

    //Register message channel with service if available
    if ('serviceWorker' in navigator) {
        //Send port channel to worker

        const DefaultWorker = await navigator.serviceWorker.ready;

        if (!DefaultWorker || !DefaultWorker.active)
            return;

        DefaultWorker.active.postMessage({
            type: "ARTICLE_CACHE_PORT_INIT"
        }, [
            ArticleCacheChannel.port2
        ]);

        //Handle messages from worker
        ArticleCacheChannel.port1.onmessage = (event: MessageEvent) => handleMessage(event, articleStore as ArticleStore);
    }
};

export const preloadArticle = (slug: string, force?: boolean) => {
    if (!IS_FEATURE_ARTICLE_PRELOAD)
        return;

    ArticleCacheChannel.port1.postMessage({
        command: "PRELOAD",
        data: {
            slug,
            force: force ?? false
        } as IArticleCacheWorkerMessagePreloadData
    } as IArticleCacheWorkerMessage);
};

export const checkArticleCache = (slug: string): Promise<IArticleCacheWorkerMessageStatusData | undefined> => new Promise((resolve) => {
    if (!IS_FEATURE_ARTICLE_PRELOAD || !('serviceWorker' in navigator)) {
        resolve(undefined)
        return;
    }

    const statusListener = (e: CustomEvent<IArticleCacheWorkerMessageStatusData>) => {
        document.removeEventListener(ARTICLE_STATUS_EVENT, statusListener as EventListener);

        const status = e.detail;
        if (status.slug == slug)
            resolve(status);
    };
    document.addEventListener(ARTICLE_STATUS_EVENT, statusListener as EventListener);

    ArticleCacheChannel.port1.postMessage({
        command: "CHECK",
        data: {
            slug
        } as IArticleCacheWorkerMessageCheckData
    } as IArticleCacheWorkerMessage);

    //Default response after 10ms
    setTimeout(() => {
        document.removeEventListener(ARTICLE_STATUS_EVENT, statusListener as EventListener);
        resolve(undefined);
    }, 10);
});

//Preload on intersection
const INTERSECTION_TIMEOUT = (window.APP_SETTINGS?.feature_flags?.articlePreload ?? 2) * 1000;

let LinkIntersectionTimeouts: { [slug: string]: NodeJS.Timeout } = {};

const LinkIntersectionObserver = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
        const linkElement = entry.target as HTMLAnchorElement;
        const { pathname: slug } = new URL(linkElement.href);

        if (entry.isIntersecting) {
            if (LinkIntersectionTimeouts.hasOwnProperty(slug))
                return;

            LinkIntersectionTimeouts[slug] = setTimeout(() => {
                //Preload
                preloadArticle(slug.replace(/^\//, ''));

                LinkIntersectionObserver.unobserve(linkElement as Element);
                delete LinkIntersectionTimeouts[slug];
            }, INTERSECTION_TIMEOUT);
        } else {
            clearTimeout(LinkIntersectionTimeouts?.[slug]);
            delete LinkIntersectionTimeouts[slug];
        }
    });
}, {
    threshold: 1.0
});

export const useArticleIntersectionPreload = (ref: React.MutableRefObject<HTMLAnchorElement | undefined>) => {

    useEffect(() => {
        if (!ref || !ref.current)
            return;

        const hrefURL = new URL(ref.current.href);

        //Observe only article links
        //TODO: /zdrowie/...
        if (hrefURL.pathname.search(/^\/[0-9]+\,/) == 0 && IS_FEATURE_ARTICLE_PRELOAD)
            LinkIntersectionObserver.observe(ref.current as Element);

        return () => ref && ref.current && LinkIntersectionObserver.unobserve(ref.current as Element);
    }, []);
};