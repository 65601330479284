import React from 'react'
import { observer } from 'mobx-react'
import { styled } from "../../../../stitches.config";

import * as S from './common/styles/Styles';
import { Author } from "./BridImageBanner";

const AuthorBrid: React.FC<{ author: Author }> = ({ author }) => {
    const isDesktop = window.innerWidth > 996;
    return (
        <S.CircleImg diameter={isDesktop ? 40 : 32} src={author.image} />)
};

const Wrap = styled("div", {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    margin: 0,
    "& img": {
        borderRadius: "40px",
        border: "1px solid #fff",
    },
    "@bp4": {
        marginLeft: 0,
        maxWidth: "40%",
    }
});

export default AuthorBrid