import React from "react";
import { inject, observer } from "mobx-react";

const KulkiSvg = inject("UIStore")(
    observer(({ UIStore = {} }) => {
        const color = (UIStore.appTheme.mainColor ? UIStore.appTheme.mainColor : '#f60');

        return (
            <svg width="12" height="24" viewBox="0 0 12 13" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <circle cx="6" cy="6" r="3" stroke={color} strokeWidth="1" fill="transparent" />
                <circle cx="6" cy="15" r="3" stroke={color} strokeWidth="1" fill="transparent" />
            </svg>
        );
    }
    )
);

export default KulkiSvg;