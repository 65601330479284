import React from 'react';
import { styled } from '../../../stitches.config';
import Logo from '../../atoms/Logo';

const PodcastPosterPlaceholder: React.FC = () => {

    return (
        <Placeholder>
            <Logo />
        </Placeholder>
    );

};

export default PodcastPosterPlaceholder;

const Placeholder = styled('div', {
    position: 'relative',
    background: '$primary',
    width: '100%',
    '&::before': {
        content: '',
        display: 'block',
        paddingTop: '100%'
    },
    '& > div': {
        position: 'absolute',
        background: 'transparent',
        padding: '0 20%',
        width: '100%',
        top: '50%',
        transform: 'translateY(-50%)',
        'svg path': {
            fill: '$grayscale0Fixed !important'
        }
    }
});