import axios, { AxiosResponse } from "axios"

export async function unsubscribeZen(): Promise<AxiosResponse> {
    try {
        const res = await axios.post(`${window.API_HOST}/api/account/unsubscribe`);
        return res.data;
    } catch (err) {
        if (axios.isAxiosError(err)) {
            throw { status: err.response!.status, errors: err.response!.data.errors }
        } else {
            throw new Error('Cannot unsubscribe.')
        }
    }
}