import React from "react";
import axios, { AxiosResponse } from "axios";
import { PaymentParams, PaymentPayload, PaymentStatus, PaymentType } from "../redesign/types/Payment";
import { useLocation } from "react-router-dom";


export type PaymentInvalidData = {
  status: number;
  isFormValid: boolean;
} & {
    [field in keyof PaymentPayload]?: string
  };

export async function startPayment(
  payload: PaymentPayload,
  type: PaymentType
): Promise<any> {
  try {
    const res = await axios.post(
      `${window.API_HOST}/api/payment`,
      { ...payload, type: type }
    );

    (res.data?.redirect_to && window.open(res.data.redirect_to, "_self"))
    // return (res.data?.redirect_to && window.open(res.data.redirect_to, "_self")) //res.data?.checkSuccessUrl;
    return res.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      throw {
        status: err.response!.status,
        errors: err.response!.data.errors,
        isFormValid: false,
        ...Object.entries(err.response!.data.errors ?? {}).reduce((errors, [field, messages]) => {

          if (Array.isArray(messages) && messages.length > 0)
            errors[field] = messages[0];
          return errors;

        }, {} as any)
      } as PaymentInvalidData;
    } else {
      throw new Error("Cannot start payment.");
    }
  }
}

export function isPaymentFormError(error: any) {
  return (typeof error === 'object' && error !== null && error.hasOwnProperty('isFormValid'));
}
//
