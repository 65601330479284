import React, { useMemo } from "react";
import { styled } from "../../../../../../stitches.config";

export const H2: React.FC<React.HTMLAttributes<HTMLHeadingElement> & { color?: string }> = ({ color, ...props }) => {

  const Component = useMemo(() => (
    styled("h2", {
      fontWeight: 700,
      fontSize: "24px",
      lineHeight: "30px",
      letterSpacing: "0.2px",
      "@bp4": {
        fontSize: "38px"
      },
      color: `${color ? color : "#131212"}`
    })
  ), [color]);

  return <Component {...props} />;
};

export const H3: React.FC<React.HTMLAttributes<HTMLHeadingElement> & { color?: string }> = ({ color, ...props }) => {

  const Component = useMemo(() => (
    styled("h3", {
      fontSize: "16px",
      fontWeight: "900",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.25",
      borderRadius: "50%",
      color: `${color ? color : "#272424"}`
    })
  ), [color]);

  return <Component {...props} />;
};

export const H4 = styled("h4", {
  fontSize: "22px",
  fontWeight: "normal",
  lineHeight: 1.09,
  letterSpacing: "-0.32px",
  color: "#333333",
  fontStretch: "normal",
  "@bp4": {
    fontSize: "16px",
    lineHeight: 1.5,
    letterSpacing: "normal",
    color: "#b7b7b7"
  }
});

type TCircleImg = {
  diameter: number;
};

export const CircleImg: React.FC<React.ImgHTMLAttributes<HTMLImageElement> & TCircleImg> = ({ diameter, ...props }) => {

  const Component = useMemo(() => (
    styled("img", {
      width: `${diameter}px`,
      height: `${diameter}px`,
      borderRadius: "50%"
    })
  ), [diameter]);

  return <Component {...props} />;
};
