import React, { useEffect, Suspense } from 'react';

import { ReactComponent as SoundOnIcon } from "../../../assets/img/podcastPlayer/podcast_sound_on.svg";

//import { Range } from 'react-range';
const Range = React.lazy(() => import(/* webpackChunkName: "react-range" */ "react-range").then(m => ({ default: m.Range })));

interface Props {
    volume: number;
    setVolume: (volume: number) => void
};

const PodcastPlayerVolume: React.FC<Props> = ({ volume, setVolume }) => {

    return (
        <div className="podcastPlayerNav__volume">
            <button className="podcastPlayerNav__volume__button" onClick={() => setVolume(volume > 0 ? 0 : 1)}>
                <SoundOnIcon />
            </button>
            <Suspense fallback={null}>
                <Range
                    step={0.1}
                    min={0}
                    max={1}
                    values={[volume]}
                    onChange={(values) => setVolume(values[0])}
                    renderTrack={
                        ({ props, children }) => (
                            <div className="podcastPlayerNav__volume__track" {...props}>
                                <div className="podcastPlayerNav__volume__track__current" style={{ width: (volume * 100) + '%' }} />
                                {children}
                            </div>
                        )
                    }
                    renderThumb={
                        ({ props }) => (
                            <div className="podcastPlayerNav__volume__thumb" {...props} />
                        )
                    }
                />
            </Suspense>
        </div>
    )

}

export default PodcastPlayerVolume;