import React from 'react'
import { styled } from "../../../../../stitches.config";

import { useWindowWidth } from '../../../../../helpers/useWindowWidth'

import AuthorCardMobile from './AuthorCardMobile'
import AuthorCardDesktop from './AuthorCardDesktop'

import { h3 } from "../../../../../redesign/commonStyles/Fonts";
export interface Author {
    name: string;
    image: string;
    profession: string;
    about: string;
    portfolio: Array<string>;
}

interface IData {
    authors: Array<Author>;
}

const BridAuthors: React.FC<{ blockData: IData }> = ({ blockData }) => {
    const width = useWindowWidth();
    const isDesktop = window.innerWidth > 996;
    return (blockData.authors && blockData.authors.length > 0
        ? (<Wrap id="zespol-realizujacy-projekt">
            <h3 className={h3()}>
                Zespół realizujący projekt
            </h3>
            {blockData.authors.map((item, index) => (
                <>
                    {isDesktop ?
                        <AuthorCardDesktop author={item} key={index} />
                        :
                        <AuthorCardMobile author={item} key={index} />
                    }
                </>
            ))}
        </Wrap>)
        : null);
}

const Wrap = styled("div", {
    margin: "0 auto",
    padding: "8px 16px 40px 16px",
    backgroundColor: "$grayscale0",
    "@bp4": {
        padding: "24px 48px 56px 48px",
    },
    "& h3": {
        color: "$grayscale100",
    }
});

export default BridAuthors