import * as React from "react"
import { styled } from "../../stitches.config";


type UnorderedListProps = {
    children?: any,
}

const UnorderedList: React.FC<UnorderedListProps> = ({ children }) => {
    return <BulletedList>
        {children}
    </BulletedList>
}

const BulletedList = styled("div", {
    listStyle: "none",
    paddingLeft: "0",
    fontFamily: "$chariss",
    color: "$grayscale100",
    fontSize: "16px",
    lineHeight: "26px",
    "@bp4": {
        fontSize: "21px",
        lineHeight: "32px",
        paddingLeft: "40px",
    },
    "& li": {
        "&:before": {
            width: "12px",
            height: "12px",
            borderRadius: "50%",
            content: "",
            display: "inline-block",
            backgroundColor: "$primary",
            marginRight: "10px",
            position: "relative",
        },

        " div": {
            display: "inline-block",
        }
    },
});

export default UnorderedList