import React from 'react';
import { styled } from "../stitches.config"
import { pStrong, p } from "./commonStyles/Fonts";


type LeadProps = {
    text: string,
    isPodcast?: boolean,
}

const Lead: React.FC<LeadProps> = ({
    text,
    isPodcast = false,
    ...props
}) => {
    return (
        <>
            {isPodcast ?
                <>
                    <StyledLead className={p()} >
                        {text}
                    </StyledLead >
                </>
                :
                <>
                    <StyledLead className={pStrong()} >
                        {text}
                    </StyledLead>
                </>
            }
        </>
    );
};

const StyledLead = styled('p', {
    margin: "24px 0 40px 0",
    "@bp4": {
        margin: "40px 0 64px 0",
    }
})


export default Lead