import React from "react";

import checked from "../../../../assets/img/checked.svg";
import unchecked from "../../../../assets/img/unchecked.svg";
import { styled } from "../../../../stitches.config";
import { h3, h4, h4Strong, bodyLargeStrong, bodyLarge } from "../../../../redesign/commonStyles/Fonts";

import { WithAnimateHeight } from "./common/components/AnimateHeightWrapper";


interface Checkbox {
  description: string;
  value: number;
  checked: boolean;
}

interface IData {
  checkboxes: Checkbox[];
}

interface IProps {
  blockData: IData;
  props?: any;
}

const BridShoppingList: React.FC<IProps> = ({ blockData, ...props }) => {
  const { checkboxes }: IData = blockData;
  const isDesktop = window.innerWidth > 996;
  const amount = checkboxes
    .map((item) => item.value)
    .reduce((acc, curr) => acc + curr);

  return (
    <Wrap>
      <h3 className={h3()}>Lista Zakupów</h3>
      <WithAnimateHeight condition={checkboxes.length >= 5}>
        <div>
          <List>
            {checkboxes.map((item, index) => (
              <ListItem key={index}>
                <h4 className={isDesktop ? h4() : bodyLarge()}>{item.description}</h4>
                <span className={isDesktop ? h4Strong() : bodyLargeStrong()}>{item.value} zł</span>
              </ListItem>
            ))}
          </List>
          <Sum className={isDesktop ? h3() : h4()}>
            <p>Razem: </p>
            <span>{amount} zł</span>
          </Sum>
        </div>
      </WithAnimateHeight>
    </Wrap>
  );
};

const Wrap = styled("div", {
  margin: "16px auto",
  fontFamily: "$grotesk",
  background: "$grayscale0",
  padding: "8px 16px 40px 16px",
  "@bp4": {
    padding: "24px 48px 56px 48px",
  },
  h3: {
    color: "$grayscale100",
    marginBottom: "24px",
    "@bp4": {
      marginBottom: "32px",
    }
  }
});

const List = styled("ul", {
  listStyleType: "none",
  margin: 0,
  padding: 0,
});

const ListItem = styled("li", {
  padding: "16px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  backgroundColor: "$grayscale10",
  marginBottom: "8px",
  h4: {
    margin: 0,
    color: "$grayscale100",
  },
  span: {
    marginLeft: "auto",
    color: "$primary",
    fontFamily: "$grotesk",
    flexShrink: "0",
    paddingLeft: "6px",
  },
  "@bp4": {
    padding: "24px",
  }
});

const Sum = styled("div", {
  position: "relative",
  marginTop: "24px",
  marginLeft: "auto",
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  span: {
    margin: "0 24px 0 0",
    color: "$primary",
  },
  p: {
    color: "$grayscale100",
    margin: "0 8px 0 0",
  },
});

export default BridShoppingList;
