import React, { PropsWithChildren, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { IS_TABOOLA_NEWSROOM } from '../components/common/constants/constants';
import { useStores } from './useStores';

type TaboolaNewsroomPage = 'home' | 'article' | undefined;

const cleanupTaboolaNewsroom = () => {

    const w = (window as any);

    const script = document.getElementById('tb_newsroom_script');
    if (script) {
        document.body?.removeChild(script);
    }

    w._newsroom = undefined;
}

export const useTaboolaNewsroom = (newsroomPage: TaboolaNewsroomPage) => {

    const { AdsStore } = useStores();
    const location = useLocation();

    useEffect(() => {
        if (!IS_TABOOLA_NEWSROOM || (!AdsStore.tcDataLoaded && !new URLSearchParams(location.search).has('tbcrawl')))
            return;

        cleanupTaboolaNewsroom();
        if (!newsroomPage)
            return;


        const w = (window as any);
        w._newsroom = w._newsroom || [];

        if (newsroomPage == 'home') {
            w._newsroom.push({ pageTemplate: 'home' });
            w._newsroom.push({ pageDashboard: 'home' });
            w._newsroom.push('auditClicks');
            w._newsroom.push('trackPage');
        }

        const script: HTMLScriptElement = document.createElement('script');

        script.id = 'tb_newsroom_script';
        script.src = '//c2.taboola.com/nr/natematgroup-natemat/newsroom.js';
        script.async = true;

        document.body.appendChild(script);

        return () => cleanupTaboolaNewsroom();
    }, [newsroomPage, location, AdsStore.tcDataLoaded]);

};

interface ITaboolaNewsroomWrapper {
    newsroomPage: TaboolaNewsroomPage;
};

export const TaboolaNewsroomWrapper: React.FC<PropsWithChildren<ITaboolaNewsroomWrapper>> = ({ newsroomPage, children }) => {

    useTaboolaNewsroom(newsroomPage);

    return (
        <>
            {children}
        </>
    );
};