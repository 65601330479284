import React, { Component } from "react";
import { inject } from "mobx-react";
import { styled } from "../stitches.config";
import Link from "./Link";
import { keyframes } from "@stitches/react";
import { h2, bodyLarge } from "../redesign/commonStyles/Fonts";

import TrackEvent from "../components/TrackEvent";
import { pushDataLayer } from "../helpers/pushDataLayer"

import ErrorPage from "../redesign/pages/error-pages/ErrorPage";

@inject("UIStore")
export default class Error extends Component {
  componentDidMount() {
    pushDataLayer({
      'event': 'virtualPageview',
      'virtualPageURL': window.location.pathname,
      'virtualPageTitle': 'Nie ma takiej strony',
      'pageType': 'Błąd 404'
    }),
      setTimeout(() => {
        window.location.href = "/";
      }, 15000);
  }
  render() {
    this.props.UIStore.setCurrentAction(['error']);
    return (
      <ErrorWrapper>
        {/* wszędzie wyświetlamy błąd 404, poza 403 i 500 */}
        {this.props.error !== "403" && this.props.error !== "500" && (
          <ErrorPage />
        )}
        {this.props.error === "403" &&
          <>
            <NumberBox>
              <h1>403</h1>
            </NumberBox>
            <Title className={h2()}>Nie możemy Cię wpuścić</Title>
            <Description className={bodyLarge()}>Przepraszamy, ale nie masz uprawnień do przeglądania tego zasobu.</Description>
          </>
        }
        {this.props.error === "500" &&
          <>
            <NumberBox>
              <h1>500</h1>
            </NumberBox>
            <Title className={h2()}>Ups, coś poszło nie tak</Title>
            <Description className={bodyLarge()}>Mamy problem z naszym serwerem. Sprawdź ponownie później.</Description>
          </>
        }
        <Link to="/" className="goBackButton"> <span>Powrót na stronę główną</span></Link>
        <TrackEvent name="ErrorPage" category="ANALYTICS" label={window.location.href} />
      </ErrorWrapper>
    );
  }
}

Error.defaultProps = {
  error: "Nie ma takiej strony"
};

const progress = keyframes({
  "0%": { width: "0%" },
  "100%": { width: "100%" },
});

export const ErrorWrapper = styled("div", {
  fontSize: "32px",
  textAlign: "center",
  padding: "16px",
  fontWeight: "bold",
  background: "$grayscale0",
  "@bp4": {
    padding: "40px 20px",
  },
  "& .goBackButton": {
    color: "$grayscale100Fixed",
    background: "$grayscale10",
    display: "block",
    margin: "32px auto 0 auto",
    padding: "11px 14px 13px 14px",
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "24px",
    width: "220px",
    position: "relative",
    "&:hover": {
      color: "$grayscale100Fixed",
      textDecoration: "none",
    },
    "@bp4": {
      margin: "32px auto 0 auto",
    },
    "& span": {
      position: "relative",
      zIndex: 10,
    },
    "&:before": {
      zIndex: 2,
      content: "",
      position: "absolute",
      top: "0",
      bottom: "0",
      left: "0",
      animation: `${progress} 15s linear`,
      backgroundColor: "$primary",
      animationFillMode: "forwards",
    }
  }
});

const Title = styled("div", {
  marginBottom: "8px",
  color: "$grayscale100",
  textAlign: "left",
  "@bp4": {
    marginBottom: "16px",
    textAlign: "center",
  }
});

const Description = styled("div", {
  maxWidth: "650px",
  color: "$grayscale100",
  marginBottom: "32px",
  textAlign: "left",
  margin: "0 auto",
  "@bp4": {
    textAlign: "center",
  }
});

const NumberBox = styled("div", {
  width: "100%",
  height: "218px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: "$grayscale25",
  maxWidth: "1272px",
  margin: "0 auto",
  color: "$grayscale100Fixed",
  marginBottom: "32px",
  "@bp4": {
    height: "312px",
    marginBottom: "48px",
  },
  "& h1": {
    fontSize: "54px",
    fontWeight: "700",
    lineHeight: "62px",
    letterSpacing: "-0.5px",
  }
});
