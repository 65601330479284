import React, { PropsWithChildren } from "react";
import { styled } from "../../../../stitches.config";

import ErrorBoundary from "../ErrorBoundary";

import NoSSR from "../../../../helpers/NoSSR";

interface ISlateBlockBizon {
    noSSR?: boolean | {
        fallback: React.ReactNode;
    };
};

const SlateBlockBizon: React.FC<PropsWithChildren<ISlateBlockBizon>> = ({ children, noSSR }) => {

    let WrapperComponent: React.ComponentType = React.Fragment;
    let WrapperProps: any = undefined;

    if (!!noSSR) {
        WrapperComponent = NoSSR;

        if (typeof noSSR !== "boolean") {
            WrapperProps = {
                fallback: noSSR.fallback
            };
        }
    }

    return (
        <ErrorBoundary>
            <WrapperComponent {...WrapperProps}>
                {children}
            </WrapperComponent>
        </ErrorBoundary>
    );

};

export default SlateBlockBizon;

const NoSSRBasePlaceholder = styled("div", {});