import React, { Component, Fragment } from "react";
import { styled } from "../../../stitches.config";
import { inject, observer } from "mobx-react";
// import { withRouter } from "react-router-dom";

import { withThemeContext } from "../../../redesign/components/StitchesThemeProvider";


import Parser, { attributesToProps } from "html-react-parser";
import domToReact from "html-react-parser/lib/dom-to-react";

import { ConsentConditional } from "../../HOCs/withConsent"

import Html from '../Parts/Html'
import ParagraphContent from "../Parts/ParagraphContent"
import Link from "../../Link";

class HtmlParser extends Component {
  options = {
    replace: el => {
      if (el.name === 'a') {
        return <Link to={el.attribs.href} disableNofollow={this.props.disableNofollow} data-analytics-name="in-text-link">
          {domToReact(el.children, this.options)}
        </Link>
      } else if (el.name === 'ul') {
        return <ParagraphContent>
          {domToReact(el.children, this.options)}
        </ParagraphContent>
      } else if (el.name === 'iframe') {
        const iframeProps = attributesToProps(el.attribs);
        return <ConsentConditional><iframe {...iframeProps} /></ConsentConditional>
      } else if (el.name === "script" && el.attribs) {
        if (el.attribs.src && el.attribs.src.length > 1) {
          var scriptSrc = el.attribs.src;
          // console.log('HtmlParser load script', scriptSrc, el);
          setTimeout(function () {
            var s = document.createElement("script");
            s.type = "text/javascript"; s.async = true;
            s.setAttribute("src", scriptSrc);
            document.getElementsByTagName("body")[0].appendChild(s);
          }, 300);
          return <Fragment />;

        } else if (el.attribs.type === 'text/javascript') {
          var script = (el.children[0] ? el.children[0].data : null);
          setTimeout(function () { window.eval(script) }, 1000);
          return <Fragment />;
          // powinno być tak jak poniżej, zamiast tych 2 linijek powyżej, ale nie działa
          // return <Safe.script>{script}</Safe.script>;
        }
      }

      if (
        el?.type === "text" &&
        el?.prev?.name === "br" &&
        (
          el?.data.includes("\nNapisz do autora: ") ||
          el?.data.includes("\nNapisz do autorki: ") ||
          el?.data.includes("\nSkontaktuj się z autorką: ") ||
          el?.data.includes("\nSkontaktuj się z autorem: ")
        )
      ) {
        const email = el?.data
          .replace("\nNapisz do autora: ", "")
          .replace("\nNapisz do autorki: ", "")
          .replace("\nSkontaktuj się z autorką: ", "")
          .replace("\nSkontaktuj się z autorem: ", "")
          .trim();
        return (
          <AuthorEmailWithHeader>
            {el?.data.replace(email, "")}
            <a href={`mailto:${email}`}>
              {email}
            </a>
            <span onClick={() => { navigator.clipboard.writeText(email) }} role="button">
              <svg className="svg-copy-icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.3333 0.666687H0.666626V11.3334H4.66663V15.3334H15.3333V4.66669H11.3333V0.666687ZM9.99996 6.00002V4.66669L9.99996 2.00002H1.99996V10L4.66663 10H5.99996L9.99996 10L9.99996 6.00002ZM5.99996 11.3334H11.3333V6.00002H14V14H5.99996V11.3334Z" />
              </svg>
            </span>
          </AuthorEmailWithHeader>
        )
      }
    }
  }

  render() {
    const { data = "" } = this.props;
    const { isDarkMode } = this.props;
    const dark = this.props.invert ? !isDarkMode : isDarkMode;

    const articleBody = Parser(
      data,
      this.options
    );
    return typeof articleBody === "string" ?
      <Html html={articleBody} isDarkMode={dark} />
      :
      <ContainerBizon isDarkMode={isDarkMode}>{articleBody}</ContainerBizon>
  }
}

const AuthorEmailWithHeader = styled("span", {
  color: "#8D8D8D",
  fontSize: "18px",
  letterSpacing: "0.2px",
  "@bp4": {
    display: "flex"
  },
  "> span": {
    width: "9px",
    height: "11px",
    borderWidth: "0",
    padding: "0",
    marginLeft: "8px",
    backgroundColor: "transparent",
    cursor: "pointer"
  },
  "> a": {
    color: "$grayscale0Fixed",
    "@bp4": {
      marginLeft: "7px"
    }
  },
  ".svg-copy-icon": {
    fill: "$-grayscale0Fixed"
  }
});


const ContainerBizon = styled("div", {
  color: "$grayscale100",
  fontSize: "16px",
  fontWeight: 400,
  letterSpacing: "0.1px",
  lineHeight: "26px",
  fontFamily: "$charis",
  "a": {
    color: '"$primary" !important',
    "&:hover": {
      textDecoration: "none"
    }
  },
  "@bp4": {
    maxWidth: "840px",
    margin: "20px auto",
    fontSize: "21px",
    letterSpacing: "0px",
    lineHeight: "32px"
  }
})

HtmlParser.defaultProps = {
  hideAdslots: false
}

export default withThemeContext(HtmlParser);
