export function setYBTargeting() {
    var googletag = window.googletag || {};
    googletag.cmd = googletag.cmd || [];
    googletag.cmd.push(function () {

        var _YB = _YB || {
            bm: function () { return (Math.random() >= 0.1 ? '1' : '0'); },
            dc: function () { return (_YB.dool ? 'd' : 'c' + Math.floor(Math.random() * 20)); },
            mx: function () { return (!_YB.dool ? 'x' : 'm' + Math.floor(Math.random() * 180)); },
            tt: function () { return ('tt' + Math.floor(Math.random() * 10)); },
            dool: Math.random() >= 0.1
        };
        let sample;
        if (Math.random() <= 0.1) {
            sample = 'c';
        } else {
            if (Math.random() <= 0.1) {
                sample = 'a' + Math.floor(Math.random() * 10);
            } else {
                sample = 'b';
            }
        }
        var _yt = new Date(), yb_th = _yt.getUTCHours() - 8, yb_tm = _yt.getUTCMinutes(), yb_wd = _yt.getUTCDay();
        if (yb_th < 0) { yb_th = 24 + yb_th; yb_wd -= 1; };
        if (yb_wd < 0) { yb_wd = 7 + yb_wd };

        googletag.pubads().setTargeting('yb_bm', _YB.bm());
        googletag.pubads().setTargeting('yb_ab', sample);
        googletag.pubads().setTargeting('yb_dc', _YB.dc());
        googletag.pubads().setTargeting('yb_mx', _YB.mx());
        googletag.pubads().setTargeting('yb_tt', _YB.tt());
        googletag.pubads().setTargeting('yb_ff', '' + Math.round(Math.random()));
        googletag.pubads().setTargeting('yb_th', yb_th.toString());
        googletag.pubads().setTargeting('yb_tm', yb_tm.toString());
        googletag.pubads().setTargeting('yb_wd', yb_wd.toString());
    });
}