import React from "react"
//TODO otypowac wrapper
//do implementacji zamiast WithAnimateHeight w AnimateHeightWrapper.tsx i WithLink w BridTileSmall2.js
type ConditionalWrapperProps = {
    condition: boolean;
    wrapper: (children: React.ReactNode) => React.ReactElement
    children: React.ReactNode
}

const ConditionalWrapper: React.FC<ConditionalWrapperProps> = ({ condition, wrapper, children }) => (condition ? wrapper(children) : <>{children}</>)

export default ConditionalWrapper