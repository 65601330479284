import * as React from "react"

type ScriptLazyLoad = "async" | "defer" | "none";

type AdditionalAttributes = {
    [key: string]: string;
}

export const useScript = (url: string, additionalAttrs: AdditionalAttributes = {}, lazyLoad: ScriptLazyLoad = "async"): void => {
    React.useEffect(() => {
        if (url && url.length > 0) {
            const script: HTMLScriptElement = document.createElement('script');

            if (lazyLoad == "async") {
                script.async = true;
            } else if (lazyLoad == "defer") {
                script.defer = true;
            }

            script.src = url;

            for (const key of Object.keys(additionalAttrs)) {
                script.setAttribute(key, additionalAttrs[key])
            }

            document.body.appendChild(script);
            return () => {
                document.body?.removeChild(script);
            }
        }
    }, [url])
}