import React, { useState, useEffect, useLayoutEffect, useRef, useMemo } from "react"
import { observer } from "mobx-react"
import LazyLoad from "react-lazyload"
import { connectToAdServer } from "@skolimow/react-advertising"
import Cookies from "cookies-js";


import ConditionalWrapper from "../helpers/ConditionalWrapper"
import { useStores } from "../helpers/useStores"
import { useInterval } from "../helpers/useInterval"
import StickyBar from "../prebid/StickyBar"

const DEFAULT_SLOT_REFRESH_DELAY = 30000

const AdUnit = observer(({ id, activate, displaySlot, isReady, isCapped, isAnchor }) => {
    const didActivate = useRef(false)

    const setupAndActivateAdSlot = () => {
        if (!didActivate.current) {
            displaySlot(id)
            activate(id)
            didActivate.current = true;
        }
    }

    useLayoutEffect(() => {
        // jeśli anchor, to pomijamy - czekamy na event
        if (isAnchor) return;
        // defaultowy sposob aktywacji
        if (isReady && !isCapped) {
            setupAndActivateAdSlot()
        }
    }, [isReady, isCapped])

    useEffect(() => {
        //aktywacja na event - potrzebne dla anchora
        if (isAnchor) {
            window.addEventListener('stickybtf', setupAndActivateAdSlot)
        }
        return () => {
            if (isAnchor) {
                window.removeEventListener('stickybtf', setupAndActivateAdSlot)
            }
        }
    }, [setupAndActivateAdSlot])

    return <div id={id}></div>
})

const AdSlot = observer(({ id, AdsStore, ...props }) => {
    const [isCapped, setCapped] = useState(true) //zakładamy, ze slot jest na poczatku zcappowany
    const [gptPath, setGptPath] = useState(null)
    const counter = useRef(null)
    const timeoutRef = useRef(null)
    var smartIntervalRefresh = null;

    const refreshAdslot = () => {
        console.log('refreshAdslot', id, Date.now());
        const { pbjs = {}, googletag, AD_SLOTS = {} } = window;
        pbjs.que.push(() => {
            pbjs.requestBids({
                adUnitCodes: [id],
                bidsBackHandler: () => {
                    pbjs.setTargetingForGPTAsync([id]);
                    googletag.cmd.push(() => googletag.pubads().refresh([AD_SLOTS[id]]));
                },
            })
        });
    }

    // odpali sie tylko jezeli AdsStore.adConfig[id]?.smartInterval?.enabled === true, google zaleca delay powyzej 30 sekund - https://developers.google.com/publisher-tag/guides/control-ad-loading#best_practices
    const smartRefreshStart = () => {
        // console.log('smartRefreshStart', id, Date.now() / 1000);
        if (!smartIntervalRefresh) {
            // console.log('smartRefreshStart real', id, Date.now() / 1000, AdsStore.adConfig[id]?.smartInterval?.delay);
            smartIntervalRefresh = setInterval(
                refreshAdslot,
                AdsStore.adConfig[id]?.smartInterval?.delay || DEFAULT_SLOT_REFRESH_DELAY
            );
        }
    }
    const smartRefreshClear = () => {
        // console.log('smartRefreshClear', id, Date.now() / 1000);
        clearInterval(smartIntervalRefresh);
        smartIntervalRefresh = null;
    }

    useInterval(refreshAdslot, AdsStore.adConfig[id]?.interval?.enabled ? AdsStore.adConfig[id]?.interval.delay : null)

    useEffect(() => {
        const { googletag } = window;
        const onSlotVisibilityChanged = (e) => {
            const slotId = e.slot.getSlotElementId()
            if (slotId === id) {
                console.group('Visibility of slot', e.slot.getSlotElementId(), 'changed.');
                console.log('Visible area:', e.inViewPercentage + '%');
                console.groupEnd();

                if (e.inViewPercentage > 50) {
                    smartRefreshStart();
                } else {
                    smartRefreshClear();
                }
            }
        };

        // jeśli slot jest przypisany do jakiejś grupy SRA
        if (AdsStore.adConfig[id]?.sra?.enabled) {
            // dodajemy go do tej grupy w AdsStore
            AdsStore.pushAdslot(AdsStore.adConfig[id]?.sra?.groups[0], id)
            // porównujemy grupy, jeśli wszystkie sloty są zarejestrowane to wysyłamy request
            AdsStore.compareGroups()
        }

        if (AdsStore.adConfig[id]?.capping?.enabled && !AdsStore.adConfig[id]?.anchor && !AdsStore.adConfig[id]?.interstitial) { // anchor i interstitial będzie miał osobne handlowanie cappingu
            const cookie = Cookies.get(id);
            if (typeof cookie === "string") {
                console.log(`slot has been capped: ${id}`)
            } else {
                const d = new Date()
                d.setMinutes(d.getMinutes() + AdsStore.adConfig[id]?.capping?.time)

                if (!document.cookie.split(';').some((item) => item.trim().startsWith(id))) {
                    document.cookie = `${id}=true; path=/; expires=${d.toUTCString()}; secure; samesite=lax`;
                }

                setCapped(false)
            }
        } else {
            setCapped(false)
        }

        googletag.cmd.push(() => {
            if (AdsStore.adConfig[id]?.smartInterval?.enabled) {
                // jesli smart refresh jest wlaczony, dodajemy listener na zmiane visibility
                googletag.pubads().addEventListener('slotVisibilityChanged', onSlotVisibilityChanged);
            }
            googletag.pubads().addEventListener('slotRenderEnded', (event) => {
                const slotId = event.slot.getSlotElementId()
                if (slotId === id) {
                    setGptPath(event.slot.getAdUnitPath())
                }
            });
        })

        window.addEventListener(`prebid_${id}_refresh`, refreshAdslot)

        return () => {
            window.removeEventListener(`prebid_${id}_refresh`, refreshAdslot)
            smartRefreshClear();
            AdsStore.removeAdslot(AdsStore.adConfig[id]?.sra?.groups[0], id)
            AdsStore.compareGroups()

            googletag.cmd.push(() => {
                googletag.pubads().removeEventListener('slotVisibilityChanged', onSlotVisibilityChanged);
            });
        }
    }, [])

    return <div id={gptPath} className={"adslot__ad-content " + id}>
        {!!AdsStore.adConfig[id]?.sra?.enabled
            ? <>
                {AdsStore.availGroups.has(AdsStore.adConfig[id]?.sra?.groups[0])
                    ? <AdUnit id={id} isCapped={isCapped} isAnchor={AdsStore.adConfig[id]?.anchor} {...props} />
                    : null}
            </>
            : <AdUnit id={id} isCapped={isCapped} isAnchor={AdsStore.adConfig[id]?.anchor} {...props} />}
    </div>
})

const AdContainer = observer(({ id, ...props }) => {
    const { AdsStore } = useStores();
    return <>
        {props.isReady &&
            <ConditionalWrapper
                condition={AdsStore.adConfig[id]?.anchor}
                wrapper={children => <StickyBar id={id} AdsStore={AdsStore}>{children}</StickyBar>}>
                <ConditionalWrapper
                    condition={AdsStore.adConfig[id]?.lazy?.enabled}
                    wrapper={children => <LazyLoad offset={AdsStore.adConfig[id]?.lazy?.offset || 0} height={250} >{children}</LazyLoad>}>
                    <AdSlot id={id} AdsStore={AdsStore} {...props} />
                </ConditionalWrapper>
            </ConditionalWrapper>
        }
    </>
})

export default connectToAdServer(AdContainer);