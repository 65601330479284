import React, { Component } from "react"
import LazyLoad from "react-lazyload"

import ConditionalWrapper from "../../helpers/ConditionalWrapper"

const WithLazyLoad = ({ options = {}, ...props }) => {
    return (
        <ConditionalWrapper
            condition={!window.TESTING_MODE}
            wrapper={children => <LazyLoad {...options}>{children}</LazyLoad>}>
            {props.children}
        </ConditionalWrapper>
    )
}

const LazyLoadDecorator = (options = {}) =>
    function lazyload(WrappedComponent) {
        return class LazyLoadDecorated extends Component {
            render() {
                return (
                    <ConditionalWrapper
                        condition={!window.TESTING_MODE}
                        wrapper={children => <LazyLoad {...options}>{children}</LazyLoad>}>
                        <WrappedComponent {...this.props} />
                    </ConditionalWrapper>
                );
            }
        };
    };


export { LazyLoadDecorator as withlazyload }
export default WithLazyLoad