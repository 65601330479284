import * as React from "react"

export const useTimeout = (callback: () => void, delay: number | null | false) => {
    const savedCallback = React.useRef(() => { });

    React.useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    React.useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay === null || delay === false) return undefined;
        let id = setTimeout(tick, delay);
        return () => clearTimeout(id);
    }, [delay]);
};