import React, { Suspense, useState } from "react";
import { styled } from "../stitches.config";
import { h3, h4Strong, bodyLarge, body } from "./commonStyles/Fonts";
import AuthorLinkIcon from "./assets/icons/author-link-icon.svg";
import Avatar from "./atoms/Avatar";
import SubscribeButton from "./SubscribeButton";
import CopyButton from "./components/CopyButton";
import Link from "../components/Link";
import { DISABLE_PREMIUM_FLAG_ACTIVE } from "../components/common/constants/constants"

import { AuthorType } from "../stores/ArticleStore"

const StarButton = React.lazy(() => import(/* webpackChunkName: "StarButton" */"./StarButton"));

interface AuthorProps {
  authorSize?: "extrasmall" | "small" | "big";
  border?: boolean;
  starButton?: boolean;
  subscribedAuthor?: boolean;
  isDashboard?: boolean;
  isPodcast?: boolean;
  onToggle?: () => void;
  isRedactor?: boolean;
  // article podział 
  author?: AuthorType;
  articleDate?: string;
  articleUpdateDate?: string;
  articleReadingTime?: string;
}

export const Author: React.FC<AuthorProps> = ({
  border = false,
  authorSize = "small",
  starButton = false,
  subscribedAuthor = false,
  isDashboard = false,
  isPodcast = false,
  isRedactor = false,
  onToggle,
  author,
  articleDate,
  articleUpdateDate,
  articleReadingTime,
}) => {

  const [showFirstArticleDate, setShowFirstArticleDate] = useState(false)

  if (isDashboard) {

    return (
      <StyledAuthor className="dashboardAuthor">
        <StyledSmallAuthorWrapper>
          {author?.img && (
            <Avatar src={author?.img} url={author?.url || ""} border={border} />
          )}
          <div>
            <p className={h4Strong()}>{author?.name}</p>
            {author?.profession || author?.articlesCount &&
              <div className={body()}>
                <span>{author?.profession}</span>
                {author?.articlesCount && (
                  <span>{author.articlesCount} artykułów</span>
                )}
              </div>
            }
          </div>
          {!DISABLE_PREMIUM_FLAG_ACTIVE &&
            <Suspense fallback={null}>
              <StarButton
                text=""
                forceActive
                active={subscribedAuthor}
                style={{ border: 'none' }}
                onToggle={onToggle} />
            </Suspense>
          }
        </StyledSmallAuthorWrapper>
      </StyledAuthor>
    );
  }

  return (
    <StyledAuthor className={isRedactor ? "redactor" : ""}>
      {authorSize === "big" && (
        <StyledAuthorWrapper className={isPodcast ? "podcastAuthor" : ""}>
          {author?.img && (
            <Avatar
              src={author?.img}
              url={author?.url || ""}
              border={border}
            />
          )}
          <StyledAuthorNameMobile>
            {" "}
            {author?.url ?
              <Link to={author?.url}><p className={h3()}>{author?.name}</p></Link>
              :
              <p className={h3()}>{author?.name}</p>
            }
            {author?.canFollow && !DISABLE_PREMIUM_FLAG_ACTIVE && !isRedactor && <SubscribeButton authorId={Number(author.OID)} text="Obserwuj" />}
          </StyledAuthorNameMobile>
          <div className="full-width">
            <StyledAuthorName>
              {" "}
              {author?.url ?
                <Link to={author?.url}><p className={h3()}>{author?.name}</p></Link>
                :
                <p className={h3()}>{author?.name}</p>
              }
              {author?.canFollow && !DISABLE_PREMIUM_FLAG_ACTIVE && !isRedactor && <SubscribeButton authorId={Number(author.OID)} text="Obserwuj" />}
            </StyledAuthorName>
            {author?.articleDescription && <p className="author-description">
              <span className={bodyLarge()}>{author?.articleDescription}</span>
            </p>}
            {author?.email && !isRedactor &&
              <div className={bodyLarge()}>
                <span className="author-copy-button">Napisz do mnie: <CopyButton text={author?.email} /></span>
              </div>
            }
            {isRedactor && author?.url &&
              <FindOutMore>
                <Link to={author?.url}>Dowiedz się o mnie więcej</Link>
              </FindOutMore>
            }
          </div>
        </StyledAuthorWrapper>
      )}
      {authorSize === "small" && (
        <StyledSmallAuthorWrapper className={isPodcast ? "podcastAuthor" : ""}>
          {author?.img && (
            <Avatar
              src={author?.img}
              url={author?.url || ""}
              border={border}
              size={authorSize}
            />
          )}
          <div>
            <p className={h4Strong()}>
              {author?.url ?
                <><Link to={author?.url}>{author?.name}</Link>{" "}</>
                :
                <NoLinkParagraph>{author?.name}</NoLinkParagraph>
              }
              {author?.canFollow && starButton && !DISABLE_PREMIUM_FLAG_ACTIVE && !isRedactor && (
                <span>
                  <SubscribeButton authorId={Number(author.OID)} />
                </span>
              )}
            </p>
            <div className={body()}>
              {articleUpdateDate && articleUpdateDate != articleDate ? <UpdatedDate onClick={() => setShowFirstArticleDate(!showFirstArticleDate)}>{articleUpdateDate}</UpdatedDate> : <span>{articleDate}</span>}
              <span className="dot">&#183;</span>
              {articleReadingTime && <span>{articleReadingTime} czytania</span>}
            </div>
            <FirstArticleDate className={showFirstArticleDate ? "first-article-date-visible" : ""}><span className={body()}>Publikacja artykułu: {articleDate}</span></FirstArticleDate>
          </div>
        </StyledSmallAuthorWrapper>
      )}
    </StyledAuthor>
  );
};

const StyledAuthor = styled("div", {
  "& .author-copy": {
    marginLeft: "8px",
    width: "17px",
    height: "17px",
    backgroundImage: `url(${AuthorLinkIcon})`,
    backgroundSize: "cover",
    backgroundColor: "$grayscale0",
    borderWidth: "0",
  },
  "&.dashboardAuthor": {
    border: "1px solid $grayscale25",
    padding: "16px 12px",
    "& button": {
      marginLeft: "auto",
    },
    "@bp4": {
      display: "flex",
      alignItems: "center",
    }
  },
  "&.redactor": {
    margin: "16px 0",
  },
  "& .author-description": {
    margin: "25px 0 8px 0",
    "@bp4": {
      margin: "0 150px 8px 0",
    },
  },
  "& .author-copy-button": {
    "@bp3": {
      display: "flex",
    },
    "& svg": {
      "& path": {
        fill: "$grayscale75",
      }
    },
    "& div": {
      textDecoration: "underline",
      "@bp3": {
        marginLeft: "4px",
      },
    },
  },
  "& span": {
    color: "$grayscale75",
  },
  "& p": {
    margin: "0 0 8px 0",
    color: "$grayscale100",
    "a, a:link, a:visited": {
      color: "$grayscale100",
    }
  },
  "& .avatar": {
    flexShrink: 0,
    marginRight: "24px",
  },
});

const StyledAuthorWrapper = styled("div", {
  display: "flex",
  flexWrap: "wrap",
  background: "$grayscale0",
  padding: "32px 16px",
  "@bp4": {
    flexWrap: "nowrap",
    padding: "32px",
  },
  "& .avatar": {
    width: "64px",
    height: "64px",
    "@bp4": {
      width: "104px",
      height: "104px",
    },
  },
  ".full-width": {
    width: "100%",
  },
  "a": {
    "&:hover": {
      textDecorationColor: "$grayscale100"
    }
  }
});

const StyledSmallAuthorWrapper = styled("div", {
  display: "flex",
  alignItems: "center",
  // border potrzebny w dashbordzie, trzeba zrobić warunek
  //border: "1px solid $grayscale10",
  //padding: "12px 16px",
  "& span": {
    marginRight: "6px",
  },
  "& .dot": {
    color: "$grayscale50",
  },
  "& .first-article-date-visible": {
    display: "block !important",
  },
  "& p": {
    margin: "0",
  }
});

const StyledAuthorName = styled("div", {
  display: "none",
  "@bp4": {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
  },
});

const StyledAuthorNameMobile = styled("div", {
  display: "flex",
  alignItems: "flex-start",
  flexDirection: "column",
  justifyContent: "center",

  "@bp4": {
    display: "none",
  },
});

const NoLinkParagraph = styled("span", {
  color: "$grayscale100 !important",
  marginRight: "0px !important",
});

const FindOutMore = styled("p", {
  "& a": {
    color: "$primary !important",
    fontWeight: 600,
    textDecoration: "underline !important",
    fontSize: "16px",
    lineHeight: "24px",
    marginTop: "16px",
    "@bp4": {
      marginTop: "auto",
    },
    "&:hover": {
      color: "$primary !important",
      textDecoration: "underline !important",
    }
  },
});

const UpdatedDate = styled("button", {
  border: "0px",
  background: "transparent",
  cursor: "pointer",
  color: "$grayscale75",
  padding: "0px",
  marginRight: "6px",
  "&:hover": {
    textDecoration: "underline",
  }
});

const FirstArticleDate = styled("span", {
  color: "$grayscale75 !important",
  display: "none",
});

export default Author;
