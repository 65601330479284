import React, { createContext, useContext, useEffect, useState, useRef, useMemo, PropsWithChildren } from 'react';
import axios from "axios";

import PodcastPlayerNav from '../redesign/components/PodcastPlayer/PodcastPlayerNav';
import { useNavigate } from "react-router-dom";

interface IOnnTvData {
    iid: string;
    sender: string;
    comm: 'playerLoaded' | 'plstarttry' | 'player_videoloaded' | 'moviecleared' | 'movieset' | 'moviestarted' | 'moviecleared' | 'movieresumed' | 'moviesetdelayed' | 'moviestopped' | 'moviepaused' | 'movie_playstart' | 'moviebuffering' | 'movie_progress' | 'moviecompleted' | 'movieseeking' | 'volumeset' | 'adnotfoundinblock' | 'adblockfplayed' | 'adnotpresent' | 'adprogress';
    subject?: number;
    videoId: string;
    videoDuration: number;
    videoTitle: string;
    videoPoster: string;
    volume: number;
    containerId: string;
};

interface PlayerIid {
    [key: string]: IPodcastPlayerData | null;
}

interface IPodcastPoster {
    img?: string;
    img_scheme?: string;
    media?: any;
}

interface IPodcastHistory {
    episode_id: number;
    video_id: number;
}

export interface IPodcastPlayerData {
    podcast_article?: string;
    duration?: number;
    progress?: number;
    podcastPoster?: IPodcastPoster;
    podcastTitle?: string;
    podcastSeries?: string;
    isCompleted?: boolean;
    nextEpisode?: IPodcastHistory | null;
    prevEpisode?: IPodcastHistory | null;
}

export interface IPodcastPlayerContext {
    actualVideo: React.MutableRefObject<string | undefined>;
    isPlay: boolean;
    isCompleted: boolean;
    isAdPlay: boolean;
    progress: number;
    volume: number;
    newMedia?: string;
    isVideoLoading: boolean;
    isPlayerInit: boolean;
    play: (videoId?: string, progress?: number) => void;
    pause: () => void;
    seek: (progress: number, sid?: string) => void;
    setMedia: (sid?: string) => void;
    setVolume: (volume: number) => void;
    setEpisode: (episode: IPodcastHistory) => void;
};

export type PodcastContextVariableType = keyof IPodcastPlayerContext;

const PodcastPlayerContext = createContext<IPodcastPlayerContext>({
    actualVideo: {} as React.MutableRefObject<string | undefined>,
    isPlay: false,
    isCompleted: false,
    isAdPlay: false,
    progress: 0,
    volume: parseFloat(window.localStorage.getItem('podcastVolume') || '1'),
    newMedia: undefined,
    isVideoLoading: true,
    isPlayerInit: false,
    play: () => { },
    pause: () => { },
    seek: (progress: number, sid?: string) => { },
    setMedia: (videoId?: string) => { },
    setVolume: (volume: number) => { },
    setEpisode: (episode: IPodcastHistory) => { }
});

// Save state from context variable, and change only for passed sid
export function usePlayerState<T>(variableName: PodcastContextVariableType, podcastContext: IPodcastPlayerContext, videoId?: string) {
    const variable = podcastContext[variableName];
    const [state, setState] = useState<typeof variable | null>(null);

    useEffect(() => {
        if (!videoId) return;

        if (podcastContext.actualVideo.current != videoId) {
            switch (variableName) {
                case 'isVideoLoading':
                case 'isAdPlay':
                    setState(false);
                    break;
            }
        } else
            setState(variable);
    }, [variable, videoId, podcastContext.newMedia]);

    return state as unknown as T;
}

// Use PodcastPlayerContext
export const usePodcastPlayer = (): IPodcastPlayerContext => {
    const context = useContext(PodcastPlayerContext);
    if (context === undefined) {
        throw new Error("usePodcastPlayer must be used within PodcastPlayerContext");
    }
    return context;
};

// Load Player via sid, and get status of init
export const usePlayerInit = (podcast_article: string): [string | undefined, boolean] => {
    const { newMedia } = useContext(PodcastPlayerContext);

    //Load podcast on component create
    const [videoId, setVideoId] = useState<string | undefined>(undefined);
    const [init, setInit] = useState<boolean>(false);

    useEffect(() => {
        loadPodcastArticle(podcast_article).then(videoId => {
            setVideoId(videoId);
            setInit(true);
        });
    }, []);

    return [videoId, init];
};

// Map saves informations about players (videoId -> playerData)
export const PODCASTS_PLAYERS: PlayerIid = {};

// Load podcast article data
const loadPodcastArticle = async (podcast_article: string) => {
    for (let videoId in PODCASTS_PLAYERS)
        if (PODCASTS_PLAYERS[videoId]?.podcast_article == podcast_article) {
            PODCASTS_PLAYERS[videoId] = {
                ...PODCASTS_PLAYERS[videoId]
            };
            return videoId;
        }

    const { data } = await axios.get(`${window.API_HOST}/pwa-podcast/${podcast_article}`);
    const podcastData = data.podcast;

    //Decode SID
    const videoId = podcastData.videoId;

    PODCASTS_PLAYERS[videoId] = {
        ...PODCASTS_PLAYERS[videoId],
        podcast_article,
        duration: podcastData.duration,
        podcastTitle: podcastData.episode_title,
        podcastPoster: podcastData.episode_poster,
        podcastSeries: podcastData.episode_series,
        nextEpisode: podcastData.next_episode,
        prevEpisode: podcastData.prev_episode,
    };

    addPlayerScript(podcastData.sid);

    return videoId;
}

// Add player scripts
const addPlayerScript = (playerSid: string) => {
    //Add player just once
    if (document.getElementById('podcast_script'))
        return;

    const playerContainer: HTMLDivElement = document.createElement('div');
    playerContainer.id = `podcast_player`;
    playerContainer.style.cssText = 'display: none;';
    document.body.appendChild(playerContainer);

    const script: HTMLScriptElement = document.createElement('script');
    script.id = `podcast_script`;
    script.async = true;
    script.src = `https://video.onnetwork.tv/embed.php?sid=${playerSid}&cId=podcast_player&&mplon=${1}&autoplay=${0}&iid=${111}`;
    document.body.appendChild(script);
}

// PodcastPlayerContext provider
const PodcastPlayerProvider: React.FC<PropsWithChildren<React.ReactNode>> = ({ children }) => {

    const navigate = useNavigate();

    const actualVideo = useRef<string | undefined>(undefined);
    const seekInto = useRef<{ videoId: string, progress: number }>({ videoId: '', progress: -1 });

    //Just for state update
    const updateVideoTimeout = useRef<NodeJS.Timeout>();

    const [isVideoLoading, setVideoLoading] = useState<boolean>(false);
    const [isPlayerInit, setPlayerInit] = useState<boolean>(false);

    const [newMedia, setNewMedia] = useState<string | undefined>(undefined);

    const [isPlay, setPlay] = useState<boolean>(false);
    const [isCompleted, setCompleted] = useState<boolean>(false);
    const [isAdPlay, setAdPlay] = useState<boolean>(false);

    const [progress, setAudioProgress] = useState<number>(0);
    const [volume, setCurrentVolume] = useState<number>(parseFloat(window.localStorage.getItem('podcastVolume') || '1'));

    const isNavOpen = useMemo(() => actualVideo.current || isPlay, [isPlay, newMedia]);

    //Dynamic update podcastValue in localStorage
    useEffect(() => window.localStorage.setItem('podcastVolume', String(volume)), [volume]);

    //Update player volume on play or volume change
    useEffect(() => {
        if (!isPlay) return;
        sendMediaCommand('setvolume', String(Math.min(Math.max(volume, 0), 1)));
    }, [isPlay, volume, isPlayerInit]);

    const sendMediaCommand = (message: string, value?: string) => {
        try {
            // Ingore cause we dont know ONTVsendCommand type
            // @ts-ignore
            window.ONTVsendCommand(message, '111', value);
        } catch { }
    }

    // Get metadata from player
    const receiveMediaInfo = (e: MessageEvent<any>) => {

        //Check if message is from onnetwork player
        if (e.origin !== 'https://video.onnetwork.tv')
            return;
        if (typeof e.data !== 'string')
            return;
        if (!e.data.startsWith('onntv://'))
            return;

        //Parse onnetwork message
        try {
            const data: IOnnTvData = JSON.parse(e.data.substr(8));

            //console.log('[ES] podcast', data);

            if (data.iid !== '111') return;

            if (data.comm == 'movieset') {
                setVideoLoading(true);
                PODCASTS_PLAYERS[data.videoId] = {
                    ...PODCASTS_PLAYERS[data.videoId],
                    duration: data.videoDuration,
                    progress: 0,
                };
                setNewMedia(data.videoId);
            }

            switch (data.comm) {
                case 'plstarttry':
                    setPlayerInit(true);
                    if (actualVideo.current) {
                        const isCompleted = PODCASTS_PLAYERS[actualVideo.current]?.isCompleted ?? false;
                        if (!isCompleted)
                            play(actualVideo.current);
                    }
                    break;

                case 'movie_playstart':
                    //Wait for first seek on play
                    if (seekInto.current.videoId == data.videoId) {
                        seek(seekInto.current.progress, data.videoId);
                    }

                case 'moviecleared':
                case 'moviebuffering':
                    setVideoLoading(true);
                    break;

                case 'player_videoloaded':
                    sendMediaCommand('play');
                    break;

                case 'moviestarted':
                case 'movieresumed':
                    actualVideo.current = data.videoId;
                    setCompleted(false);
                    setPlay(true);
                    break;

                case 'moviestopped':
                case 'moviepaused':
                    setPlay(false);
                    break;

                case 'movie_progress':
                    setAdPlay(false);

                    //Wait for first seek on play
                    if (seekInto.current.videoId == data.videoId && data.subject != seekInto.current.progress) {
                        break;
                    }
                    setAudioProgress(data.subject || 0);
                    if (data.subject && data.subject > 0)
                        setVideoLoading(false);

                    PODCASTS_PLAYERS[data.videoId] = {
                        ...PODCASTS_PLAYERS[data.videoId],
                        progress: data.subject || 0,
                        isCompleted: false
                    };
                    break;

                case 'moviesetdelayed':
                    setVideoLoading(false);
                    setPlayerInit(true);
                    break;

                case 'moviecompleted':
                    PODCASTS_PLAYERS[data.videoId] = {
                        ...PODCASTS_PLAYERS[data.videoId],
                        isCompleted: true
                    };
                    setPlay(false);
                    setCompleted(true);
                    break;

                case 'adnotfoundinblock':
                case 'adblockfplayed':
                case 'adnotpresent':
                    setAdPlay(false);
                    break;

                case 'adprogress':
                    setAdPlay(true);
                    break;
            }

        } catch (error) {
            console.log('PodcastPlayer: ' + error);
        }
    };

    useEffect(() => { setNewMedia(undefined) }, [newMedia]);

    // Wait for messages from player
    useEffect(() => {
        window.addEventListener('message', receiveMediaInfo);
        return () => window.removeEventListener('message', receiveMediaInfo);
    }, []);

    //Play podcast
    const play = (videoId?: string, playerProgress?: number) => {
        if (isCompleted && ((videoId && actualVideo.current == videoId) || !videoId)) {
            setMedia(actualVideo.current);
        } else if (videoId && videoId != actualVideo.current) {
            setMedia(videoId);
            if (playerProgress && videoId) {
                seekInto.current = {
                    videoId: videoId,
                    progress: playerProgress
                };
            }
        } else {
            sendMediaCommand('play');
        }
        sendMediaCommand('setvolume', String(Math.min(Math.max(volume, 0), 1)));
    }

    //Pause podcast
    const pause = () => {
        sendMediaCommand('pause');
    }

    //Go to ..
    const seek = (playerProgress: number, videoId?: string) => {
        seekInto.current = {
            videoId: '',
            progress: -1
        };

        if (actualVideo.current == videoId) {
            sendMediaCommand('seek', String(playerProgress));
        } else {
            play(videoId, playerProgress);
        }
    }

    //Set podcast
    const setMedia = (videoId?: string) => {
        pause();
        setPlay(false);
        setCompleted(false);
        setVideoLoading(false);
        setAdPlay(false);
        //Wait for state update
        if (updateVideoTimeout.current) {
            clearTimeout(updateVideoTimeout.current);
        }

        updateVideoTimeout.current = setTimeout(() => {
            actualVideo.current = videoId;
            if (videoId) {
                sendMediaCommand('loadvideo', videoId);
                setPlay(true);
            } else {
                setNewMedia('empty');
                setAudioProgress(0);
            }
        }, 100);
    }

    //Set volume
    const setVolume = (volume: number) => {
        setCurrentVolume(volume);
    }

    //Change episode
    const setEpisode = (episode: IPodcastHistory) => {
        const { video_id, episode_id } = episode;
        if (!PODCASTS_PLAYERS.hasOwnProperty(video_id) || !isPlayerInit) {
            if (!isPlayerInit) {
                actualVideo.current = video_id + '';
                setPlay(true);
            }

            loadPodcastArticle(episode_id + '').then((videoId) => {
                if (isPlayerInit)
                    play(video_id + '', 0);
            });
        } else {
            play(video_id + '');
        }
    }

    return (
        <PodcastPlayerContext.Provider value={{
            actualVideo, isPlay, isCompleted, isAdPlay, progress, volume,
            play, pause, seek, setMedia, setVolume,
            newMedia, setEpisode,
            isVideoLoading, isPlayerInit,
        }}>
            {children}
            {actualVideo.current && isNavOpen && <PodcastPlayerNav videoId={actualVideo.current} />}
        </PodcastPlayerContext.Provider>
    );

};

export default PodcastPlayerProvider;

export const formatTime = (time: number, showHours?: boolean): string => {

    let parts: number[] | string[] = [time, 0, 0];

    for (let i = 1; i < parts.length; i++) {
        parts[i] += parts[i - 1] / 60;
        parts[i - 1] %= 60;
    }

    parts = parts.reverse().map(p => String(Math.floor(p)).padStart(2, '0'));

    if (!showHours && parts[0])
        parts.shift();

    return parts.join(':');

}