import React from "react";
import { styled } from "../../stitches.config";


import Link from "../Link";

const ArticleLinks = props => {
  if (!props.show || props.show == 'off' || !props.links || props.links.length == 0) {
    return null;
  }

  return (
    <BoxWrapper>
      <Title>Powiązane tematy</Title>
      <ListContainer>
        {props.links.map(art => {
          if (art.url && art.url.length > 0 && art.description && art.description.length > 0) {
            return (
              <ListElement>
                <Link to={art.url}>
                  <ArticleTitle>{art.description}</ArticleTitle>
                  <ArticleDate>{art.date}</ArticleDate>
                </Link>
              </ListElement>
            );
          }
        })}
      </ListContainer>
    </BoxWrapper>
  );
};

const BoxWrapper = styled("div", {
  marginTop: "20px",
  pointerEvents: "all"
});

const Title = styled("div", {
  fontWeight: 500,
  fontSize: "15px",
  paddingBottom: "8px",
  color: "white"
});

const ArticleTitle = styled("div", {
  fontSize: "15px"
});

const ArticleDate = styled("div", {
  fontSize: "10px",
  color: "#69707F",
  paddingTop: "6px"
});

const ListContainer = styled("div", {});

const ListElement = styled("div", {
  display: "block",
  marginBottom: "20px",
  "@media (min-width: 992px)": {
    display: "inline-block",
    width: "175px",
    marginRight: "20px"
  },
  "> a": {
    display: "block",
    height: "100px",
    padding: "13px 16px",
    background: "white",
    borderRadius: "3px",
    textDecoration: "none",
    lineHeight: "18px",
    color: "$primary",
    "@media (min-width: 992px)": {
      opacity: 0.45,
      "&:hover": {
        opacity: 1
      }
    }
  }
});

export default ArticleLinks;
