import { observable, action, computed } from "mobx";


import { pushDataLayer } from "../helpers/pushDataLayer"

class CategoryStore {
    constructor(RootStore) {
        this.RootStore = RootStore;
    }
    @observable status = null;
    message = null;

    fetchedTopic = { topic: { type: "natemat" } };
    currentSlug = null;

    @computed
    get blocks() {
        if (this.fetchedTopic)
            return this.fetchedTopic.category.last_posts;
        else
            return [];
    }

    @computed
    get categoryName() {
        if (this.fetchedCategory)
            return this.fetchedTopic.topic.name;
        else
            return "";
    }

    @action('Change store status')
    setStatus = (status, message = null) => {
        this.message = message;
        this.status = status;
    }

    @action("Display Topic")
    displayTopic = topic => {
        const { updateGoogleAdKeywords } = this.RootStore.UIStore;
        updateGoogleAdKeywords(topic?.page_parameters?.adKeywords)
        this.fetchedTopic = topic;
        this.setStatus("valid");
    };

    @action("Get category based on current URL")
    updateSlug = (slug = "") => {
        if (this.currentSlug !== slug) {
            // prawdopodobnie "wstecz", brak aktualizacji
            this.currentSlug = slug;
            this.setStatus("loading");
        }
        this._fetchFromNetwork(slug, true);
    };

    parseResponse = (res, slug) => {
        pushDataLayer({
            'event': 'virtualPageview',
            'virtualPageURL': `/${res.topic.url}`,
            'virtualPageTitle': res.topic.seo_title,
            'pageType': "Pozostałe"
        })
        this.displayTopic(res);
        // this._saveToDb(response, slug);
    }

    _fetchFromNetwork = (slug, justUpdate = false) => {
        const getParams = (window.location.search || '?');
        const that = this;
        const fetchUri = `/pwa-topic/${slug}${getParams}`;
        const fetchUrl = `${window.API_HOST}${fetchUri}`;

        if (window?.APP_SETTINGS?.pwa_preload && window?.APP_SETTINGS?.pwa_preload[fetchUri]) {
            console.log('[ES] pwa-topic z pwa_preload');
            this.parseResponse(window?.APP_SETTINGS?.pwa_preload[fetchUri], slug);
        } else {
            fetch(fetchUrl)
                .then(response => {
                    return response.ok
                        ? response.json()
                        : Promise.reject(response.status);
                })
                .then(res => {
                    this.parseResponse(res, slug);
                })
                .catch(err => {
                    window.APP_TRACK_ERROR("TopicStore-fetchFromNetwork", { url: fetchUrl, error: err });

                    if (err === 404) {
                        that.setStatus("error", "Nie znaleziono takiej strony");
                        return;
                    } else if (justUpdate) {
                        return;
                    } else if (!window.navigator.onLine) {
                        that.setStatus("error", "Brak połączenia z internetem. Nadal możesz korzystać z aplikacji, choć część treści, tak jak ta, będzie niedostępna");
                        return;
                    } else {
                        that.setStatus("error", "Ups! Wystąpił problem podczas pobierania strony. Sprawdź połączenie z internetem i spróbuj ponownie");
                        return;
                    }
                });
        }
    };
}

export default CategoryStore;
