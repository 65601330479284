import React from "react";
import App from "./App";
import { Provider } from "mobx-react";

import RootStore from "./stores/RootStore";

import { DeepViewChecker } from "./helpers/DeepViewChecker";

import NoSSR from "./helpers/NoSSR";

const stores = {
  RootStore: RootStore,
  ArticleStore: RootStore.ArticleStore,
  CategoryStore: RootStore.CategoryStore,
  UIStore: RootStore.UIStore,
  ReadLaterStore: RootStore.ReadLaterStore,
  TopicStore: RootStore.TopicStore,
  UserStore: RootStore.UserStore,
  AdsStore: RootStore.AdsStore,
  PodcastStore: RootStore.PodcastStore
};

const AppEntry = () => (
  <div className="body-v2">
    <Provider {...stores}>
      <NoSSR fallback={null}>
        <DeepViewChecker />
      </NoSSR>
      <App />
    </Provider>
  </div>
);

export default AppEntry; 