import React from "react";
import * as Sentry from "@sentry/react";

export default ({ children }) => {

    return (
        <Sentry.ErrorBoundary fallback={<></>}>
            {children}
        </Sentry.ErrorBoundary>
    )

}