export enum PaymentParams {
  PAYMENT_TYPE = "payment-type",
  PAYMENT_STATUS = "payment-status",
  PAYMENT_EDITOR_ID = "payment-editor-id",
  PAYMENT_ARTICLE_ID = "payment-article-id",
  PAYMENT_BRID_ID = "payment-brid-id",
  PAYMENT_VALUE = "payment-value",
  PAYMENT_ORIGIN = "payment-origin",
}

export enum PaymentStatus {
  SUCCESS = "success",
  FAILED = "failed",
  CANCELLED = "cancelled",
  PENDING = "pending",
  WAITING = "waiting",
  VALUE = "0"
};

export type PaymentPayload = {
  email: string;
  amount: number;
  termsAndCondition: boolean;
  message?: string;
  blikCode?: string;
  blikPay: boolean;
  origin: string;
};

export enum PaymentType {
  TIP = "TIP",
  BRID = "BRID",
  PREMIUM = "PREMIUM",
}