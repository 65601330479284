import React, { memo } from 'react';
interface IRenderWrapper<P extends {}> {
    component: React.ComponentType<P>;
    props?: P;
    preventRerender?: boolean | ((prevProps: P, nextProps: P) => boolean);
};

function Wrapper<P extends {}>({ component, props }: IRenderWrapper<P>) {
    return React.createElement(component, props);
};

const RenderWrapper = memo(Wrapper, (prevProps, nextProps) => {
    //Force pass that props are equal, to prevent rerender
    if (typeof nextProps.preventRerender === "boolean") {
        return nextProps.preventRerender;
    } else if (nextProps.props && prevProps.props && nextProps.preventRerender) {
        return nextProps.preventRerender(nextProps.props, prevProps.props);
    }

    //Default rerender check
    return prevProps.props === nextProps.props;
});

export default RenderWrapper as typeof Wrapper;