/* #7707 Animowany helikopter */
import React, { useState } from "react";
import { createPortal } from "react-dom";
import { styled, css, keyframes } from "../../stitches.config"
import { useEffect } from "react";

import helicopterSmallImage from "./helikopter_small.gif";
import helicopterImage from "./helikopter.gif";

const SCREENING_BACKGROUND_URL = "https://static.grupanatemat.pl/Kampanie/2024/lipiec/2226_2303_Netflix/nowe/Wallpaper/wallpaper.jpg";

const NetflixAxelFAction: React.FC = () => {

    //Wait for helicopter image loading
    const [isHelicopterReady, setHelicopterReady] = useState(false);

    //Wait for right screening to display
    const [visible, setVisibility] = useState(false);
    useEffect(() => {
        const screeningActive = () => {
            //Wait for screening image then show animation
            const screeningImage = new Image();
            screeningImage.onload = () => {
                setVisibility(true);
            };

            screeningImage.src = SCREENING_BACKGROUND_URL;
        };

        const screeningObserver = new MutationObserver(() => {
            const screeningBackground = window.getComputedStyle(document.body).background;

            if (screeningBackground.includes(SCREENING_BACKGROUND_URL)) {
                screeningActive();
                screeningObserver.disconnect();
            }
        });
        screeningObserver.observe(document.body, { attributes: true });

        return () => {
            screeningObserver.disconnect();
        }

    }, []);

    //Add container to body
    const [placementContainer, setPlacementContainer] = useState<HTMLDivElement | null>(null);
    useEffect(() => {
        if (!visible)
            return;

        const placementContainer = document.createElement('div');
        placementContainer.className = AnimationContainerStyle();

        document.body.appendChild(placementContainer);
        setPlacementContainer(placementContainer);

        return () => {
            document.body.removeChild(placementContainer);
            setPlacementContainer(null);
        }
    }, [visible]);

    //Scale animation
    useEffect(() => {
        if (!placementContainer)
            return;

        const resizeOberser = new ResizeObserver(([entry]) => {
            const containerWidth = entry.contentRect.width;
            placementContainer.style.setProperty("--containerScale", `${containerWidth / 1920}`);
        });
        resizeOberser.observe(placementContainer);

        return () => {
            resizeOberser.disconnect();
        };
    }, [placementContainer]);

    if (!placementContainer)
        return null;

    return createPortal((
        <AnimationCanvas show={isHelicopterReady}>
            <AnimationHelicopterWraper>
                <AnimationHelicopter
                    srcSet={`${helicopterSmallImage} 360w, ${helicopterImage} 996w`}
                    sizes={`(max-width: 995px) 360px, 996px`}
                    src={helicopterImage}
                    onLoad={() => setTimeout(() => setHelicopterReady(true), 2000)}
                />
            </AnimationHelicopterWraper>
        </AnimationCanvas>
    ), placementContainer);

};

export default NetflixAxelFAction;

const AnimationContainerStyle = css({
    position: "fixed",
    inset: 0,
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    pointerEvents: "none",
    zIndex: "10000",
    $containerScale: "1"
});

const AnimationHelicopterWrapperKeyframes = keyframes({
    "0%": {
        transform: "translate(0px, -706px)"
    },
    "10%": {
        transform: "translate(-100px, -700px)"
    },
    "25%": {
        transform: "translate(-300px, -750px)"
    },
    "60%": {
        transform: "translate(-843px, -598px)"
    },
    "100%": {
        transform: "translate(-2935px, -1606px)"
    },
});

const AnimationHelicopterKeyframes = keyframes({
    "0%": {
        transform: "translateX(0)"
    },
    "100%": {
        transform: "translatex(10%)"
    },
});

const AnimationHelicopterWraper = styled('div', {
    width: "1015px",
    height: "706px",
    position: "absolute",
    right: "-1015px",
    bottom: "-706px",
    transformOrigin: "calc(-1015px / 2) calc(-706px / 2)",

});

const AnimationHelicopter = styled('img', {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    objectPosition: "center",
});

const AnimationCanvas = styled('div', {
    width: "1920px",
    height: "900px",
    position: "relative",
    zoom: "var(--containerScale)",
    variants: {
        show: {
            true: {
                [`& ${AnimationHelicopterWraper}`]: {
                    animation: `${AnimationHelicopterWrapperKeyframes} linear 4s`,
                },
                [`& ${AnimationHelicopter}`]: {
                    animation: `${AnimationHelicopterKeyframes} alternate infinite ease-in-out 1s`,
                }
            },
            false: {
                visibility: "hidden"
            }
        }
    }
});
