import * as React from "react"
import { styled } from "../../../../stitches.config"

import { AnyElement, ChildArray, Child } from "./types"
import Link from "../../../Link"
import { toJS } from "mobx"

type StyledMarkProps = Omit<Child, "text">

type RenderSlateChildrenProps = {
    element: ChildArray
}

const RenderSlateChildren: React.FC<RenderSlateChildrenProps> = ({
    element
}) => {
    return <>
        {element.map(el => {
            if ("type" in el) {
                // @ts-ignore
                return <>{el.type === "p" ?
                    <RenderSlateChildren element={el.children} /> :
                    <Link to={el.url} disableNofollow={el.disableNofollow} data-analytics-name="in-text-link">
                        <RenderSlateChildren element={el.children} />
                    </Link>
                }
                </>
            } else {
                if (el.superscript) {
                    return <sup>{el.text}</sup>
                }
                if (el.subscript) {
                    return <sub>{el.text}</sub>
                }
                if (el.text !== "") {
                    if (["bold", "italic", "underline", "strikethrough"].some(mark => Object.keys(el).includes(mark))) {
                        return <Mark
                            bold={el.bold}
                            italic={el.italic}
                            underline={el.underline}
                            strikethrough={el.strikethrough}
                        >
                            {el.text}
                        </Mark>
                    } else {
                        return <>{el.text}</>
                    }
                }
            }
        })}
    </>
}

const Mark = styled("span",{
    fontWeight: "lighter",
    fontStyle: "normal",
    variants: {
        "bold": {
            true: {
                fontWeight: 700
            },
            false: {}
        },
        "italic": {
            true: {
                fontStyle: "italic"
            },
            false: {}
        },
        "underline": {
            true: {
                textDecoration: "underline"
            },
            false: {}
        },
        "strikethrough": {
            true: {
                textDecoration: "line-through"
            },
            false: {}
        }
    },
    compoundVariants: [
        {
            "underline": true,
            "strikethrough": true,
            css: {
                textDecoration: "underline line-through"
            }
        }
    ]
});

export default RenderSlateChildren