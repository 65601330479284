import React from 'react';
import { styled } from "../stitches.config"
import { h4 } from "./commonStyles/Fonts";
import { p } from "./commonStyles/Fonts";

interface LinkProps {
    text: string,
    url: string,
    size?: "big" | "normal" | "small" | "monochromatic";
}

const Link: React.FC<LinkProps> = ({
    text,
    url,
    size = "normal",
    ...props
}) => {
    return (
        <StyledLink href={url} size={size} className={h4()}>
            {text}
        </StyledLink>
    );
};


const StyledLink = styled('a', {
    textDecoration: "underline",
    color: "$primary",
    variants: {
        size: {
            big: {
                color: "red",
                fontSize: "18px !important",
                lineHeight: "24px !important",
            },
            normal: {
                fontSize: "16px !important",
                lineHeight: "24px !important",
            },
            small: {
                fontSize: "14px !important",
                lineHeight: "18px !important",
            },
            monochromatic: {
                fontSize: "18px!important",
                lineHeight: "24px!important",
                color: "$grayscale0 !important",
            }
        },
    }
})


export default Link