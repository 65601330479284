import React from "react";

import { Link, useLocation } from "react-router-dom";
import getCamelizeValue from "../../helpers/getCamelizeValue";
import MODAL_CONFIG, { ModalFields } from "./config";

type Props = ModalFields & React.HTMLAttributes<{}>;

const ModalLink: React.FC<Props> = (props) => {
  const location = useLocation();

  const getPaymentHref = () => {
    const params: string[] = [];
    MODAL_CONFIG[props.type].params.forEach((param) => {
      const camelizeParam = getCamelizeValue(param);
      // @ts-ignore
      const value = props[camelizeParam];
      if (value !== undefined) {
        params.push(`${param}=${Array.isArray(value) ? value.join(",") : value}`);
      }
    });

    return `${location.pathname}?modal-type=${props.type}&${params.join("&")}`;
  };

  return (
    <Link to={getPaymentHref()} {...props}>
      {props.children}
    </Link>
  );
};

export default ModalLink;
