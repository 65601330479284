import React from 'react';

interface Props {
    onClick: () => void;
};

const PodcastNextEpisodeButton: React.FC<Props> = ({ onClick }) => {

    return (
        <button className="podcastPlayerNav__nextEpisode" onClick={onClick}>
            Odtwórz następny odcinek
        </button>
    );

}

export default PodcastNextEpisodeButton;