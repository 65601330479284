import React from "react";
import { styled } from "../../stitches.config";

const WrapperBalls = styled("div",{
  position: "absolute",
  top: "50%",
  left: "5.6px",
  transform: "translateY(-50%)"
});

const Ball = styled("div",{
  width: "4.8px",
  height: "4.8px",
  backgroundColor: "#fff",
  borderRadius: "50%",
  "&:first-child":{
    marginBottom: "2.8px"
  }
});

const Balls = () => (
  <WrapperBalls>
    <Ball />
    <Ball />
  </WrapperBalls>
);

export default Balls;
