import axios, { AxiosResponse } from "axios"

export type UserDeleteActionType = "DELETE_AFTER_EXPIRATION" | "DELETE_NOW";

export async function deleteUser(): Promise<UserDeleteActionType> {
    try {
        const res = await axios.post(`${window.API_HOST}/api/account/delete`);
        return res.data.action as UserDeleteActionType;
    } catch (err) {
        if (axios.isAxiosError(err)) {
            throw { status: err.response!.status, errors: err.response!.data.errors }
        } else {
            throw new Error('Cannot delete account.')
        }
    }
}