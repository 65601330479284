import React from "react";
import { styled } from "../stitches.config";
import { body, bodyLarge } from "./commonStyles/Fonts";
import Avatar from "./atoms/Avatar";
import Link from "../components/Link";

interface AllAuthorsAtavistProps {
    allAuthors: Array<{
        img?: string;
        name: string;
        url?: string,
        profession?: string,
    }>;
}

export const AllAuthorsAtavist: React.FC<AllAuthorsAtavistProps> = ({
    allAuthors,
}) => {
    return (
        <>
            {allAuthors.map((allAuthors) =>
                <AuthorAtavistWrapper className="author-atavist-wrapper">
                    <Link to={allAuthors?.url}>
                        {allAuthors?.img && (
                            <Avatar
                                src={allAuthors?.img}
                                url={allAuthors?.url || ""}
                                border={false}
                                size={"normal"}
                            />
                        )}
                        <p className={bodyLarge()}>{allAuthors?.name} </p>
                        <span className={body()}>{allAuthors?.profession} </span>
                    </Link>
                </AuthorAtavistWrapper>
            )}
        </>
    );
};



const AuthorAtavistWrapper = styled("div", {
    margin: "10px",
    width: "calc(50% - 20px)",
    "@bp4": {
        width: "auto",
        margin: 0,
    },
    "a": {
        textDecoration: "none",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
    },
    "& .avatar": {
        flexShrink: 0,
        marginRight: "0 !important",
        marginBottom: "16px",
        width: "72px",
        height: "72px",
        borderRadius: "72px",
        "@bp4": {
            width: "96px",
            height: "96px",
            borderRadius: "96px",
        },
    },
    "span": {
        color: "$grayscale75",
        textAlign: "center",
        "&:hover": {
            textDecoration: "none",
        }
    },
    "p": {
        color: "$grayscale100Fixed",
        textAlign: "center",
    }
});

export default AllAuthorsAtavist;
