import { observable, action, autorun, computed } from "mobx";

import { injectTestAdKeywords } from '../helpers/adTargetingTest';
import { DESKTOP_BREAKPOINT } from '../components/common/constants/constants';

import natematLogo from "../assets/img/natemat/logo_black.svg";
import natematLogoPlaceholder from "../assets/img/natemat/logo_placeholder.svg";
import bridLogo from "../assets/img/brid/by_naTemat_logo_Brid.svg";

import { UIStoreBrandsTheme } from "../config";


class UIModel {
  constructor(RootStore) {
    this.RootStore = RootStore;
    this.themes = {
      brid: {
        mainColor: "#f60",
        secondColor: "#f60",
        logo: bridLogo,
        logoSimple: "/img/natemat/logo_simple.svg",
        logoPlaceholder: natematLogoPlaceholder,
        logoInsta: "/img/natemat/insta.svg",
        plista_publicKey: "279c3906f34cc14e6ecde775"
      },
      bliss: {
        mainColor: "#f60",
        secondColor: "#f60",
        logo: natematLogo,
        logoSimple: "/img/natemat/logo_simple.svg",
        logoPlaceholder: natematLogoPlaceholder,
        logoInsta: "/img/natemat/insta.svg",
        plista_publicKey: "279c3906f34cc14e6ecde775"
      },
      ...UIStoreBrandsTheme
    };

    this.hardcoverConfig = {
      themeColor: '#f72c3f',
      articles: [
        {
          title: "Miliony na nadgarstku.",
          author: "Michał Jośko",
          url: "/blogi/skolimow/2000063,test"
        },
        {
          title: "Trzy błędy popełniane nagminnie w przez amatorów whisky.",
          author: "Helena Łygas",
          url: "/blogi/skolimow/2000063,test"
        },
        {
          title: "Perfumy naarzędziem manipulacji.",
          author: "Maria Kowalczyk",
          url: "/blogi/skolimow/2000063,test"
        },
        {
          title: "Granica drogi. Samochody przyszłości - bez kierowców.",
          author: "Dawid Wojtowicz",
          url: "/blogi/skolimow/2000063,test"
        },
        {
          title: "Raport rozwoju najbardziej dochodowych dziedzin przedsiębiorczość w Polsce.",
          author: "Piotr Mikke",
          url: "/blogi/skolimow/2000063,test"
        },
      ]
    }

    window.BRAND && this.themes[window.BRAND] && (this.appTheme = this.themes[window.BRAND]);
    window.BASE_THEME && (this.appTheme = window.BASE_THEME);

    window.addEventListener('resize', () => this.width = window.innerWidth)
    // autorun(() => console.log(this.isDesktop))

  }

  @observable showLoginModal = false;

  @observable appHistory = [];

  @observable appTheme = {};

  @observable width = window.innerWidth;

  @computed get isDesktop() {
    return this.width > DESKTOP_BREAKPOINT;
  }

  @observable googleAdKeyWords = {};

  @computed get isMaterialPartnera() {
    return (this.googleAdKeyWords?.topics?.includes && this.googleAdKeyWords.topics.includes('material-partnera'));
  }
  /* 
   * @todo refactoring // przenieść do article store (w category store już jest)
   */
  @observable
  UIStatus = {
    status: "valid",
    message: ""
  };

  @observable
  UICategory = {
    OID: null,
    name: window.BRAND,
    type: window.BRAND,
    url: "/",
    logo: null
  };

  @observable serviceWorkerStatus = "active";

  @observable
  popUpsVisiblity = {
    sidebar: false,
    modal: false,
    search: false,
    readLater: false,
    nav_articles: false,
    nav_extended: false
  };

  @observable nav = (window.APP_SETTINGS?.nav || 'MAIN');

  prevLocation = null;

  @observable
  numberOfUserInteractions = 0;

  @action("Rejestruje nową interakcję")
  newUserInteraction = () => {
    this.numberOfUserInteractions += 1;
    console.log('[ES] interaction ', this.numberOfUserInteractions);
  };

  /**
   * zlicza pageview
   * nie może korzystać z updateHistory, bo ustawiamy parametry
   */
  @action("zlicza pageview")
  trackPageView = ({ title, groupName = "", authorGroupName = "", topics = [] }) => {
    if (this.prevLocation !== window.location.pathname) {
      this.prevLocation = window.location.pathname
      // console.log('[ES] track pageview', window.location.pathname, title, groupName, authorGroupName);

      if (window._gaq) {
        window._gaq.push(["_set", "title", title]);
        window._gaq.push(['_setPageGroup', '1', (groupName || '')]);
        window._gaq.push(['_setPageGroup', '2', (authorGroupName || '')]);
        window._gaq.push(["_trackPageview"]);
      }

      if (window.gtag) {
        window.gtag('config', 'UA-29155269-2', { 'content_group1': (groupName || '') });
        window.gtag('config', 'UA-29155269-2', { 'content_group2': (authorGroupName || '') });
        window.gtag('config', 'UA-29155269-2', { 'page_title': title });
        window.gtag('event', 'page_view');
      }

      // chart beat
      if (window.pSUPERFLY) {
        const chartBeatData = {
          sections: topics.join(','),
          authors: authorGroupName,
          path: window.location.pathname,
          title: title
        };
        // console.log('chartBeatData', chartBeatData);
        window.pSUPERFLY.virtualPage(chartBeatData);
      }
    }
  };

  @action('ustawia typ nawigacji')
  setNav = nav => {
    this.nav = nav;
  }

  @action('wyświetla popup do logowania')
  setShowLoginModal = show => {
    this.showLoginModal = show;
  }

  @action
  setCurrentAction = action => {
    this.actionNames = (Array.isArray(action) ? action : [action]);

    const cl = document.body.classList
    const clArr = Array.from(document.body.classList);
    var matches = [];
    clArr.forEach(function (value) {
      if (/^mod--+/.test(value)) {
        matches.push(value);
      }
    });
    matches.forEach(value => { cl.remove(value); });

    this.actionNames.map(a => {
      cl.add("mod--" + a);
    });
  }

  @action
  updateGoogleAdKeywords = (keywords = {}) => {
    keywords = injectTestAdKeywords(keywords);

    this.googleAdKeyWords = keywords;
    window.globalTargeting = keywords;

    // targeting dla GAM
    let { googletag = {} } = window;
    googletag = googletag || {};
    googletag.cmd = googletag.cmd || [];
    googletag.cmd.push(function () {
      for (const [key, value] of Object.entries(keywords)) {
        googletag.pubads().setTargeting(key, value)
      }
    })

    // targeting dla prebid (#3278)
    let { pbjs } = window;
    if (pbjs && pbjs.que) {
      pbjs.que.push(function () {
        // console.log('pbjs.setBidderConfig', keywords.topics);
        pbjs.setBidderConfig({
          bidders: ['adf'],
          config: {
            ortb2: {
              site: {
                ext: {
                  data: {
                    topics: keywords.topics
                  }
                }
              }
            }
          }
        });
      });
    }
  }

  @action
  updateHistory = location => {
    if (this.appHistory.length > 0 && window?.pp_gemius_hit) {
      window.pp_gemius_hit(window.g360_gemius_identifier);
    }
    this.appHistory.push(location);
  };

  @action
  updateTheme = theme => {
    this.appTheme = theme;
  };

  @action
  changeUIType = category => {
    if (this.UICategory.OID !== category.OID && this.themes[category.type]) {
      this.UICategory = {
        OID: category.OID,
        name: category.name,
        type: category.type,
        url: category.url,
        logo: category.logo
      };
      this.updateTheme(this.themes[category.type]);
    }
  };

  @action("hide all popups")
  hideAllPopUps = () => {
    this.popUpsVisiblity["sidebar"] = false;
    this.popUpsVisiblity["modal"] = false;
    this.popUpsVisiblity["search"] = false;
    this.popUpsVisiblity["readLater"] = false;
    this.popUpsVisiblity["nav_articles"] = false;
    document.body.style.overflow = "initial";
  };

  @action("toggle popUpVisiblity")
  togglePopUpVisibility = (element, val = null) => {
    window._gaq.push(["_trackEvent", "togglePopUpVisibility", element + '/' + val]);

    if (val !== null) {
      this.popUpsVisiblity[element] = (this.popUpsVisiblity[element] === val ? false : val);
    } else {
      this.popUpsVisiblity[element] = !this.popUpsVisiblity[element];
    }
    if (element !== "nav_extended") {
      document.body.style.overflow = ((this.popUpsVisiblity[element] || this.popUpsVisiblity["sidebar"] || this.popUpsVisiblity["search"]) ? "hidden" : "initial");
    }
  };

  @action("update serviceWorkerStatus")
  updateServiceWorkerStatus = ({ action }) => {
    if (action === "showMessage") {
      return (this.serviceWorkerStatus = "waiting");
    }
    if (action === "refresh") {
      return window.location.reload();
    }
  };
}

export default UIModel;
