import isRealMobileDevice from "../helpers/isRealMobileDevice";
import isViewFromGoogle from "../helpers/isViewFromGoogle";
import { IS_EXIT_SCREEN_ENABLE, IS_OPEN_ARTICLE_THROUGH_MAIN_PAGE } from '../components/common/constants/constants';

// isRobot
// location <> window.APP_SETTINGS.exit_screen.link
// window.APP_SETTINGS.exit_screen.link
// COOKIE exit_screen_visited=/473138,czym-jest-bullying-tak-wspierac-dzieci-doswiadczajace-przemocy-w-szkole

const getCachedValues = () => {
    let storeValue = localStorage.getItem('exitScreenLink');

    let link: string | null,
        timestamp: number = 0;

    try {
        if (!storeValue)
            throw '';

        const value = JSON.parse(storeValue);

        link = value?.link || null;
        timestamp = value.timestamp ?? 0;
    } catch {
        //Back compatibility with old string values
        link = storeValue || null;
    }

    return {
        link, timestamp
    };
};

type CacheValue = ReturnType<typeof getCachedValues>;

const addExitScreen = () => {
    if (!isRealMobileDevice() || !window.APP_SETTINGS?.exit_screen)
        return;

    if (IS_OPEN_ARTICLE_THROUGH_MAIN_PAGE && new URLSearchParams(window.location.search).has('fbclid'))
        return;

    const exitScreenLink = window.APP_SETTINGS?.exit_screen?.link;
    const exitScreenCappingTime = window.APP_SETTINGS?.exit_screen?.capping ?? -1;

    let exitScreenParams = window.APP_SETTINGS?.exit_screen?.params || {};

    if (isViewFromGoogle())
        exitScreenParams['_exT'] = 'discover';

    const {
        link: cachedExitScreenLink,
        timestamp: cachedExitScreenTimestamp
    } = getCachedValues() as CacheValue;

    const targetLocation = { ...window.location };

    const exitScreenPathname = new URL(exitScreenLink, window.location.toString())?.pathname || "";

    if (
        //Ignore if exitScreen already visited
        cachedExitScreenLink == exitScreenLink &&
        (
            //If the capping time is below zero then capping - only on the link
            exitScreenCappingTime < 0 ||
            //Otherwise capping - the elapsed timeout
            (Date.now() - cachedExitScreenTimestamp) <= (exitScreenCappingTime * 60 * 60 * 1000)
        )
    )
        return;

    //If current page is not exitScreen apply exitScreen
    if (exitScreenPathname != targetLocation.pathname) {
        //Replace current state witn exitScreen
        const exitScreenSearchParams = new URLSearchParams(targetLocation.search.replace(/^\?/, ''));
        Object.entries(exitScreenParams).forEach(([name, value]) => {
            exitScreenSearchParams.append(name, value + '');
        });
        window.history.replaceState('', '', `${exitScreenLink}?${exitScreenSearchParams.toString()}`);
        //Back to page
        window.history.pushState('', '', targetLocation.pathname + targetLocation.search)
        // setTimeout(() => window.history.pushState('', '', backLocation.pathname + backLocation.search), 25);
    }

    //Store exitScreenLink
    localStorage.setItem('exitScreenLink', JSON.stringify({
        link: exitScreenLink,
        timestamp: Date.now()
    } as CacheValue));
};

if (IS_EXIT_SCREEN_ENABLE) {
    addExitScreen();
}