const isLocalStorageSupported = () => {
    try {
        var storage = window.localStorage;
        storage.setItem('checker', 'true');
        storage.removeItem('checker');
        return true;
    } catch {
        return false;
    }
};

export default isLocalStorageSupported;