import { useMemo } from "react";
import { matchPath, useLocation } from "react-router-dom";

import Routes from "../Routes";

const useCurrentRoute = () => {
    const location = useLocation();

    const currentRoute = useMemo(() => {
        for (const route of Object.values(Routes))
            if (matchPath(route, location.pathname))
                return route;

    }, [location]);

    return currentRoute;
};

export default useCurrentRoute;