import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom"
import { useAuth } from "../context/AuthContext"

const RestrictedRoute : React.FC<{element: React.ReactNode}> = ({element}) => {
    const [{ isAuthed }] = useAuth();

    const navigate = useNavigate();

    useEffect(()=>{
        if(isAuthed)
            navigate('/');
    },[isAuthed]);

    if(isAuthed===null)
        return null;

    return <>{!isAuthed && element}</>;
}

export default RestrictedRoute;