import { useState, useEffect } from "react"


export const useScroll = () => {
    const [prevOffset, setPrevOffset] = useState<number>(0)
    const [direction, setDirection] = useState<"up" | "down" | null>(null)

    const handleScroll = () => {
        let scrollY = Math.floor(window.scrollY);
        if (scrollY === 0) {
            setDirection(null);
        }
        if (scrollY > prevOffset) {
            setDirection("down");
        } else if (scrollY <= prevOffset) {
            setDirection("up");
        }
        setPrevOffset(scrollY);
    }

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        }
    }, [prevOffset])

    return { direction, prevOffset }
}