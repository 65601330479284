import React from "react"
import { styled } from "../../../../stitches.config"

import HtmlParser from "../../Content/HtmlParser"

const HardcoverFactFrame = ({ blockData }) => {
    return <Wrap>
        <HtmlParser data={blockData.text} />
    </Wrap>
}

const Wrap = styled("div", {
    width: "358px",
    margin: "20px auto",
    minHeight: "50px",
    textAlign: "center",
    backgroundColor: "#fff",
    position: "relative",
    fontSize: "35px",
    fontFamily: "'Source Serif Pro', serif",
    fontWeight: 600,
    maxWidth: "calc(100vw - 40px)",
    "&:before": {
        display: "block",
        content: "",
        position: "absolute",
        width: "42px",
        height: "48px",
        borderTop: "2px solid",
        borderLeft: "2px solid",
        borderColor: "$hardcoverColor",
        top: "-2px",
        left: "-2px"
    },
    "&:after": {
        display: "block",
        content: "",
        position: "absolute",
        width: "42px",
        height: "48px",
        borderBottom: "2px solid #000",
        borderRight: "2px solid #000",
        bottom: "-2px",
        right: "-2px"
    },
    "font": {
        fontSize: "45px"
    },
    "div": {
        margin: "0 !important"
    },
    "@media (min-width: 992px)": {
        width: "520px"
    }
})

export default HardcoverFactFrame;