import React, { Suspense } from "react";
import { styled } from "../../../../stitches.config";
import { observer } from "mobx-react";
import { h1, body } from "../../../../redesign/commonStyles/Fonts";
import AuthorBrid from "./AuthorBrid";

export interface Author {
  name: string;
  image: string;
  profession: string;
  about: string;
  portfolio: Array<string>;
}
interface IData {
  text?: string;
  backgroundUrl: string;
  authors: Array<Author>;
}

type TWrap = {
  src: string;
};

const BridImageBanner: React.FC<{ blockData: IData }> = observer(
  ({ blockData }) => {
    return (
      <Wrap css={{
        "$$src": `url(${blockData.backgroundUrl})`
      }}>
        <Title className="title-wrap">
          {blockData.authors && blockData.authors.length > 0
            ?
            <Suspense fallback={null}>
              <AuthorsWrapper>
                <ImagesWrapper>
                  {blockData.authors.map((item, index) => (
                    <AuthorBrid author={item} key={index} />
                  ))}
                </ImagesWrapper>
                <a className={body()} href="#zespol-realizujacy-projekt">zespół {blockData.authors.length} osobowy</a>
              </AuthorsWrapper>
            </Suspense>
            :
            null
          }
          <h1 className={h1()}><span>{blockData.text}</span></h1>
        </Title>
        <div className="bg-img" />
      </Wrap>
    );
  }
);

const Wrap = styled("div", {
  position: "relative",
  ".bg-img": {
    paddingTop: "78.125%",
    zIndex: -19,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundImage: "$$src",
    backgroundSize: "cover",
    "@bp4": {
      marginBottom: "-30px"
    }
  },
  "& > img": {
    position: "absolute",
    top: "100px",
    objectFit: "cover",
    width: "100%",
    height: "250px",
    zIndex: -20
  },
  "@bp4": {
    height: "624px",
    ".bg-img": {
      position: "absolute",
      top: "0",
      margin: "0",
      width: "100%",
      height: "624px",
      paddingTop: "0",
      backgroundImage: "linear-gradient(0deg,rgba(0, 0, 0, 1) 0%,rgba(0, 0, 0, 0) 100%), $$src",
      backgroundSize: "cover"
    }
  }
});

const Title = styled("div", {
  width: "90%",
  position: "absolute",
  bottom: "0",
  margin: "0 26px",
  "h1": {
    color: "#131212",
    lineHeight: "37px !important",
    marginTop: "18px",
    "span": {
      backgroundColor: "#fff",
      paddingLeft: "2px",
      paddingRight: "2px",
      boxShadow: "rgb(255 255 255) 2px 0px 0px, rgb(255 255 255) -2px 0px 0px"
    }
  },
  "@bp4": {
    position: "absolute",
    bottom: "46px",
    margin: "0 0 0 86px",
    "h1": {
      width: "65%",
      lineHeight: "71px !important",
      marginTop: "26px"
    }
  }
});

const ImagesWrapper = styled("div", {
  marginLeft: "10px",
  "img": {
    marginLeft: "-10px",
    borderRadius: "40px",
    border: "1px solid #fff"
  }
});

const AuthorsWrapper = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: "100%",
  margin: "0",
  "a": {
    marginLeft: "10px",
    display: "block",
    fontSize: "12px",
    color: "#FF6400",
    textDecoration: "underline"
  }
});

export default BridImageBanner;
