import { useRef, useState, useEffect } from 'react';

export function useHover(): [React.RefObject<HTMLElement>, boolean] {
    const [value, setValue] = useState<boolean>(false);

    const ref = useRef<HTMLElement>(null);

    const handleMouseOver = () => setValue(true);
    const handleMouseOut = () => setValue(false);

    useEffect(
        () => {
            const node: HTMLElement | null = ref.current;
            if (node) {
                node.addEventListener('mouseover', handleMouseOver);
                node.addEventListener('mouseout', handleMouseOut);

                return () => {
                    node.removeEventListener('mouseover', handleMouseOver);
                    node.removeEventListener('mouseout', handleMouseOut);
                };
            }
        },
        [ref.current]
    );

    return [ref, value];
}