import React, { PropsWithChildren } from 'react';
import { h1 } from "./commonStyles/Fonts";

interface ArticleTitleProps {
    as?: string;
}

const ArticleTitle: React.FC<PropsWithChildren<ArticleTitleProps>> = ({
    children,
    as
}) => {
    return React.createElement(as ?? "h1", { className: h1() }, children);
};

export default ArticleTitle