import axios from 'axios';

export function authInterceptor() {
    axios.interceptors.request.use(request => {
        const storageToken = localStorage.getItem('access_token');
        const smAccessToken = (window?.APP_SETTINGS?.social_media_access_token);
        const token = storageToken || smAccessToken;

        if (token) {
            request.headers.Authorization = `Bearer ${token}`;
        }

        return request;
    });

    // TODO: obsługa response z wylogowaniem
}
