import { useEffect } from "react"
import { pushDataLayer } from "../helpers/pushDataLayer"

const DataLayer = ({ data }) => {
    useEffect(() => {
        pushDataLayer(data);
    }, [])

    return null;
}

export default DataLayer