import React from 'react';
import type { BrandConfig } from '../config.types';

//BrandingLogos
import logo, { ReactComponent as LogoComponent } from "./assets/logo.svg";
import logoPlaceholder from "./assets/logo_placeholder.svg";
import logoSimple from "./assets/logo_simple.svg";

//ErrorPageImages
import errorPageImageDesktop from "./assets/errorPageDesktop.png";
import errorPageImageMobile from "./assets/errorPageMobile.png";

//Theme
const colors = {
    primary120: "#55B6B7",
    primary: "#62CACB",
    primary60: "#DFF4F5",
};

const svgColorFilter: string = "invert(100%) sepia(48%) saturate(5786%) hue-rotate(153deg) brightness(84%) contrast(89%)"

//SocialMedia links
const socialMediaLinks = {
    facebook: 'https://www.facebook.com/mamadupl',
    instagram: 'https://www.instagram.com/mamadu.pl/',
    tiktok: 'https://www.tiktok.com/@mamadupl',
    youtube: 'https://www.youtube.com/@mamadupl'
};

//Breadcrumbs/Footer title
const brandTitle = (
    <>
        mama<span>:</span>DU
    </>
);

//ErrorPageBranding
const errorPageText = (
    <>
        Ups, nie mamy tego, czego szukasz. Ale nie szkodzi. Zrelaksuj się i przeczytaj inne, interesujące Cię teksty.
    </>
);

//FooterDescription
const footerDescription = "Codzienny magazyn świadomego rodzica";

//PushPushGo worker
const pushPushWorkerUrl = "https://s-eu-1.pushpushgo.com/66952b52603d31a48459e073/worker.js";

//Export config
const BrandConifg: BrandConfig = {
    LogoComponent,
    logo, logoPlaceholder, logoSimple,
    colors, svgColorFilter,
    brandTitle,
    socialMediaLinks,
    errorPageText, errorPageImageDesktop, errorPageImageMobile,
    footerDescription,
    pushPushWorkerUrl
};


export default BrandConifg;