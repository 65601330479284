import React from 'react';
import { styled, CSS } from "../../stitches.config"
import Link from "../../components/Link";

interface AvatarProps {
    size?: 'extrasmall' | 'small' | 'normal' | 'big' | 'extrabig';
    border?: boolean;
    src: string;
    url?: string;
    css?: CSS;
};

const Avatar: React.FC<AvatarProps> = ({
    size = 'normal',
    border = false,
    src,
    url,
    ...props
}) => {
    return (
        <StyledAvatar className="avatar" size={size} border={border}>
            {url ?
                <Link to={url}>
                    <img src={src} />
                </Link>
                :
                <img src={src} />}
        </StyledAvatar>
    );
};

const StyledAvatar = styled('div', {
    flexShrink: 0,
    '& img': {
        width: "100% !important",
        height: "100% !important",
        borderRadius: "100%",
        objectFit: "cover",
    },
    variants: {
        size: {
            extrasmall: {
                width: "32px",
                height: "32px",
                borderRadius: "32px",
            },
            small: {
                width: "40px",
                height: "40px",
                borderRadius: "40px",
            },
            normal: {
                width: "72px",
                height: "72px",
                borderRadius: "72px",
            },
            big: {
                width: "96px",
                height: "96px",
                borderRadius: "96px",
            },
            extrabig: {
                width: "112px",
                height: "112px",
                borderRadius: "112px",
            }
        },
        border: {
            true: {
                border: "2px solid $primary",
                padding: "2px",
            },
            false: {
                border: "0px solid $primary",
            }
        }
    }
})

export default Avatar