import React from "react";
import { styled } from "../../stitches.config"
import SlateRenderer from "./Parts/Slate/SlateRenderer";

class MiddlePart extends React.Component {
  render() {
    const article = this.props.article;

    return (
      <Wrapper className={"article-middle-part"}>
        <SlateRenderer
          parts={article.MiddlePart}
          hideAdslots={this.props.hideAdslots}
        />
      </Wrapper>
    );
  }
}

const Wrapper = styled("div",{});

export default MiddlePart;
