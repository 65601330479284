import React, { useEffect, useMemo, useState, useRef } from "react";
import { useStores } from "../helpers";
import isRealMobileDevice from "../helpers/isRealMobileDevice";
import useCurrentRoute from "../helpers/useCurrentRoute";
import { observer } from "mobx-react";
import { useLocation, useNavigate } from "react-router-dom";

import Routes from '../Routes';

const OpenArticleThroughMainPage: React.FC = observer(() => {

    const { ArticleStore, CategoryStore } = useStores();

    const location = useLocation();
    const navigate = useNavigate();
    const currentRoute = useCurrentRoute();

    const [article, setArticle] = useState<any>(null);
    const [isInject, setInject] = useState<boolean>(false);

    const homeCategoryOIDRef = useRef<string>('');

    //Detect article open via fb link and redirect to homepage
    useEffect(() => {
        if (!currentRoute || !ArticleStore.currentSlug || !isRealMobileDevice() || homeCategoryOIDRef.current != '')
            return;

        if (![Routes.article, Routes.zdrowieArticle].includes(currentRoute))
            return;

        if (!new URLSearchParams(location.search).has('fbclid'))
            return;

        const article = Object.entries(window.APP_SETTINGS.pwa_preload ?? {}).find(([key]) => key.search(ArticleStore.currentSlug) > -1)?.[1]?.article;
        if (!article)
            return;

        setArticle(article);
        navigate('/');
    }, [currentRoute, ArticleStore]);

    //Catch homepage load from fb article and attach block to the top
    useEffect(() => {
        if (!article || currentRoute !== Routes.home || CategoryStore.status !== 'valid')
            return;

        if (isInject)
            return;

        //Remove articleContentInSource
        document.getElementById("articleContentInSource")?.remove();

        CategoryStore.setStatus("loading");
        homeCategoryOIDRef.current = CategoryStore.fetchedCategory.OID;
        CategoryStore.blocks.unshift({
            "type": "b-one",
            "uID": "override-article",
            "b1": {
                "search": article.title,
                "link": article.url,
                "title_normal": article.title,
                "background-image": article.picture.md5,
                "show_links": "off",
                "media": {
                    "background-image": article.picture.media
                },
                "post_category": article.category
            },
            "settings": {
                "withoutWrapper": true
            }
        });

        CategoryStore.setStatus("valid");
        setArticle(null);
        setInject(true);
    }, [currentRoute, CategoryStore.status, isInject]);

    //Cleanup injection after close homepage
    useEffect(() => {
        if (currentRoute !== Routes.home && !isInject)
            return;

        if (CategoryStore.fetchedCategory.OID === homeCategoryOIDRef.current) {
            CategoryStore.fetchedCategory.category.wizard = CategoryStore.blocks.filter((b: any) => b.uID !== 'override-article');
        }

        setInject(false);
    }, [currentRoute, CategoryStore, isInject]);

    return (
        <></>
    );
});

export default OpenArticleThroughMainPage;