import React, { Component } from "react";
import { inject } from "mobx-react";

@inject("UIStore")
class PlistaOutstream extends Component {

    initAd() {
        const { publicKey } = this.props;

        let configObject = {
            "publickey": publicKey,
            "origin": "pl",
            "dataMode": "data-display",
            "noCache": true,
        };
        if (window.BRAND === 'natemat' || window.BRAND === 'aszdziennik') {
            configObject = {
                ...configObject,
                "widgets": [
                    {
                        "name": "outstream",
                        "pub": {
                            "click": "%%CLICK_URL_UNESC%%",
                            "impression": "%%VIEW_URL_UNESC%%"
                        }
                    }
                ],
                "sandbox": "%%PREVIEW_MODE%%"
            }
        } else {
            configObject = {
                ...configObject,
                "widgets": [
                    "outstream_2"
                ],
                "passback": function () {
                    /*passback script here*/
                }
            }
        };

        (function (c) {
            var g, s, n = 'script', w = window; w.PLISTA_ = w.PLISTA_ || []; w.PLISTA_.push(c); if (w.PLISTA_.length > 1) { return; } g = w.document.getElementsByTagName(n)[0]; s = w.document.createElement(n); s.async = true; s.type = 'text/javascript'; s.src = (w.location.protocol === 'https:' ? 'https:' : 'http:') + '//static' + (c.origin ? '-' + c.origin : '') + '.plista.com/async/min.js'; g.parentNode.insertBefore(s, g);
        }(configObject));
    }

    componentDidMount() {
        this.initAd();
    }
    componentDidUpdate() {
        this.initAd();
    }

    render() {
        const outstreamVer = window.BRAND === 'natemat' || window.BRAND === 'aszdziennik' ? '' : '_2';
        return <div data-display={`plista_widget_outstream${outstreamVer}`}></div>
    }
}
export default PlistaOutstream;
