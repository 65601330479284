import React from "react";
import { IS_FEATURE_enableComments } from "./common/constants/constants";
import { FacebookProvider } from "react-facebook"

interface IProps {
    children: any;
}

const FacebookProviderWrapper: React.FC<IProps> = ({
    children,
    ...props
}) => {

    const appId = (window?.APP_SETTINGS?.facebook_app_id) || "";

    return <>
        {IS_FEATURE_enableComments ?
            <FacebookProvider language="pl_PL" appId={appId} lazy={true}>
                {children}
            </FacebookProvider>
            :
            <>{children}</>
        }
    </>
}


export default FacebookProviderWrapper;
