import axios, { AxiosResponse } from "axios"

export type LoginUserData = {
    username: string;
    password: string;
}

export async function loginUser(userData: LoginUserData): Promise<AxiosResponse<LoginUserData>> {
    try {
        const res = await axios.post(`${window.API_HOST}/api/account/login`, userData)

        if (res?.data?.errors) {
            throw { status: res.status, errors: res.data.errors }
        } else if (res?.data?.access_token) {
            localStorage.setItem("access_token", res.data.access_token);
        }
        return res.data
    } catch (err) {
        if (axios.isAxiosError(err)) {
            throw { status: err.response!.status, errors: err.response!.data.errors }
        } else {
            throw err
        }
    }
}