import React, { Component, useRef } from "react";
import { StickyContainer, Sticky } from 'react-sticky';
import Adslot from './Adslot'
import { observer } from "mobx-react";
import { observable } from 'mobx'

@observer
class AdslotSticky extends Component {
    @observable height = this.props.initialHeight;

    componentDidMount() {
        const { googletag } = window;
        googletag.cmd.push(() => {
            googletag.pubads().addEventListener('slotRenderEnded', (event) => {

                if (event.slot.getSlotElementId().match(/art_intext\d+/) != null) {
                    const tmp = event.slot.getSlotElementId().match(/art_intext(\d+)/);
                    const componentName = `ART_intext_${tmp[1]}`;
                    if (this.props.name === componentName) {
                        this.height = event.isEmpty ? '0px' : '480px';
                    }

                }
            });
        })
    }

    render() {
        const isMobile = window.innerWidth < 996;
        const { paddingBottom = 0 } = this.props
        //this.props.height = this.height;
        return (
            <>
                <ContainerSticky height={this.height} {...this.props} />
                <div style={{ paddingBottom: paddingBottom }} />
            </>
        )
    }
}

const ContainerSticky = ({ name, disableFirewrok, height, ...props }) => {
    const paddingTop = props.paddingTop || 0;
    const topOffset = props.topOffset || 0;
    const className = props.className || '';
    const containerStyle = { minHeight: height }
    const isStickyPaddingTop = props.isStickyPaddingTop || 0;

    return (
        <StickyContainer className={className} style={containerStyle}>
            <Sticky topOffset={topOffset}>
                {({ style, isSticky }) => {
                    const newStyle = { ...style, paddingTop: isSticky && isStickyPaddingTop || paddingTop }
                    return <div style={newStyle} isSticky={isSticky}>
                        <Adslot name={name} disableFirewrok={disableFirewrok} {...props} />
                    </div>
                }
                }
            </Sticky>
        </StickyContainer>
    );
}

export default AdslotSticky