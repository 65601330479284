import React, { useState, useEffect } from "react"
import { styled } from "../stitches.config";
import { observer } from "mobx-react"
import Cookies from "cookies-js";

const StickyBar = observer(({ id, AdsStore, children }) => {
    const [isHidden, setHidden] = useState(true)
    const [capped, setCapped] = useState(true) //TODO przenieść wyzej do AdContainer

    useEffect(() => {
        const cookie = Cookies.get(id);
        if (typeof cookie === "string") {
            console.log(`Anchor has been capped: ${id}`)
        } else {
            window.googletag.cmd.push(() => {
                window.googletag.pubads().addEventListener('slotRenderEnded', (event) => {
                    const slotId = event.slot.getSlotElementId()
                    console.log('[ES] slotRenderEnded', slotId, event);
                    if (slotId === id) {
                        if (!event.isEmpty) {
                            // capping tylko w przypadku, kiedy reklama nie jest pusta 
                            const d = new Date()
                            d.setMinutes(d.getMinutes() + AdsStore.adConfig[id]?.capping?.time)

                            if (!document.cookie.split(';').some((item) => item.trim().startsWith(id))) {
                                document.cookie = id + "=true; path=/; expires=" + d.toUTCString() + "; secure; samesite=lax";
                            }

                            setHidden(false)
                        }
                    }
                });
            });

            setCapped(false);
        }
    }, [])

    const handleClick = (e) => {
        e.preventDefault();
        setHidden(true)
        // TODO to jest brzydko, mozna ew. poprawic
        const container = document.getElementById("belka_sticky")
        container.children[0].remove();
    }

    return (
        <StickyWrapper isHidden={isHidden} className="bizon">
            <ButtonWrap>
                <button onClick={handleClick}>Zamknij</button>
            </ButtonWrap>
            {!capped && children}
        </StickyWrapper>
    )
})

const ButtonWrap = styled("div",{
    position: "absolute",
    right: 0,
    zIndex: 10000,
    transform: "translate(-15px, -25px)",
    padding: "3px 4px 0 4px",
    backgroundColor: "rgb(255,255,255)",
    borderRadius: "3px 3px 0 0",
    boxShadow: "2px -2px 3px 0 rgba(0, 0, 0, 0.35), 0 -2px 3px 0 rgba(0, 0, 0, 0.35) inset",
    "button":{
        zIndex: "9999",
        margin: 0,
        whiteSpace: "nowrap",
        fontFamily: "$grotesk",
        fontStyle: "normal",
        textShadow: "rgba(0,0,0,.004) 1px 1px 1px !important",
        color: "#000",
        border: "none",
        backgroundColor: "transparent"
    }
});

const StickyWrapper = styled("div",{
    transition: "transform 1.5s",
    position: "fixed",
    right: 0,
    left: 0,
    bottom: 0,
    zIndex: 10000,
    width: "100%",
    backgroundColor: "rgba(255,255,255,0.9)",
    boxShadow: "3px -2px 3px 0 rgba(0,0,0,0.35)",
    transform: "translateY(0px)",
    "&.bizon":{
        "@mobile":{
            bottom: "70px",
            background: "white",
            paddingBottom: "15px"
        }
    },
    variants: {
        isHidden: {
            true: {
                transform: "translateY(500px)"
            }
        }
    }
});

export default StickyBar