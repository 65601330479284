import React from "react";
import Media from "../../../components/common/Media";
import { defaultColors, styled } from "../../../stitches.config";
import { h3, bodyLarge } from "../../commonStyles/Fonts";
import { withThemeContext } from "../../components/StitchesThemeProvider";
import { PaymentStatus, PaymentType } from "../../types/Payment";
import { ModalType } from "../config";
import ModalLink from "../ModalLink";
import blikIcon from "../../../../src/assets/img/blik-logo.png";

interface Props {
  users: AuthorType[];
  articleOID: string;
  isDarkMode?: boolean;
  headText: string;
  descriptionText: string;
  origin: string;
}

const TipCallToAction: React.FC<Props> = ({
  articleOID,
  users,
  isDarkMode,
  headText,
  descriptionText,
  origin
}) => {
  return (
    <Wrapper
      style={{
        backgroundColor: isDarkMode
          ? defaultColors.grayscale0
          : defaultColors.grayscale25,
      }}
    >
      <AvatarWrapper>
        <Media
          src={users[0].img}
          height={300}
          width={300}
          preserveRatio={false}
          aspectRatio={1}
          alt={users[0].name}
        />
      </AvatarWrapper>
      <TextWrapper>
        <h3 className={h3()}>{headText}</h3>
        <p className={bodyLarge()} style={{ fontWeight: 600 }}>
          {descriptionText}
        </p>
        <ModalLink
          type={ModalType.PAYMENT}
          paymentStatus={PaymentStatus.PENDING}
          paymentType={PaymentType.TIP}
          paymentOrigin={origin}
          // TODO: Dorobić walidację
          paymentEditorId={users?.map(author => author.OID).join(",")}
          paymentArticleId={articleOID}
          style={{ display: "block", marginTop: "auto" }}
        >
          <Button>Zostaw napiwek</Button>
        </ModalLink>
      </TextWrapper>
      <img className="blik-icon" src={blikIcon} />
    </Wrapper>
  );
};

const Wrapper = styled("div", {
  backgroundColor: `grayscale0 !important`,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  width: "100%",
  position: "relative",
  marginTop: "24px",
  h3: {
    margin: "0",
  },
  "@bp4": {
    flexDirection: "row",
    height: "300px",
  },

  "& .blik-icon": {
    position: "absolute",
    bottom: "80px",
    right: "12px",
    width: "80px !important",
    marginLeft: "-20px",
    objectFit: "contain !important",
    aspectRatio: "auto !important",
    flexShrink: 0,
    "@bp4": {
      bottom: "40px",
      right: "40px",
    }
  },

  "a": {
    "@bp4": {
      maxWidth: "170px",
    },
  }
});

const AvatarWrapper = styled("div", {
  '& > div > picture > img': {
    objectFit: 'cover',
    objectPosition: 'center'
  }
});

const TextWrapper = styled("div", {
  padding: "12px",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  background: "$grayscale0",
  h3: {
    color: `$grayscale100 !important`,
  },
  p: {
    color: `$grayscale100 !important`,
  },
  "@bp4": {
    padding: "40px",
  },
});

const Button = styled("button", {
  backgroundColor: "$primary",
  color: "$grayscale0",
  border: "none",
  marginTop: "40px",
  padding: "12px 20px",
  width: "100%",
  fontWeight: 600,
  "@bp4": {
    width: "auto",
    marginTop: "auto",
  },
});
export default withThemeContext(TipCallToAction);
