import type { BrandsConfig } from "./config.types";

/* Import configs */
import NaTematConfig from "./natemat/config";
import INNPolandConfig from "./innpoland/config";
import ASZDziennik from "./aszdziennik/config";
import MamaDu from "./mamadu/config";
import DadHero from "./dadhero/config";
import TopNewsyConfig from "./topnewsy/config";
import KlubZwierzakiConfig from "./klubzwierzaki/config";

/* Brands config */
const brandsConfig: BrandsConfig = {
    natemat: NaTematConfig,
    innpoland: INNPolandConfig,
    aszdziennik: ASZDziennik,
    mamadu: MamaDu,
    dadhero: DadHero,
    topnewsy: TopNewsyConfig,
    klubzwierzaki: KlubZwierzakiConfig,
};

export default brandsConfig;